
<template>
    <div class="modal fade" tabindex="-1" role="dialog" :id="id" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{title}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p><slot></slot></p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary confirmclosed" @click="confirm">Confirm</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        props:{
            id: String,
            title:String,
            confirmFunction:Function
        },
        methods:{
            confirm(){
                this.$emit('confirmFunction');
            }
        }
    };
</script>
