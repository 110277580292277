<template>
    <div>
        <div
            class="row no-gutters"
            style="background-image: url('/images/contact-header.jpg');background-repeat: no-repeat; background-size:cover; height: 60vh; position: relative; background-position: center center;"
        >
            <h1
                class="ml-3 text-light"
                style="position: absolute; bottom: 2rem; left: 2rem;"
            >
                Contact
            </h1>
        </div>
        <div class="container">
            <div v-if="loading">
                Sending Message...........
            </div>
            <div v-else>
                <div class="row my-5">
                    <div class="col-md-6">
                        <success v-if="success">
                            Your message has been received. We will contact you with in 72 hours.
                        </success>
                        <fatal-error v-if="errors">
                            There was an error sending your message. Please try again.
                        </fatal-error>


                        <form class="row contact-form mb-3">
                            <p class="ml-3 mb-5">
                                We always love to hear from our customers. Share some stories on how Boss® has made your job easier. OR if you think we should do something better - let us know. Enter your information, comment or question below, someone will get back to you promptly.
                            </p>

                            <div class="form-group col-md-6">
                                <input
                                    type="text"
                                    name="fname"
                                    class="form-control"
                                    placeholder="First Name*"
                                    v-model="user.fname"
                                    :class="[{ 'is-invalid': errorFor('fname') }]"
                                />
                                <v-errors :errors="errorFor('fname')"></v-errors>
                            </div>
                            <div class="form-group col-md-6">
                                <input
                                    type="text"
                                    name="lname"
                                    class="form-control"
                                    placeholder="Last Name*"
                                    v-model="user.lname"
                                    :class="[{ 'is-invalid': errorFor('lname') }]"
                                />
                                <v-errors :errors="errorFor('lname')"></v-errors>
                            </div>


                            <div class="form-group col-md-12">
                                <input
                                    name="email"
                                    type="email"
                                    class="form-control"
                                    placeholder="Email Address*"
                                    v-model="user.email"
                                    :class="[{ 'is-invalid': errorFor('email') }]"
                                />
                                <v-errors :errors="errorFor('email')"></v-errors>
                            </div>
                            <div class="form-group col-md-12">
                                <input
                                    name="phone"
                                    type="text"
                                    placeholder="Phone "
                                    class="form-control"
                                    v-model="user.phone"
                                />

                            </div>
                            <div class="form-group col-md-12">
                                <input
                                    name="address"
                                    type="text"
                                    placeholder="Address"
                                    class="form-control"
                                    v-model="user.address"
                                />
                            </div>

                                <div class="form-group col-md-4">
                                    <input
                                        name="city"
                                        type="text"
                                        placeholder="City"
                                        class="form-control"
                                        v-model="user.city"
                                    />
                                </div>
                                <div class="form-group col-md-4">
                                    <input
                                        name="state"
                                        type="text"
                                        class="form-control"
                                        v-model="user.state"
                                        placeholder="State"
                                    />
                                </div>
                                <div class="form-group col-md-4">
                                    <input
                                        name="zipcode"
                                        type="text"
                                        class="form-control"
                                        v-model="user.zipcode"
                                        placeholder="Zip Code"
                                    />
                                </div>

                            <div class="form-group col-md-12">
                                <input
                                    name="country"
                                    type="text"
                                    class="form-control"
                                    placeholder="Country"
                                    v-model="user.country"
                                />
                            </div>
                            <div class="form-group col-md-12">

                                <textarea
                                    name="message"
                                    type="text"
                                    class="form-control"
                                    cols="30"
                                    rows="5"
                                    placeholder="Enter your comment or question here*"
                                    v-model="user.message"
                                    :class="[{ 'is-invalid': errorFor('message') }]"
                                ></textarea>
                                <v-errors :errors="errorFor('message')"></v-errors>
                            </div>

                            <!-- Google Recaptcha -->
                            <div class="form-group col-md-12">
                                <!-- <div class="g-recaptcha" id="recaptcha" data-sitekey="6Lc_NmwpAAAAAKDaGCZPI77ElMYB24oToP91yT3F" data-callback="onRecaptchaSuccess" ></div> -->
                                <div id="recaptcha" ></div>
                            </div>

                            <button class="btn  ml-3 " @click.prevent="sendMsg">
                                CONTACT US
                            </button>
                        </form>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-4 p-4 company-address mb-5">
                        <div>
                            <p>
                                <strong>Boss Gloves</strong><br />
                                <span
                                    >Contact our Customer Service Representatives via
                                    phone/email.</span
                                ><br />

                                <a class="text-white" href="tel:5188610133"
                                    >(518) 861-0133</a
                                >
                            </p>
                        </div>
                        <div>
                            <p>
                                <strong>PIP Global Corporate Headquarters</strong><br />
                                25 British American Blvd.<br />
                                Latham, NY 12110<br />
                                <a class="text-white" href="tel:8552846800"
                                    >(855) 284-6800</a
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import validationErrors from "./../mixins/validationErrors";
export default {
    mixins: [validationErrors],

    data() {
        return {
            user: {
                fname: null,
                lname:null,
                email: null,
                phone:null,
                address: null,
                city:null,
                state:null,
                zipcode:null,
                country:null,
                message: null,
                gRecaptchaResponse:null
            },
            recaptchaWidgetId: null,
            success: false,
            errors: null,
            loading:false,
            recaptchaLoaded: false,
        };
    },
    methods: {

        loadRecaptchaScript() {
            return new Promise((resolve, reject) => {
            if (window.grecaptcha) { // Check if reCAPTCHA is already loaded
                resolve();
            } else {
                // Create a script element to load reCAPTCHA
                const script = document.createElement('script');
                script.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoad&render=explicit';
                script.async = true;
                script.defer = true;
                document.head.appendChild(script);

                // Define a global callback for when reCAPTCHA is loaded
                window.onRecaptchaLoad = () => {
                    console.log("reCAPTCHA loaded successfully.");
                    resolve();
                };

                // Error handling for script loading
                script.onerror = () => {
                reject(new Error("Failed to load the reCAPTCHA script"));
                };
            }
            });
        },
        renderRecaptcha() {
            var self = this;
            this.loadRecaptchaScript().then(() => {

                if (window.grecaptcha && window.grecaptcha.render) {
                    this.recaptchaWidgetId = grecaptcha.render('recaptcha', {
                        sitekey: '6Lc_NmwpAAAAAKDaGCZPI77ElMYB24oToP91yT3F',
                        callback: self.onRecaptchaSuccess,
                    });
                    // window.onRecaptchaSuccess = this.onRecaptchaSuccess;
                } else {
                    console.error("reCAPTCHA not loaded");
                }
            }).catch(error => {
            console.error("Error loading reCAPTCHA:", error.message);
            });
        },
        onRecaptchaSuccess(response) {
            this.user.gRecaptchaResponse = response;
        },

        async sendMsg() {

            this.loading=true;
            this.errors = null;
            try {
                await axios.post(`/api/sendMsg`, this.user).then(response => {
                    this.success = true;
                    var self = this;
                    this.loading=false;
                    setTimeout(function() {
                        self.success = false;
                    }, 5000);
                    this.user = {};
                });
            } catch (error) {
                this.loading=false;
                this.errors = error.response && error.response.data.errors;

            }
            if (window.grecaptcha && this.recaptchaWidgetId !== null) {
                this.renderRecaptcha();
            }
        },

        onRecaptchaSuccess(response) {
            this.user.gRecaptchaResponse = response;
        },
    },
    mounted() {
        var self = this;
        this.$nextTick(() => {
            this.loadRecaptchaScript().then(() => {

            // Render reCAPTCHA widget here
                if (window.grecaptcha && window.grecaptcha.render) {
                    this.recaptchaWidgetId = grecaptcha.render('recaptcha', {
                        sitekey: '6Lc_NmwpAAAAAKDaGCZPI77ElMYB24oToP91yT3F',
                        callback: self.onRecaptchaSuccess,
                    });
                    // window.onRecaptchaSuccess = this.onRecaptchaSuccess;
                } else {
                    console.error("reCAPTCHA not loaded");
                }
            });
        });

    },
    beforeDestroy() {
        window.onRecaptchaSuccess = null;
    },
    watch: {
        'user.gRecaptchaResponse'(newValue) {
            console.log('gRecaptchaResponse captured');
        }


    }
};
</script>

<style lang="scss" scoped>

h1 {
    font-size: 3.5rem;
}
label {
    font-family: "Acumin Pro", Helvetica, Arial, sans-serif;
}
.contact {
    padding: 3rem 2rem;

    @media screen and (max-width: 1604px) {
        padding: 3rem 0.5rem;
    }
}
.contact-form{
    button{
        background: #d0162e;
        color:#fff;
    }
}
.company-address{
    background:#d0162e;
    color:#fff;
    position:relative;
    &:after{

        position:absolute;
        bottom:0;
        right:0;
        width:80px;
        height:200px;
        content: " ";
        border-right:80px solid #fff;
        border-top:200px solid transparent;

    }

}
</style>
