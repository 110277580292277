<template>
    <div class="filter-list">
        <p class="mb-0 mr-1">FILTERS:</p>
        <ul>
            <li>
                <div>
                <span class="text-uppercase clear-btn" @click="clearFilter()">clear all </span>
                </div>
            </li>
            <li v-for="(value,name, index) in activeParam" :key="index">
                <div v-if="name != 'scID' && name !='ccID' && name != 'page'">
                    <span v-for="(val,index) in value.split('|')" :key="index">

                        <span class="text-uppercase filter-show"><b-icon icon="x" scale="1.5" class="remove-filter text-primary mr-1" aria-hidden="true" @click="deleteValueinParam(name,val)"></b-icon><strong v-html="name.replace(/_/g,' ')+': '+val"></strong></span>
                    </span>

                </div>

            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loading: false,
            currentParam: this.$route.query,
            activeParam: {...this.$route.query},
        };
    },
    methods:{
        // clear all filter
        clearFilter(){
            this.$router.push({ query:{scID: this.currentParam.scID, ccID: this.currentParam.ccID }});
        },
        // delete value in param
        deleteValueinParam(filterName, value){
            // duplicate the object
            const params={...this.currentParam};

            // create an array of filter options
            const arrayofFilterOptions=params[filterName].split('|');

            // delete the value from the array
            arrayofFilterOptions.splice(arrayofFilterOptions.indexOf(value),1);

            if( arrayofFilterOptions.length>0){
                // join all the values from the array
                const updatedParam=arrayofFilterOptions.join('|');
                // updating the value in the filter
                params[filterName]=updatedParam;

                // push new param
                this.$router.push({ query: params });
            }else{
                //remove param itself
                delete params[filterName];
                // push new param
                this.$router.push({ query: params});
            }

        },
    }
}
</script>
<style lang="scss" scoped>
.filter-list{
    border-bottom: 1px solid #efefef;
    border-top: 1px solid #efefef;

    margin-top: 0.3rem ;
    padding: 0.5rem 0 0.3rem 0;
    display:grid;
    grid-template-columns:70px auto;
    p{
        // font-family: "acumin pro condensed", Arial, Helvetica, sans-serif;

        // font-family: "Roboto Condensed", sans-serif;
        font-weight:300;
    }
    ul{
        list-style:none;
        display:flex;
        flex-wrap:wrap;

    }
    .remove-filter{
        border:0.5px solid #cd1b00;
        line-height: 0.6;
    }
    .filter-show{
        display:inline-block;
        background-color: #fff;
        border: 1px solid #e6e6e7;
        color: #666;
        font-size: .8rem;
        font-weight: 600;
        margin:0 0.2rem 0.2rem 0;
        padding: 0.25rem 0.5rem;
        text-decoration: none;
        i{
            cursor:pointer;
        }
    }
    .clear-btn{
        background: #cd1b00;
        color: #fff;
        cursor:pointer;
        border: 1px solid #e6e6e7;
        font-size: .8rem;
        padding: 0.25rem 0.5rem 0.4rem 0.5rem;
        margin-right:0.2rem;

    }
}

</style>
