<template>
    <div>
        <div style="width: 816x; height:138px; background-image:url('/images/spec-sheet-top.png');">

            <div style="position: relative; top:88px; text-align:right; padding-right:70px; font-weight:300; color:#999">
                STYLE: <span style="font-size:26px;" v-html="prodstyle"></span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        prodstyle:String
    },
    data() {
        return {
        }

    },
    computed: {

    },
    created(){
    },

}
</script>
