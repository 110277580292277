<template>
    <div v-if="product && prodccID">
        <div v-if="prodccID =='11613' || prodccID=='11554'">
            <table>
                <tr>
                    <th style="text-align:left; width:15%">SKU</th>
                    <th style="width:11%">FRAME COLOR</th>
                    <th style="width:11%">LENS</th>
                    <th style="width:11%">LENS COLOR</th>
                    <th style="width:6%">LENS COATING</th>
                    <th style="width:15%">UPC</th>
                    <th style="width:15%">UPC (INNER)</th>
                    <th style="width:15%">UPC (CASE)</th>
                </tr>
                <tr v-for="(item,index) in SKUdata" :key="index">
                    <td style="text-align:left;">{{ item.SKU }}</td>
                    <td>{{ item.frame_color }}</td>
                    <td>{{ item.lens }}</td>
                    <td>{{ item.lens_color }}</td>
                    <td>{{ item.lens_coating }}</td>
                    <td>{{ item.UPC }}</td>
                    <td>{{ item.UPC_inner }}</td>
                    <td>{{ item.UPC_case }}</td>
                </tr>
            </table>
            <div v-if="eyeCHECK">
                <p style="clear:both; margin-top:16px; color:#333;font-size: 10px;"><strong>KEY:</strong> AF = Anti-Fog / AR = Anti-Reflective / AS = Anti-Scratch / FL360 = Fogless 3Sixty / U = Uncoated</p>

            </div>
        </div>
        <div v-else>
            <table>
                <tr>
                    <th style="text-align:left;">SKU</th>
                    <th>COLOR</th>
                    <th>SIZE</th>
                    <th>UPC</th>
                    <th>UPC (INNER)</th>
                    <th>UPC (CASE)</th>
                </tr>
                <tr v-for="(item,index) in SKUdata" :key="index">

                    <td style="text-align:left;">{{ item.SKU }}</td>
                    <td>{{ item.color }}</td>
                    <td>{{ item.size }}</td>
                    <td>{{ item.UPC }}</td>
                    <td>{{ item.UPC_inner }}</td>
                    <td>{{ item.UPC_case }}</td>
                </tr>
            </table>

        </div>
    </div>
</template>
<style lang="scss" scoped>
table {
    width:100%;
    border-collapse: collapse;
}
table tr{
    font-size:0.6rem;
}
table tr:nth-child(2n) {
    background-color: #f7f7f7;
}
table tr th{
    font-weight: bold;
    border-bottom: solid 1px #333;
    padding: 1px 1px;
}

</style>
<script>

import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
            eyeCHECK:0,
            prodccID:"",
            SKUdata:[],

        }

    },
    computed: {
    },
    created(){
        let eyeStyle;

        const unwatch=this.$watch('product',()=>{
            this.prodccID=this.product.category.commodity_code_id;
            if (this.prodccID == "11613" || this.prodccID == "11554") {
                this.eyeCHECK = 1;

                axios.get(`/api/getprod/${this.product.prod_id}/`).then(response => {
                    let SKUrow = response.data[0];
                    //style build
                    eyeStyle = SKUrow.plp_item.split("-");
                    eyeStyle = eyeStyle[0]+"-"+eyeStyle[1];

                    axios.get(`/api/getUPCforEyewear/${eyeStyle}/`).then(response => {
                        let UPCrows=response.data;
                        UPCrows.forEach((UPCrow,index)=>{

                            axios.get(`/api/product/${UPCrow.prod_id}/`).then(response => {
                                let EYErow=response.data[0];
                                let lensCoating="";
                                //simplify lens coating
                                let lcEXP = EYErow.lens_coating.split("--");
                                lcEXP.forEach((lc,indexLC)=>{
                                    if (lc && lensCoating) {
                                        lensCoating = lensCoating+"/";
                                    }
                                    switch(lc) {
                                        case "4A Coating": lensCoating =lensCoating+ "4A"; break;
                                        case "4A": lensCoating =lensCoating+ "4A"; break;
                                        case "Anti-Fog": lensCoating =lensCoating+ "AF"; break;
                                        case "Anti-Scratch": lensCoating =lensCoating+ "AS"; break;
                                        case "Anti-Reflective": lensCoating =lensCoating+ "AR"; break;
                                        case "FogLess 3Sixty": lensCoating =lensCoating+ "FL360"; break;
                                        case "Uncoated": lensCoating =lensCoating+ "U"; break;
                                    }

                                });
                                let lens="",upc="";
                                if (EYErow.lens == "Indoor Outdoor") {lens = "I/O";}
                                else {lens = EYErow.lens;}
                                if (UPCrow.upc=="") {upc=UPCrow.upcA_dup;} else {upc=UPCrow.upc;}

                                this.SKUdata.push({
                                        "SKU":UPCrow.sku,
                                        "frame_color":UPCrow.color,
                                        "lens":lens,
                                        "lens_color":EYErow.lens_color,
                                        "lens_coating":lensCoating,
                                        "UPC":upc,
                                        "UPC_inner":UPCrow.upc_ip,
                                        "UPC_case":UPCrow.upc_case
                                    } );

                            });

                        });
                    });
                });
            }
            else{

                axios.get(`/api/getUPC/${this.product.ew_style_id}/`).then(response =>{
                    let UPCrows=response.data;
                    let upc="";
                    UPCrows.forEach((UPCrow,index)=>{

                        if (UPCrow.upc=="") {upc=UPCrow.upcA_dup;}
                        else {upc=UPCrow.upc;}
                        this.SKUdata.push({
                            "SKU":UPCrow.sku,
                            "color":UPCrow.color,
                            "size":UPCrow.size,
                            "UPC":upc,
                            "UPC_inner":UPCrow.upc_ip,
                            "UPC_case":UPCrow.upc_case,
                        });
                    });

                });

            }
            unwatch();
        });
    },
}
</script>
