<template>

    <div v-if="product.en_511"  class="product-icon-tooltip">
        <div  class="prod-icon icon-tall">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p><span>Protective Gloves Against Cold: </span><strong v-html="product.en_511"></strong></p>
                <p><span>Convective Cold Resistance: </span><strong v-html="product.en_511_convect_cold"></strong></p>
                <p><span>Contact Cold Resistance: </span><strong v-html="product.en_511_contact_cold"></strong></p>
                <p><span>Water Permeability: </span><strong v-html="product.en_511_water"></strong></p>

                <p>
                    The EN 511 standard specifies the requirements and performance levels of work gloves against cold as low as -50°C. Protective devices against: convective cold, contact cold and water penetration. Convective and contact tests are graded on a scale from 0 to 4, with 0 signifying that the glove failed the test, and 4 demonstrating it has achieved the maximum resistance in that specific area. Water permeation is grade on a scale of 0 to 1 where 0 indicates “water penetration after 30 minutes” and 1 indicates “no water penetration after 30 minutes”
                </p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='en-511-2006'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
