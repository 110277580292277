<template>
    <footer>
        <div class="footer-links-container row">
            <div class="col-md-3 mt-3">
                <div class="mb-3">
                    <p class="heading"> PRODUCTS</p>
                    <ul class="links">
                        <!-- <li>
                            <router-link to="">Coated Gloves</router-link>
                        </li>
                        <li>
                            <router-link to="">Performance Gloves</router-link>
                        </li>
                        <li>
                            <router-link to="">Leather Gloves</router-link>
                        </li>
                        <li>
                            <router-link to="">General Gloves</router-link>
                        </li>
                        <li>
                            <router-link to="">Disposable Gloves</router-link>
                        </li> -->
                        <li>
                            <router-link to="/products?scID=2560">Work Gloves</router-link>
                        </li>
                        <li>
                            <router-link to="/products?scID=2563&ccID=11558">Rain Gear</router-link>
                        </li>
                    </ul>
                </div>
                <div>

                    <p class="heading">RESOURCES</p>
                    <ul class="links">
                        <li>
                            <router-link :to="{ name: 'privacy' }"
                                >Privacy Policy</router-link
                            >
                        </li>
                        <li>
                            <router-link :to="{ name: 'privacy' }"
                                >Cookie Settings</router-link
                            >
                        </li>

                        <!-- <li><a href="{{url('dashboard')}}">My Tools</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="social col-md-3 mt-3">
                <div  class="mb-3">
                    <p class="heading d-block">ABOUT US</p>
                    <ul>
                        <li>
                            <router-link :to="{ name: 'aboutus' }"
                                >We are Boss</router-link
                            >
                        </li>
                        <li>
                            <a href="https://www.pipglobal.com/sustainability" target="blank">Sustainability</a>
                        </li>
                   </ul>
                </div>
                <div>
                    <p class="heading d-block">CONNECT WITH US</p>
                    <a href="https://www.facebook.com/BossGloves" target="blank"
                        ><i class="fab fa-facebook fa-2x p-1" role="presentation"></i
                    ></a>
                    <a href="https://instagram.com/BossGloves" target="blank"
                        ><i class="fab fa-instagram fa-2x p-1" role="presentation"></i
                    ></a>
                </div>
            </div>
            <div class="contact col-md-3 mt-3">
                <div  class="mb-3">
                    <p class="heading d-block">LET US HELP</p>
                    <ul>
                        <li>
                            <router-link :to="{ name: 'contact' }" >Contact Us</router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'literature' }" >Catalog Downloads</router-link>
                        </li>
                   </ul>
                </div>
                <div>
                    <div class="phone">
                        <p class="heading">call us</p>
                        <p class="number">
                            <a href="tel:5188610133">(518) 861-0133</a>
                        </p>
                    </div>
                    <div class="phone">
                        <p class="heading">customer service direct</p>
                        <p class="number">
                            <a href="tel:8552846800">(855) 284-6800</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="location col-md-3 mt-3">
                <div class="address">
                    <p class="heading">West chester protective gear</p>
                    <p class="address">
                       11500 Canal Road<br />Cincinnati, OH 45241
                    </p>
                </div>
                <div class="address">
                    <p class="heading">global headquarters</p>
                    <p class="address">
                        25 British American Blvd.<br />Latham, NY 12110
                    </p>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <p class="font-weight-light" style="font-size: .8rem;">
                    ©{{ new Date().getFullYear() }} by Boss Gloves. All Rights Reserved.
                </p>
            </div>
        </div>
        <cookie-law theme="pip" :buttonText="text">
            <div slot-scope="props" class="w-100 row p-2">
                <p class="col-9">
                    This site uses cookies to provide you with the best
                    experience possible. By continuing to use this website
                    without modifying your settings, you are consenting to our
                    use of cookies. Learn more and how to manage your settings.
                    <router-link to="/cookies" class="cookie-link"
                        >More Information</router-link
                    >
                </p>
                <button
                    @click="props.accept"
                    class="btn btn-primary col-3 pipButton"
                >
                    I Accept
                </button>
            </div>
        </cookie-law>
    </footer>
</template>

<script>
import CookieLaw from "vue-cookie-law";
export default {
    data() {
        return {
            text: "Accept Cookies"
        };
    },
    components: { CookieLaw }
};
</script>

<style lang="scss">
footer {
    padding: 3em 4rem 2rem 4rem;
    a {
        color: white;
        font-weight: normal;
    }
}
.Cookie--pip {
    background: #000;
    padding: 2rem;
    font-size: 0.9rem;
}

.pipButton {
    line-height: 1;
    max-height: 35px;
    max-width: 100px;
    margin-left: auto;
    font-size: 0.9rem;
}



a.cookie-link {
    color: #cd1b00;
    margin-left: 0.5rem;
    &:hover {
        text-decoration: underline;
    }
}
</style>
