<template>
    <div class="row">
        <div class="col-md-2 sidebar">
            <div class="font-weight-normal pb-3">
                <div v-if="user.role == 'admin'">
                    <h1>Edit User</h1>
                </div>
                <div v-else>
                    <h1>Account Details</h1>
                </div>
            </div>
            <div class="lines d-none d-sm-none d-lg-block"></div>
        </div>
        <div class="col-md-10">

            <div class="col-sm-12 col-md-6" v-if="user">
                <form class="mb-5">
                    <div class="form-group">
                        <label for="name">Name:</label>
                        <input
                            type="text"
                            name="name"
                            class="form-control"
                            v-model="user.name"
                            :class="[{ 'is-invalid': errorFor('user.name') }]"
                        />
                        <v-errors :errors="errorFor('name')"></v-errors>
                    </div>
                    <div class="form-group">
                        <label for="company">Company:</label>
                        <input
                            type="text"
                            name="company"
                            class="form-control"
                            v-model="user.company"
                            :class="[
                                { 'is-invalid': errorFor('user.company') }
                            ]"
                        />
                        <v-errors :errors="errorFor('company')"></v-errors>
                    </div>
                    <div class="form-group">
                        <label for="title">Title:</label>
                        <input
                            type="text"
                            name="title"
                            class="form-control"
                            v-model="user.title"
                            :class="[{ 'is-invalid': errorFor('user.title') }]"
                        />
                        <v-errors :errors="errorFor('title')"></v-errors>
                    </div>
                    <div class="form-group">
                        <input
                            type="hidden"
                            name="role"
                            class="form-control"
                            v-model="user.role"
                        />

                    </div>
                    <div class="form-group">
                        <label for="address">Address:</label>
                        <input
                            type="text"
                            name="address"
                            class="form-control"
                            v-model="user.address"
                            :class="[
                                { 'is-invalid': errorFor('user.address') }
                            ]"
                        />
                        <v-errors :errors="errorFor('address')"></v-errors>
                    </div>
                    <div class="form-group">
                        <label for="address2">Address 2:</label>
                        <input
                            type="text"
                            name="address2"
                            class="form-control"
                            v-model="user.address2"
                            :class="[
                                { 'is-invalid': errorFor('user.address2') }
                            ]"
                        />
                        <v-errors :errors="errorFor('address2')"></v-errors>
                    </div>
                    <div class="form-group">
                        <label for="city">City:</label>
                        <input
                            type="text"
                            name="city"
                            class="form-control"
                            v-model="user.city"
                            :class="[{ 'is-invalid': errorFor('user.city') }]"
                        />
                        <v-errors :errors="errorFor('city')"></v-errors>
                    </div>
                    <div class="form-group">
                        <label for="state">State:</label>
                        <input
                            type="text"
                            name="state"
                            class="form-control"
                            v-model="user.state"
                            :class="[{ 'is-invalid': errorFor('user.state') }]"
                        />
                        <v-errors :errors="errorFor('state')"></v-errors>
                    </div>
                    <div class="form-group">
                        <label for="zipcode">Zipcode:</label>
                        <input
                            type="text"
                            name="zipcode"
                            class="form-control"
                            v-model="user.zipcode"
                            :class="[
                                { 'is-invalid': errorFor('user.zipcode') }
                            ]"
                        />
                        <v-errors :errors="errorFor('zipcode')"></v-errors>
                    </div>
                    <div class="form-group">
                        <label for="email">Email:</label>
                        <input
                            type="email"
                            name="email"
                            class="form-control"
                            v-model="user.email"
                            :class="[{ 'is-invalid': errorFor('user.email') }]"
                            disabled
                        />
                        <small class="form-text text-muted">You cannot edit the email.</small>
                        <v-errors :errors="errorFor('email')"></v-errors>
                    </div>
                    <div class="form-group">
                        <label for="phone">Phone Number:</label>
                        <input
                            type="text"
                            name="phone"
                            class="form-control"
                            v-model="user.phone"
                            :class="[{ 'is-invalid': errorFor('user.phone') }]"
                        />
                        <v-errors :errors="errorFor('phone')"></v-errors>
                    </div>
                    <button
                        class="btn btn-success mr-3"
                        :disabled="loading"
                        @click.prevent="saveUser"
                    >
                        SAVE
                    </button>
                </form>
                <success v-if="success">
                    Your data has been updated !!!
                </success>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
label {
    margin-bottom: 0;
    font-weight: 600;
}
</style>
<script>
import { mapState, mapGetters } from "vuex";
import validationErrors from "../mixins/validationErrors";

export default {
    mixins: [validationErrors],
    data() {
        return {
            loading: false,
            message: false,
            route: null
        };
    },

    computed: {
        ...mapState({ user: state => state.user }),
        success() {
            return !this.loading && this.message;
        }
    },
    methods: {
        async saveUser() {
            this.loading = true;
            this.errors = null;
            try {
                await axios.post(`/api/user`, this.user).then(response => {
                    this.message = true;
                });
            } catch (error) {
                this.errors = error.response && error.response.data.errors;
            }
            this.loading = false;
        }
        // async reloadUser(){
        //     this.loading=true;
        //     this.errors=null;

        //     try {
        //         const response=await axios.get(`/api/user/${this.user.id}`);
        //         if(200==response.status){
        //             this.$store.dispatch("loadUser");
        //         }
        //     } catch (error) {
        //          this.errors=error.response && error.response.data.errors;
        //     }
        //     this.loading=false;
        // }
    }
};
</script>
