export function isLoggedIn(){
    return localStorage.getItem("isLoggedIn")== 'true';

}
export function getUserEmail(){
    return localStorage.getItem("userEmail");
}


export function logIn(email,approval,userRole){
    localStorage.setItem("isLoggedIn",true);
    localStorage.setItem("userEmail",email);
    localStorage.setItem("approval", approval);
    localStorage.setItem("userRole", userRole);
}
export function logOut(){
    localStorage.setItem("isLoggedIn",false);
    localStorage.setItem("userEmail",null);
    localStorage.removeItem("approval");
    localStorage.removeItem("userRole");
}

export function isCustomerLoggedIn(){
    return localStorage.getItem("isCustomerLoggedIn")== 'true';

}
export function getCustomerEmail(){
    return localStorage.getItem("customerEmail");
}

export function customerLogIn(email){
    localStorage.setItem("isCustomerLoggedIn",true);
    localStorage.setItem("customerEmail",email);
}
export function customerLogOut(){
    localStorage.setItem("isCustomerLoggedIn",false);
    localStorage.setItem("customerEmail",null);
}
