<template>

    <div v-if="product.video"  class="product-icon-tooltip">
        <div  class="prod-icon icon-square d-flex flex-column align-items-center">
            <i v-html="getIcon[0].svg"></i>
        </div>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='video'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
