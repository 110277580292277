<template>
    <div>
        <a href="#" id="scrollToTop" v-scroll-to="'#pipheader'">
           <i class="fas fa-angle-up"></i>
        </a>
    </div>
</template>

<script>
export default {
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll(event) {
            this.isUserScrolling = window.scrollY > 200;
            if (this.isUserScrolling) {
                $("#scrollToTop").css("display", "flex");
            } else $("#scrollToTop").css("display", "none");
        }
    }
};
</script>

<style lang="scss" scoped>
#scrollToTop {
    position: fixed;
    display: none;
    bottom: 2rem;
    right: 2rem;
    z-index: 1000;
    color:#fff;
    background: #d0162e;
    width: 3.4rem;
    padding: 0.1rem;
    font-size: 1.8rem;
    align-items: center;
    justify-content: center;
}
</style>
