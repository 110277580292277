<template>
    <div v-if="techData.length == tdFILTER.length">
        <p style="margin:1rem 0 0.5rem 0;">
            <span style="font-size:24px; font-weight:500;">
            Technical Data
            </span>
        </p>

        <table style="font-size:10px; width:100%;">
            <tbody>
                <tr v-for="(value,index) in techData" :key="index">
                    <td style="width:50%;">
                        {{ value.attribute }}
                    </td>
                    <td style="width:50%;" v-html="value.value"></td>
                </tr>

                <!-- Adding attributes to the technical spec sheet for internal viewing only -->
                <tr v-if="product.reg_identification_nbr">
                    <td style="width:50%;">
                        RIN
                    </td>
                    <td style="width:50%;">
                        {{ product.reg_identification_nbr }}
                    </td>
                </tr>
                <tr v-if="product.material_content">
                    <td style="width:50%;">
                        Material Content
                    </td>
                    <td style="width:50%;">
                        {{ product.material_content }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<style lang="scss" scoped>
table tbody tr:nth-child(2n) {
    background-color: #f7f7f7;
}
</style>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
            tdFILTERArray:"6|97|88|CP|CD|CW|98",
            tdFILTER: [],
            FILTERrow:null,

            SKUrow:null,
            SKUrows:null,
            prodQTY:null,
            featureList:[],
            techData:[],
            order:[],
        }

    },
    created(){
            axios.get(`/api/getfeaturelist`).then(response => {
                this.featureList=response.data;
            });

            // get all the product sizes and store it in an array
            axios.get(`/api/prodsize/`).then(response => {
                this.order=response.data;
            });



            const unwatch=this.$watch('product',()=>{

                // get all the product skus associated with the style
                axios.get(`/api/getprodsku/${this.product.ew_style_id}/`).then(response => {
                    if (response.data) {
                        //get the prod_sku info on the particular prod_id
                        this.SKUrows=response.data;
                        this.SKUrow = response.data.filter(prod=> prod.prod_id==this.product.prod_id);

                        if(this.SKUrow.length != 0){
                            this.SKUrow=this.SKUrow[0];
                        }else{
                            this.SKUrow=this.SKUrows[0];
                        }

                        switch (this.product.category.plp_cs_uom) {
                            case 'bg':  packageQtyUnitLabel = " Bags"; break;
                            case "bx":  packageQtyUnitLabel = " Boxes"; break;
                            case "dz":  packageQtyUnitLabel = " Dozen";  break;
                            case "ea":  packageQtyUnitLabel = "";  break;
                            case "ip":  packageQtyUnitLabel = " Inner Packs"; break;
                            case "pk":  packageQtyUnitLabel = " Packs";  break;
                            case "pr": packageQtyUnitLabel = " Pair";  break;
                            case "ream":  packageQtyUnitLabel = " Reams";  break;
                            case "st":  packageQtyUnitLabel = " Sets";  break;
                        }



                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        //TECHNICAL DATA: FILTER///////////////////////////////////////////////////////////////////////////////////////
                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        switch (this.product.category.commodity_code_id.toString()) {

                            //ARM PROTECTION///////////////////////////////////////////////////////////////////////////////////////
                            //Arc Rated Sleeve
                            case "11651":
                                this.tdFILTERArray=this.tdFILTERArray+"|3|74|89|73|18";
                                break;
                            //Claw Cover Sleeves
                            case "11733":
                                this.tdFILTERArray=this.tdFILTERArray+"|3|74|89|73|18";
                                break;
                            //Cut Resistant Sleeve
                            case "11622":
                                this.tdFILTERArray=this.tdFILTERArray+"|3|74|89|73|18";
                            break;
                            //General Duty Sleeve
                            case "11666":
                                this.tdFILTERArray=this.tdFILTERArray+"|3|74|89|73|18";
                            break;
                            //Heat Defense Sleeve
                            case "11650":
                                this.tdFILTERArray=this.tdFILTERArray+"|3|74|89|73|18";
                            break;
                            //Metal Mesh Sleeves
                            case "11725":
                                this.tdFILTERArray=this.tdFILTERArray+"";
                            break;


                            //COLD PROTECTION///////////////////////////////////////////////////////////////////////////////////////
                            //Heated Apparel
                            case "11712":
                                this.tdFILTERArray=this.tdFILTERArray+"";
                            break;
                            //Warming
                            case "11662":
                                this.tdFILTERArray=this.tdFILTERArray+"";
                            break;

                            //CONTROLLED ENVIRNOMENTS///////////////////////////////////////////////////////////////////////////////////////
                            //CE Apron
                            case "11588":
                                this.tdFILTERArray=this.tdFILTERArray+"|17";
                            break;
                            //CE Fingercots
                            case "11686":
                                this.tdFILTERArray=this.tdFILTERArray+"|15|5|16|36";
                            break;
                            //CE Gloves
                            case "11553":
                                if (this.product.polymer!="" && this.product.polymer != null) {
                                this.tdFILTERArray=this.tdFILTERArray+"|15|5|14|17|36|16";
                                } else {
                                this.tdFILTERArray=this.tdFILTERArray+"|3|4|8|7|5|9|18|10|14|37";
                                }
                            break;
                            //CE Supplies
                            case "11548":
                                this.tdFILTERArray=this.tdFILTERArray+"";
                            break;
                            //Reusable Clothing
                            case "11697":
                                this.tdFILTERArray=this.tdFILTERArray+"|101|45|40";
                            break;

                            //ELECTRICAL SAFETY///////////////////////////////////////////////////////////////////////////////////////
                            //Arc Accessories
                            case "11640":
                                this.tdFILTERArray=this.tdFILTERArray+"|72";
                            break;
                            //Arc Clothing
                            case "11642":
                                this.tdFILTERArray=this.tdFILTERArray+"|101|45|10|32|44|46|40";
                            break;
                            //Arc Protection Kits
                            case "11644":
                                this.tdFILTERArray=this.tdFILTERArray+"|83";
                            break;
                            //Electrical Glove Protection
                            case "11624":
                                this.tdFILTERArray=this.tdFILTERArray+"|81|17|14";
                            break;
                            //Glove Accessories
                            case "11648":
                                this.tdFILTERArray=this.tdFILTERArray+"|81|72|17";
                            break;
                            //Insulating Blankets
                            case "11698":
                                this.tdFILTERArray=this.tdFILTERArray+"|81";
                            break;
                            //Insulating Glove Kit
                            case "11649":
                                this.tdFILTERArray=this.tdFILTERArray+"|81|17|14";
                            break;
                            //Insulating Gloves
                            case "11620":
                                this.tdFILTERArray=this.tdFILTERArray+"|81|17|14|181";
                            break;
                            //Insulating Sleeves
                            case "11670":
                                this.tdFILTERArray=this.tdFILTERArray+"|81";
                            break;

                            //ERGONOMICS/////////////////////////////////////////////////////////////////////////////////////////////
                            //Ergonomic Supports
                            case "11614":
                                this.tdFILTERArray=this.tdFILTERArray+"|45|40|74";
                            break;
                            //Knee Protection
                            case "11564":
                                this.tdFILTERArray=this.tdFILTERArray+"";
                            break;

                            //EYE PROTECTION/////////////////////////////////////////////////////////////////////////////////////////////
                            //Safety Eyewear
                            case "11613":
                                this.tdFILTERArray=this.tdFILTERArray+"|21|23|28|24|22|25|26|29|27";

                            break;
                            //Safety Goggles
                            case "11554":
                                this.tdFILTERArray=this.tdFILTERArray+ "|78|80|28|79|21|22|27";
                            break;
                            //Face Protection
                            case "11611":
                                this.tdFILTERArray=this.tdFILTERArray+"|21|53|81|16|28";
                            break;
                            //Safety Eyewear Accessories
                            case "11611":
                                this.tdFILTERArray=this.tdFILTERArray+ "";
                            break;

                            //FALL PROTECTION/////////////////////////////////////////////////////////////////////////////////////////////
                            //Tool Tethering
                            case "11693":
                                this.tdFILTERArray=this.tdFILTERArray+ "";
                            break;

                            //FIRST AID///////////////////////////////////////////////////////////////////////////////////////////////////
                            //First Aid Kit
                            case "11582":
                                this.tdFILTERArray=this.tdFILTERArray+"|159|156|155";
                            break;

                            //FOOT PROTECTION///////////////////////////////////////////////////////////////////////////////////////////////////
                            //Over-The-Shoe
                            case "11708":
                                this.tdFILTERArray=this.tdFILTERArray+"|81|128|126|127|71|40";
                            break;
                            //Over-The-Sock
                            case "11707":
                                this.tdFILTERArray=this.tdFILTERArray+"|81|128|126|127|71|40";
                            break;

                            //HAND PROTECTION///////////////////////////////////////////////////////////////////////////////////////
                            //Chemical Resistant Gloves
                            case "11559":
                                this.tdFILTERArray=this.tdFILTERArray+ "|15|16|18|17|14|5|19";
                            break;
                            //Claw Cover Gloves
                            case "11732":
                                if (this.product.hide) {
                                    this.tdFILTERArray=this.tdFILTERArray+"|3|32|14|100";
                                } else if (this.product.coating == "Uncoated") {
                                    this.tdFILTERArray=this.tdFILTERArray+"|3|4|9|14|32|100";
                                } else {
                                    this.tdFILTERArray=this.tdFILTERArray+"|3|4|7|8|5|9|14|100";
                                }
                            break;
                            //Cut Resistant Gloves
                            case "11570":
                                if (this.product.hide) {
                                    this.tdFILTERArray=this.tdFILTERArray+"|3|32|14|100";
                                } else if (this.product.coating == "Uncoated") {
                                this.tdFILTERArray=this.tdFILTERArray+"|3|4|9|14|32|100";
                                } else {
                                    this.tdFILTERArray=this.tdFILTERArray+"|3|4|7|8|5|9|14|100";
                                }
                            break;
                            //Cold Protection Gloves
                            case "11587":
                                if (this.product.hide || this.product.palm == "Leather" || this.product.palm == "Synthetic Leather") {
                                this.tdFILTERArray=this.tdFILTERArray+"|3|18|14|32|31|30|35|20";
                                } else if (this.product.coating == "Uncoated") {
                                this.tdFILTERArray=this.tdFILTERArray+"|3|18|14";
                                } else {
                                this.tdFILTERArray=this.tdFILTERArray+"|3|18|14|4|7|8|5|9";
                                }
                            break;
                            //Disposable Gloves
                            case "11547":
                                this.tdFILTERArray=this.tdFILTERArray+"|15|31|16|36|17|5|38";
                            break;
                            //Extended Use Disposable Gloves
                            case "11731":
                                this.tdFILTERArray=this.tdFILTERArray+"|15|31|16|36|17|5|38";
                            break;
                            //Fabric Work Gloves
                            case "11552":
                                this.tdFILTERArray=this.tdFILTERArray+"|3|31|30|20|14|5|4|7|8|10";
                            break;
                            //General Purpose Gloves - Coated
                            case "11549":
                                this.tdFILTERArray=this.tdFILTERArray+"|3|4|7|8|5|9|14|100";
                            break;
                            //General Purpose Gloves - Uncoated
                            case "11550":
                                this.tdFILTERArray=this.tdFILTERArray+"|3|9|10|14";
                            break;
                            //Heat Protection Gloves
                            case "11578":
                                if (this.product.coating) {
                                this.tdFILTERArray=this.tdFILTERArray+"|3|18|10|4|7|8|5|14|17";
                                } else {
                                this.tdFILTERArray=this.tdFILTERArray+"|3|18|10|14|17";
                                }
                            break;
                            //High Performance Gloves
                            case "11581":
                                if (this.product.hide) {
                                this.tdFILTERArray=this.tdFILTERArray+"|32|30|35|20|14|40|100";
                                } else {
                                this.tdFILTERArray=this.tdFILTERArray+"|3|4|7|8|5|9|30|35|14|100";
                                }
                            break;
                            //Leather Drivers Gloves
                            case "11573":
                                this.tdFILTERArray=this.tdFILTERArray+"|32|31|14|33|20";
                            break;
                            //Leather Palm Gloves
                            case "11621":
                                this.tdFILTERArray=this.tdFILTERArray+"|32|31|30|14|35|20|33";
                            break;
                            //Metal Mesh Gloves
                            case "11724":
                                this.tdFILTERArray=this.tdFILTERArray+"";
                            break;
                            //Parade & Uniform Gloves
                            case "11568":
                                this.tdFILTERArray=this.tdFILTERArray+"|3|14|30|33|40";
                            break;

                            //HEAD PROTECTION///////////////////////////////////////////////////////////////////////////////////////////////////
                            //Accessories
                            case "11721":
                                this.tdFILTERArray=this.tdFILTERArray+"";
                            break;
                            //Bump Caps
                            case "11576":
                                this.tdFILTERArray=this.tdFILTERArray+"|76|58|59|60|57|61";
                            break;
                            //FR Hoods
                            case "11652":
                                this.tdFILTERArray=this.tdFILTERArray+"|45|66|65";
                            break;
                            //Go-To-Work Kits
                            case "11645":
                                this.tdFILTERArray=this.tdFILTERArray+"";
                            break;
                            //Hard Hats
                            case "11575":
                                this.tdFILTERArray=this.tdFILTERArray+"|76|58|59|60|57|62|75|61";
                            break;
                            //Hoods & Paint Caps
                            case "11619":
                                this.tdFILTERArray=this.tdFILTERArray+"";
                            break;
                            //Safety Helmets
                            case "11738":
                                this.tdFILTERArray=this.tdFILTERArray+"|76|58|59|60|57|62|75|61";
                            break;
                            //Winter Caps & Liners
                            case "11628":
                                this.tdFILTERArray=this.tdFILTERArray+"|45|68|66|67|65";
                            break;

                            //HEARING PROTECTION///////////////////////////////////////////////////////////////////////////////////////////////////
                            //Ear Muffs
                            case "11574":
                                this.tdFILTERArray=this.tdFILTERArray+"|64|56";
                            break;
                            //Ear Plugs
                            case "11598":
                                this.tdFILTERArray=this.tdFILTERArray+"|64|55|53|54|38";
                            break;

                            //HEAT STRESS//////////////////////////////////////////////////////////////////////////////////////////////////////////
                            //Absorptive
                            case "11655":
                                this.tdFILTERArray=this.tdFILTERArray+"|45|40";
                            break;
                            //Evaporative Cooling
                            case "11616":
                                this.tdFILTERArray=this.tdFILTERArray+ "|45|40";
                            break;
                            //Phase Change Cooling
                            case "11627":
                                this.tdFILTERArray=this.tdFILTERArray+ "|45|40";
                            break;
                            //Sun Protection
                            case "11656":
                                this.tdFILTERArray=this.tdFILTERArray+"|45|40|42|43";
                            break;

                            //Hydration
                            case "11736":
                                this.tdFILTERArray=this.tdFILTERArray+"|183|184|185|186|187|188";
                            break;

                            //PROTECTIVE CLOTHING////////////////////////////////////////////////////////////////////////////////////////////////////
                            //Aprons
                            case "11560":
                                this.tdFILTERArray=this.tdFILTERArray+"|45|10|40|44|46";
                            break;
                            //Cut Resistant Wear
                            case "11654":
                                this.tdFILTERArray=this.tdFILTERArray+"|176|40|101|177|45|89|178|179|46|42|44|180";
                            break;
                            //Disposable Clothing
                            case "11561":
                                this.tdFILTERArray=this.tdFILTERArray+"|101|45|40|48|102|103|104";
                            break;
                            //FR Clothing
                            case "11566":
                                this.tdFILTERArray=this.tdFILTERArray+"|45|10|40|44|46";
                            break;
                            //Face Protection
                            case "11694":
                                this.tdFILTERArray=this.tdFILTERArray+ "";
                            break;
                            //Hi-Vis Apparel
                            case "11629":
                                this.tdFILTERArray=this.tdFILTERArray+ "|101|45|42|43|40|44|46";
                            break;
                            //Hi-Vis Cold Gear
                            case "11711":
                                this.tdFILTERArray=this.tdFILTERArray+ "|101|45|42|43|40|47|48|44|46";
                            break;
                            //Metal Mesh Clothing
                            case "11723":
                                this.tdFILTERArray=this.tdFILTERArray+ "";
                            break;
                            //Rainwear
                            case "11558":
                                this.tdFILTERArray=this.tdFILTERArray+ "|45|16|44|46|48|17|40";
                            break;

                            //RESPIRATORY SAFETY////////////////////////////////////////////////////////////////////////////////////////////////////
                            //Cartridges
                            case "11671":
                                this.tdFILTERArray=this.tdFILTERArray+ "|99";
                            break;
                            //Disposable Masks
                            case "11571":
                                this.tdFILTERArray=this.tdFILTERArray+ "|146|85|86";
                            break;
                            //Reusable Facepieces
                            case "11668":
                                this.tdFILTERArray=this.tdFILTERArray+ "|146|99";
                            break;

                            //WARNING BEACONS////////////////////////////////////////////////////////////////////////////////////////////////////
                            //Eflare Beacons
                            case "11601":
                                this.tdFILTERArray=this.tdFILTERArray+ "|69|70|72";
                            break;
                            //Flash Baton
                            case "11572":
                                this.tdFILTERArray=this.tdFILTERArray+ "";
                            break;

                            //WELDING SAFETY////////////////////////////////////////////////////////////////////////////////////////////////////
                            //Accessories
                            case "11709":
                                this.tdFILTERArray=this.tdFILTERArray+ "";
                            break;
                            //FR Clothing
                            case "11701":
                                this.tdFILTERArray=this.tdFILTERArray+"|101|45|10|32|40|44|46";
                            break;
                            //FR Hoods
                            case "11702":
                                this.tdFILTERArray=this.tdFILTERArray+"|45|66|65";
                            break;
                            //Hand Protection
                            case "11703":
                            if (this.product.hide) {
                                this.tdFILTERArray=this.tdFILTERArray+"|32|18|31|14|17|30|35|40|20|33";
                            } else {
                                this.tdFILTERArray=this.tdFILTERArray+"|3|4|8|7|5|14|33";
                            }
                            break;
                            //Winter Caps & Liners
                            case "11706":
                                this.tdFILTERArray=this.tdFILTERArray+"|45|68|66|67|65";
                            break;
                        } //switch(this.product.category.commodity_code_id)

                        //global tech data attributes
                        //11 = Construction | 13 = Certifications | 164 = Product Circularity
                        this.tdFILTERArray=this.tdFILTERArray+"|11|13|164";

                        //exlode the filter ids
                        this.tdFILTER = this.tdFILTERArray.split("|");


                        //cycle through the filter ids
                        this.tdFILTER.forEach((tdFID, index )=>{

                            //get the correct language for the filter
                            // $FILTERres = $dbh->prepare("SELECT head_EN , filter FROM prod_filters WHERE id=:id");
                            // $FILTERarray = array(":id" => tdFID);
                            // $FILTERres->execute(FILTERarray);
                            // $FILTERrow = $FILTERres->fetch();
                            let FILTERcol, FILTERhead, FILTERtype;
                            let tdATTR="";
                            let multiCheck=0;
                            let CERTs=[];



                            axios.get(`/api/filter/${tdFID}/`).then(response => {

                                let tdATTRIBUTE=[];
                                this.FILTERrow=response.data[0];

                                //put filter in a variable
                                FILTERcol = this.FILTERrow.filter;

                                //put filter head in variable
                                FILTERhead = this.FILTERrow.head_EN;

                                //put filter type
                                FILTERtype= this.FILTERrow.filter_type;



                                switch(tdFID){
                                    //thickness
                                    case "16": tdATTR = this.product.thickness; break;
                                    //weight
                                    case "10": tdATTR = this.product.weight; break;
                                    //HEIGHT
                                    case "71": tdATTR = this.product.height; break;
                                    //lIGHT INTENSITY
                                    case "70": tdATTR = this.product.light_intensity;  break;

                                    //put inches on Collar Height
                                    case "177":
                                        if (tdATTR != "") {
                                            tdATTR = tdATTR + "&quot;";
                                        }
                                        break;

                                    //certification
                                    case "13":

                                        //get all the filters with the cert filter type
                                        CERTs=this.featureList.filter(feature=> feature.filter_type=="cert");

                                        CERTs.forEach((cert,certIndex)=>{

                                            let CERTrow=null;

                                            if (this.product[cert.filter].toString() == "1") {

                                                //get the name of the cert
                                                CERTrow=this.featureList.filter(feature=> feature.filter==cert.filter && feature.filter_id==tdFID)[0];

                                                //add cert name to the td var
                                                if (multiCheck==1) {
                                                    tdATTR=tdATTR+", ";
                                                }
                                                tdATTR=tdATTR+CERTrow.attribute_EN;

                                                multiCheck = 1;
                                            }
                                        });


                                        multiCheck=0;
                                    break;

                                    //Sustainability
                                    case "164":
                                        // let ATTRrow=[];
                                        let filtername="sustainability";
                                        let countSustabilityValue=0;
                                        //get the correct language for the attribute
                                        // ATTRrow= this.featureList.filter(feature=> (feature.filter_id==tdFID));

                                        if(this.product[filtername] != ""){
                                            this.product[filtername].split("--").forEach(value=>{
                                                if(value != ""){
                                                    if(countSustabilityValue != 0){
                                                        tdATTR=tdATTR+"<br />";
                                                    }
                                                    tdATTR=tdATTR+value.trim();
                                                    tdATTR = tdATTR.replace('Made from','Contains');
                                                    tdATTR = tdATTR.replace('Recyclable via Terracycle Program', 'Recycled via Terracycle');
                                                    tdATTR = tdATTR.trim().replace(',', '<br />');
                                                    countSustabilityValue++;
                                                }
                                            });
                                        }

                                    break;

                                    //size available over ride
                                    case "97":
                                        let sizesAVAIL;

                                        if(this.SKUrows.length !=0){
                                            //////////////////////////////////////////////////////////////////////////////////
                                            //GET DYNAMIC SIZE RANGE/////////////////////////////////////////////////////////////////////////////

                                            //set the array
                                            let sizeARRAY = [];
                                            let SIZESrow=[];



                                            //get status of item. if stock, do not show non-stock sizes, or delete status
                                            if (this.SKUrow.status == "Stock"){
                                                SIZESrow = this.SKUrows.filter(sku=> sku.status=="Stock" && sku.status!="Discontinued");
                                            }else{
                                                SIZESrow = this.SKUrows.filter(sku=> sku.status!="Discontinued");
                                            }


                                            SIZESrow.forEach((SIZErow,SIZEindex)=>{
                                                sizeARRAY.push(SIZErow.size);
                                            });

                                            //custom sort for sizing
                                            sizeARRAY= this.order.filter(value=> sizeARRAY.includes(value));

                                            //get smallest and largest sizes
                                            let smSIZE = sizeARRAY[0];
                                            let lgSIZE = sizeARRAY[sizeARRAY.length-1];

                                            //only one size available
                                            if (smSIZE == lgSIZE) {

                                                //sku in both ECLIPSE and other ERP, but ECLIPSE is empty
                                                if (smSIZE) {
                                                    smSIZE = this.product.category.web_size_avail;
                                                    sizesAVAIL = smSIZE;
                                                }
                                            }
                                            else {
                                                //find dual sizing
                                                let pos = smSIZE.indexOf("-");
                                                if (pos !== -1) {
                                                    smSIZE = smSIZE.split("-");
                                                    lgSIZE = lgSIZE.split("-");
                                                    sizesAVAIL = smSIZE[0]+"-"+lgSIZE[1];
                                                }//if ($pos !== false)
                                                else {
                                                    sizesAVAIL = smSIZE+"-"+lgSIZE;
                                                }//else ($pos !== false)
                                            }//else ($smSIZE == $lgSIZE)



                                        }//if (response.data)

                                        //in case skus are not in prod_sku table
                                        else {
                                            if (this.product.category.web_size_avail) {
                                                sizesAVAIL = this.product.web_size_avail;

                                            }//if (!empty($row["web_size_avail"]))
                                        }//else (response.data)

                                        tdATTR = sizesAVAIL;
                                    break;
                                    default:
                                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                        //TECHNICAL DATA: ATTRIBUTE////////////////////////////////////////////////////////////////////////////////////
                                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////



                                        //explode multi attributes

                                        tdATTRIBUTE = this.product[FILTERcol].split("--");


                                        //cycle through the attributes
                                        tdATTRIBUTE.forEach((tdA, tdAindex )=>{

                                            let ATTRrow=[];


                                            //make sure its not empty
                                            if (tdA) {
                                                // check if the value of the attribute type is decimal or numberic
                                                if(FILTERtype != "decimal"){
                                                    //color filter combination
                                                    if (tdFID == "7" || tdFID == "28") {
                                                        tdFID = "6";
                                                    }


                                                    //get the correct language for the attribute
                                                    ATTRrow= this.featureList.filter(feature=> (feature.filter_id==tdFID && feature.attribute_EN.trim()==tdA.trim()));

                                                    if(ATTRrow.length==0){
                                                        tdATTR=tdA;
                                                    }else{
                                                        ATTRrow= this.featureList.filter(feature=> (feature.filter_id==tdFID && feature.attribute_EN.trim()==tdA.trim()))[0];

                                                        //put attributes in a variable
                                                        if (multiCheck==1) {
                                                            tdATTR=tdATTR+", ";
                                                        }
                                                        tdATTR=tdATTR+ATTRrow.attribute_EN;

                                                        //add color suffix for head protection
                                                        // if (colorSuffix) {
                                                        //     tdATTR=tdATTR+colorSuffix;
                                                        //     colorSuffix=null;
                                                        // }

                                                        multiCheck = 1;


                                                        //is number and is not a 1/0 field
                                                        if (!isNaN(tdA) && tdA > 1) {
                                                            tdATTR = tdA;
                                                            if (tdFID == "17") {
                                                            tdATTR = tdATTR + "&quot;";
                                                            }
                                                        }

                                                        //default to NO for empty values
                                                        if (tdA=="" && tdATTR=="") {
                                                            tdATTR = "--";
                                                        }

                                                        // 0/1 fields defaults to yes/no
                                                        switch (tdA) {
                                                            case "0":  tdATTR = "--";  break;
                                                            case "1":   tdATTR = "Yes";  break;
                                                        } //switch($tdA)
                                                    }
                                                }else{
                                                    tdATTR=tdA;
                                                }

                                            } //if (!empty($tdA))

                                        });
                                        multiCheck=0;
                                    break;
                                }

                                //show results when attribute value is not empty
                                if(this.checktdFID(tdFID,tdATTR) !=""){
                                    this.techData.push({attribute:FILTERhead, value:tdATTR, filterID:tdFID});

                                    // sort technical data
                                    this.sortTechnicalData();
                                }else{
                                    this.techData.push({attribute:FILTERhead, value: "--", filterID:tdFID});

                                    // sort technical data
                                    this.sortTechnicalData();
                                }


                            })
                            .catch(error=>{
                                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
                                //TECHNICAL DATA: OVERRIDES////////////////////////////////////////////////////////////////////////////////////
                                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

                                let packageQtyUnitLabel="";
                                let packageCaseLabel = "Case";


                                //sizes avail override


                                //case pack override
                                if (tdFID == "CP") {
                                    FILTERhead = "Packed";

                                    this.prodQTY = this.SKUrow.price_qty;
                                    if(this.prodQTY){
                                        tdATTR = this.prodQTY + packageQtyUnitLabel + "/" + packageCaseLabel;
                                    }else{
                                        tdATTR = "--";
                                    }

                                }

                                //case dimensions override
                                if (tdFID == "CD") {
                                    FILTERhead = "Case Dimensions (in)";

                                    if (this.SKUrow.case_length) {

                                        tdATTR = (this.SKUrow.case_length * 0.393701).toFixed(2) + " x " + (this.SKUrow.case_width * 0.393701).toFixed(2) + " x " + (this.SKUrow.case_height * 0.393701).toFixed(2);
                                    } else {
                                        tdATTR = "--";
                                    }
                                }

                                //case dimensions override
                                if (tdFID == "CW") {
                                    FILTERhead = "Case Weight (lbs)";
                                    tdATTR = (parseFloat(this.SKUrow.case_weight) * 2.20462).toFixed(2);
                                }//if ($tdFID == "CW")





                                //show results when attribute value is not empty
                                if(this.checktdFID(tdFID,tdATTR) !=""){
                                    this.techData.push({attribute:FILTERhead, value:tdATTR, filterID:tdFID});

                                    // sort technical data
                                    this.sortTechnicalData();
                                }else{
                                    this.techData.push({attribute:FILTERhead, value: "--", filterID:tdFID});

                                    // sort technical data
                                    this.sortTechnicalData();
                                }


                            });




                        });

                    } //if (response.data)

                });

                unwatch();
            });


    },

    methods:{
        checktdFID:function(tdFID,tdATTR){

            //do not show these attributes if there is no value
            let arTDATTRnoEmptyDisplay = [176,177,178,179,180,40,101,45,89,46,42,44];

            //if it's in the "do not show" array, replace double dash with empty string so it won't print on spec sheet
            if (arTDATTRnoEmptyDisplay.includes(tdFID) && tdATTR == "--") {
                tdATTR = "";
            }

             return tdATTR;
        },
        sortTechnicalData(){
            if(this.techData.length==this.tdFILTER.length){
                var self=this;
                this.techData.sort(function(a, b) {
                    var indexA = self.tdFILTER.indexOf(a.filterID);
                    var indexB = self.tdFILTER.indexOf(b.filterID);
                    return indexA - indexB;
                });
            }
        }
    },
    watch:{

    }


}
</script>


