import { isLoggedIn, logOut, getUserEmail } from "./components/utils/auth";
export default {

    state: {
        isLoggedIn: false,
        userEmail:null,
        user: {},
        categories:[],
        usersList:[],
        filteredUsersList: [],
        message:null,
        success:false,
        error: false,
        useProducts: true,
        useAdmin: false,
        useLocale: false,
        icons:[],

    },
    mutations: {
        setUser(state,payload){
            state.user=payload;
        },
        setUsersList(state,payload){
            state.usersList=payload;
        },
        setFilteredUsersList(state,payload){
            state.filteredUsersList=payload;
        },
        setLoggedIn(state,payload){
            state.isLoggedIn=payload;
        },
        setUserEmail(state,payload){
            state.userEmail=payload;
        },
        loadCategories(state, payload) {
            state.categories = payload;
        },
        loadIcons(state, payload) {
            state.icons = payload;
        },
    },
    getters:{
        getUser:state=>{
            return state.user;
        },
    },
    actions: {
        async loadStoredState(context){
            context.commit("setLoggedIn", isLoggedIn());
            context.commit("setUserEmail", getUserEmail());

            // loading user detail if the user is logged in
            if(isLoggedIn()){
                try {
                    const user= (await axios.get('/user')).data;
                    localStorage.setItem("approval", user.approve);
                    localStorage.setItem("userRole", user.role);

                } catch (error) {
                    dispatch("logout");
                }
            }
        },
        async loadCategories({ commit, state }) {
            const response = await axios.get(
                "/api/productcategories"
            );

            const categories = response.data;

            commit('loadCategories', categories);
        },
        loadUsersList({ commit}){
            axios.get(`/api/user`)
            .then(response=> {
                commit("setUsersList",response.data);
                const filteredList=response.data.filter(user=>{
                    return user.archive==0;
                });
                commit("setFilteredUsersList",filteredList);
            });
        },
        verifyUserList({ commit,state, dispatch}) {

            const filteredList = state.usersList.filter(user => {
                return (
                    user.role.toLowerCase() == "customer" && user.archive == 0
                );
            });
            commit("setFilteredUsersList", filteredList);
        },
        archiveUserList({ commit,state}) {

            const filteredList = state.usersList.filter(user => {
                return user.archive == 1;
            });
            commit("setFilteredUsersList", filteredList);
        },
        async archiveVerifyUser({ commit, state, dispatch }, payload){
            await axios.post(`/api/user/archiveUser`, payload).then((response) => {
                state.message="The user has been archived.";
                state.success=true;
                state.error=false;
                var self=state;
                setTimeout(function(){
                    self.success=false;
                }, 2000);
                axios.get(`/api/user`).then(response=> {
                    commit("setUsersList",response.data);
                    const filteredList=response.data.filter(user=>{
                        return user.role.toLowerCase() == "customer" && user.archive == 0;
                    });
                    commit("setFilteredUsersList",filteredList);
                });

            });
        },
        async archiveUser({ commit, state, dispatch }, payload){
            await axios.post(`/api/user/archiveUser`, payload).then((response) => {
                state.message="The user has been archived.";
                state.success=true;
                state.error=false;
                var self=state;
                setTimeout(function(){
                    self.success=false;
                }, 2000);
                axios.get(`/api/user`).then(response=> {
                    commit("setUsersList",response.data);
                    const filteredList=response.data.filter(user=>{
                        return user.archive == 0;
                    });
                    commit("setFilteredUsersList",filteredList);
                });

            });
        },

        approveUser({ commit, state, dispatch }, payload){
            if(payload.role=='customer'){
                state.message="Please choose a different user role.";
                state.error=true;
                state.success=false;
                var self=state;
                setTimeout(function(){
                    self.error=false;
                }, 3000);

            }else{
                axios.post(`/api/user/approveUser`, payload).then((response) => {
                    state.message="The user has been approved";
                    state.success=true;
                    state.error=false;
                    var self=state;
                    setTimeout(function(){
                        self.success=false;
                    }, 3000);
                    axios.get(`/api/user`).then(response=> {
                        commit("setUsersList",response.data);
                        const filteredList=response.data.filter(user=>{
                            return user.role.toLowerCase() == "customer" && user.archive == 0;
                        });
                        commit("setFilteredUsersList",filteredList);
                    });

                });
            }

        },
        restoreUser({ commit, state, dispatch }, payload){
            if(payload.role=='customer'){
                state.message="Please choose a different user role.";
                state.error=true;
                state.success=false;
                var self=state;
                setTimeout(function(){
                    self.error=false;
                }, 3000);
            }else{
                axios.post(`/api/user/approveUser`, payload).then((response) => {
                    state.message="The user has been approved.";
                    state.success=true;
                    state.error=false;
                    var self=state;
                    setTimeout(function(){
                        self.success=false;
                    }, 3000);
                    axios.get(`/api/user`).then(response=> {
                        commit("setUsersList",response.data);
                        const filteredList=response.data.filter(user=>{
                            return user.archive==1;
                        });
                        commit("setFilteredUsersList",filteredList);
                    });

                });


            }

        },
        async loadUser({commit, dispatch}){
            if(isLoggedIn()){
                try {
                    const user= (await axios.get('/user')).data;
                    commit("setUserEmail", user.email);
                    commit("setUser", user);
                    commit("setLoggedIn", true);


                } catch (error) {
                    dispatch("logout");
                }
            }
        },
        logout({commit}){
            commit("setUser", {});
            commit("setLoggedIn", false);
            commit("setUserEmail", null);
            logOut();
        },



        loadIcons({commit,state}){
            axios.get(`/api/productIcons`).then((response)=>{
                commit('loadIcons',  response.data);
            })

        },


    },
};
