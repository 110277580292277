<template>
    <div>
        <select name="role" v-model="data.role">
            <option value="customer" selected>Customer</option>
            <option value="pip employee">PIP Employee</option>
            <option value="rep agency">Rep Agency</option>
            <option value="admin">Admin</option>
        </select>

    </div>
</template>
<script>
export default {
    name: "UserFormRole",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    methods:{
    }
};
</script>
