<template>

    <div v-if="product.ansi_impact"  class="product-icon-tooltip">
        <div  class="prod-icon icon-wide">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p><span>ANSI Impact Level:</span> <strong v-html="product.ansi_impact"></strong></p>
                <p>
                    ANSI/ISEA 138 is a new, voluntary standard for the North American market designed to accurately classify different levels of impact protection offered by the impact-resistant gloves on the market.
                </p>
            </div>
        </section>
    </div>


</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='ansi-impact-'+self.product.ansi_impact){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
