<template>
    <div>
        <div
            class="row no-gutters aboutus-header"
        >
            <h1
                class="ml-4 text-light pl-1"
                style="position: absolute; bottom: 2rem; left: 2rem;"
            >
                what makes us boss<sup>&reg;</sup>
            </h1>
        </div>

        <div class="p-5 about-text text-light" >
            <h4>EXPERIENCE:</h4>
            <p>
                Since 1893, Boss has been THE trusted brand in work gloves. As the oldest glove company in the United States, we've developed the infrastructure and relationships needed to make sure our customers have the tools and protection to get the job done. We use our 125+ years of experience to develop and create the best possible work gloves in the industry and will continue to do so for the next 125 years!
            </p>
            <h4>INNOVATION:</h4>
            <p>
                We understand that working environments continue to evolve and it is our priority to ensure our products continue to evolve as well. We consistently strive to innovate and seek unrivaled technologies, materials, and designs to help create the best safety gloves on the market while still providing the comfort and function you come to expect. With our innovative culture, be assured we are tirelessly working to bring you the best innovative gloves available.
            </p>
            <h4>QUALITY & VERSATILITY:</h4>
            <p>While we started off creating working gloves for the average farmer, our customers' needs have grown, and they demand higher quality and more features to take on any task at hand. This is why we have developed an extensive offering of premium work gloves that can be used for any project you plan to tackle. Whether you need gloves to use in your garage or backyard, or to work on a farm or construction site, we have the gloves that will keep you safe and performing at your best!</p>

        </div>

        <div class="aboutus-tagline p-3">
            <h2 class="text-center">OVER 125 YEARS OF EXCELLENCE</h2>
        </div>

        <div class="row no-gutters p-5 future-boss">
            <div class="col-lg-3">

                <h3 class="pt-2">OUR HERITAGE</h3>
                <p>
                    Our story began in 1890s Kewanee, Illinois, when a farmer, frustrated by the challenges of husking corn, combined a leather strap and a metal hook to create the Perkins Boss Husker. This simple invention led to the establishment of the Perkins Husker Company, which later became Boss Manufacturing in 1893. Boss built its reputation by responding to the needs of local farmers, craftspeople, and industrial workers by providing innovative, high-quality work gloves and gear.
                </p>
                <p>
                    In 2019, BOSS Glove and Safety joined the PIP® Global Family, leaders in PPE and hand protection.
                </p>
            </div>
            <div class="col-lg-1"></div>
            <div class="col-lg-8">
                <img src="/images/collage.jpg" alt="thumbnail">
            </div>
        </div>


    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {};
    },
    methods: {
        getLogo() {
            return "/images/pip-logo-white.svg";
        }
    }
};
</script>

<style lang="scss" scoped>
.aboutus-header{
    background-image:linear-gradient(to bottom, rgba(0,0,0, 0.32), rgba(0,0,0, 0.5)), url('/images/About-Us.jpg');  position: relative; background-position: center; background-size: cover; border-bottom: 2px solid white;
    height:70vh;
    @media screen and (max-width: 786px) {
         height:40vh;
         h1{
             font-size:2.5rem;
         }
    }
}
.aboutus-tagline{
    background:#cf152d;
        color:#ffffff;
    h2{
        font-size:3rem;

    }
}
h1 {
    font-size: 3.5rem;
}
.about-text {
    background: #111;
    h2 {
        font-size: 4rem;

        @media screen and (max-width: 500px) {
            font-size: 2.5rem;
        }
    }
    h4{
         font-family: "acumin-pro", Helvetica, Arial, sans-serif;
         font-weight:700;
    }
}
.future-boss{
    background:#e6e6e6;
    h3{
        font-family: "acumin-pro", Helvetica, Arial, sans-serif;
        font-weight:700;
    }
    img{
        max-width:100%;
    }
}
.brand-container {
    background-size: cover;
    background-position: center;
    &:hover {
        .overlay {
            opacity: 0;
        }
        .brand-content {
            background: rgba(0, 0, 0, 0.8);
        }
    }
}

.brand-content {
    z-index: 20;
    padding: 2rem;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: all 0.4s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .btn {
        max-width: 50%;
        margin: 0 auto;
    }
}
</style>
