<template>
    <div class="row">
        <div class="col-md-2 sidebar">
            <h1 class="font-weight-normal pb-3">User Dashboard</h1>
            <div class="lines d-sm-none d-none d-lg-block"></div>
        </div>
        <div class="d-flex flex-column   col-md-10">

            <div>
                <ul class="dashboard">
                    <li id="account">
                        <router-link :to="{ name: 'myAccount' }"><span><i class="fas fa-user"></i></span>My Account</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'changePassword' }" ><span><i class="fas fa-lock"></i></span> Change Password</router-link>
                    </li>
                    <li v-if="user.role=='admin'">
                        <router-link :to="{ name: 'userList' }" ><span><i class="fas fa-th-list"></i></span> User List</router-link>
                    </li>
                    <li  v-if="user.role=='admin'">
                        <router-link :to="{ name: 'myTools' }" ><span><i class="fas fa-tools"></i></span>My Tools</router-link>
                    </li>
                    <li>
                        <a
                            href="javascript:void(0);"
                            @click.prevent="logout"
                            ><span><i class="fas fa-sign-out-alt"></i></span>Log Out</a
                        >
                    </li>
                </ul>
            </div>
        </div>

    </div>

</template>
<style scoped lang="scss">
.dashboard{
    padding:0;
    margin:0;
    list-style: none;
    display:flex;
    flex-wrap:wrap;
    li{
        width:200px;
        text-align: center;
        a{
            display:inline-block;
            padding:1em;

            span{
                display:block;
                font-size:5rem;
            }
        }

    }
}
</style>
<script>
    import {mapState, mapGetters} from  "vuex";
    export default{
        computed:{
            ...mapState({user:state=>state.user}),

         },
    }
</script>
