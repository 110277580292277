<template>
    <div v-if="product" style="margin-top:0.8rem;">

        <ul style="font-size: 11px; line-height:14px; padding-left:18px;" v-if="scID == 2560 || scID==2569 || scID==2575">

            <li v-for="(feature,index) in features.slice(0, 5) " :key="index"  v-html="feature"></li>
            <li v-if="product.niosh_approval_num">NIOSH Approval Number: {{ product.niosh_approval_num }}</li>
            <li v-if="product.child_labeling == '1'"><i v-html="warningIcon[0].svg" class="warning-icon"></i>Meets the safety requirements of the US Consumer Product Safety Commission (CPSC)</li>
            <li v-if="product.latex_warning == '1'"> <i v-html="warningIcon[0].svg" class="warning-icon"></i>Caution: The Packaging of This Product Contains Natural Rubber Latex Which May Cause Allergic Reactions.</li>
            <li v-if="product.prop_65"><i v-html="prop65Icon[0].svg" class="warning-icon"></i><strong>WARNING:</strong> {{ product.prop_65 }} - www.P65Warnings.ca.gov</li>
        </ul>
        <ul style="font-size: 11px; line-height:14px; padding-left:18px;" v-else>

            <li v-for="(feature,index) in features " :key="index"  v-html="feature"></li>
            <li v-if="product.niosh_approval_num">NIOSH Approval Number: {{ product.niosh_approval_num }}</li>
            <li v-if="product.child_labeling == '1'"><i v-html="warningIcon[0].svg" class="warning-icon"></i>Meets the safety requirements of the US Consumer Product Safety Commission (CPSC)</li>
            <li v-if="product.latex_warning == '1'"> <i v-html="warningIcon[0].svg" class="warning-icon"></i>Caution: The Packaging of This Product Contains Natural Rubber Latex Which May Cause Allergic Reactions.</li>
            <li v-if="product.prop_65"><i v-html="prop65Icon[0].svg" class="warning-icon"></i><strong>WARNING:</strong> {{ product.prop_65 }} - www.P65Warnings.ca.gov</li>
        </ul>


    </div>
</template>
<style lang="scss" scoped>
    .warning-icon{
        width:15px;
        display:inline-block;
    }
</style>
<script>
import { mapState } from "vuex";

export default {
    props: {

        product:Object
    },
    data() {
        return {
            features:[],
            scID: null,
        }

    },
    computed: {
        ...mapState(["icons"]),
        warningIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='warning' ){
                    return icon;
                }
            });
        },
        prop65Icon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='prop-65' ){
                    return icon;
                }
            });
        }
    },
    created(){


        const unwatch=this.$watch('product',()=>{
            this.features=this.product.features.split('|').filter(function(feature){
                if(feature != ' '){
                    return feature;
                }
            });
            this.scID=this.product.category.select_code_id;

            unwatch();
        });

    },

}
</script>
