<template>
    <div class="">
        <div
            class="row no-gutters bg-secondary d-flex flex-column justify-content-end p-4"
            style="border-bottom: 2px solid white; min-height: 15vh;"
        >
            <h1 class="text-light ml-3 text-center text-md-left">
                Cookie Policy
            </h1>
        </div>
        <div class="row p-5">
            <div class="col-12 p-3">
                <h3>Cookie Declaration</h3>
                <p>
                    This website uses cookies. We use cookies to personalise
                    content and ads, to provide social media features and to
                    analyse our traffic. We also share information about your
                    use of our site with our social media and analytics partners
                    who may combine it with other information that you've
                    provided to them or that they've collected from your use of
                    their services. You consent to our cookies if you continue
                    to use our website.
                </p>
                <p>
                    Cookies are small text files that can be used by websites to
                    make a user's experience more efficient. The law states that
                    we can store cookies on your device if they are strictly
                    necessary for the operation of this site. For all other
                    types of cookies we need your permission. This site uses
                    different types of cookies. Some cookies are placed by third
                    party services that appear on our pages. You can at any time
                    change or withdraw your consent from the Cookie Declaration
                    on our website. Learn more about who we are, how you can
                    contact us and how we process personal data in our Privacy
                    Policy.
                </p>
                <p>
                    Cookie Declaration This website uses cookies. We use cookies
                    to personalise content and ads, to provide social media
                    features and to analyse our traffic. We also share
                    information about your use of our site with our social media
                    and analytics partners who may combine it with other
                    information that you've provided to them or that they've
                    collected from your use of their services. You consent to
                    our cookies if you continue to use our website. Cookies are
                    small text files that can be used by websites to make a
                    user's experience more efficient. The law states that we can
                    store cookies on your device if they are strictly necessary
                    for the operation of this site. For all other types of
                    cookies we need your permission. This site uses different
                    types of cookies. Some cookies are placed by third party
                    services that appear on our pages. You can at any time
                    change or withdraw your consent from the Cookie Declaration
                    on our website. Learn more about who we are, how you can
                    contact us and how we process personal data in our Privacy
                    Policy. Your consent applies to the following domains:
                    *.pipglobal.com
                </p>
                <h4>Category: Necessary (4)</h4>
                <p>
                    Necessary cookies help make a website usable by enabling
                    basic functions like page navigation and access to secure
                    areas of the website. The website cannot function properly
                    without these cookies.
                </p>
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th>Cookie</th>
                            <th>Provider</th>
                            <th>Purpose</th>
                            <th>Type</th>
                            <th>Expiry</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>__cfduid</td>
                            <td>lightwidget.com</td>
                            <td>
                                Used by the content network, Cloud flare, to
                                identify trusted web traffic.
                            </td>
                            <td>HTTP</td>
                            <td>1 Year</td>
                        </tr>
                        <tr>
                            <td>PHPSESSID</td>
                            <td>pipglobal.com</td>
                            <td>
                                Preserves user session state across page
                                requests.
                            </td>
                            <td>HTTP</td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>citrix_ns_id</td>
                            <td>pipglobal.com</td>
                            <td>Used to identify trusted web traffic.</td>
                            <td>HTTP</td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>citrix_ns_id_.pipglobal.com_%2F_wat</td>
                            <td>pipglobal.com</td>
                            <td>Used to identify trusted web traffic.</td>
                            <td>HTTP</td>
                            <td>Session</td>
                        </tr>
                    </tbody>
                </table>
                <h4>Category: Statistics (4)</h4>
                <p>
                    Statistic cookies help website owners to understand how
                    visitors interact with websites by collecting and reporting
                    information anonymously.
                </p>
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th>Cookie</th>
                            <th>Provider</th>
                            <th>Purpose</th>
                            <th>Type</th>
                            <th>Expiry</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>_ga</td>
                            <td>pipglobal.com</td>
                            <td>
                                Registers a unique ID that is used to generate
                                statistical data on how the visitor uses the
                                website.
                            </td>
                            <td>HTTP</td>
                            <td>2 Years</td>
                        </tr>
                        <tr>
                            <td>_gat</td>
                            <td>pipglobal.com</td>
                            <td>
                                Used by Google Analytics to throttle request
                                rate.
                            </td>
                            <td>HTTP</td>
                            <td>1 Day</td>
                        </tr>
                        <tr>
                            <td>_gid</td>
                            <td>pipglobal.com</td>
                            <td>
                                Registers a unique ID that is used to generate
                                statistical data on how the visitor uses the
                                website.
                            </td>
                            <td>HTTP</td>
                            <td>1 Day</td>
                        </tr>
                        <tr>
                            <td>p.gif</td>
                            <td>typekit.net</td>
                            <td>
                                Keeps track of special fonts used on the website
                                for internal analysis. The cookie does not
                                register any visitor data.
                            </td>
                            <td>Pixel</td>
                            <td>Session</td>
                        </tr>
                    </tbody>
                </table>
                <h4>Category: Marketing (18)</h4>
                <p>
                    Marketing cookies are used to track visitors across
                    websites. The intention is to display ads that are relevant
                    and engaging for the individual user and thereby more
                    valuable for publishers and third party advertisers.
                </p>
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th>Cookie</th>
                            <th>Provider</th>
                            <th>Purpose</th>
                            <th>Type</th>
                            <th>Expiry</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>_fbp</td>
                            <td>pipglobal.com</td>
                            <td>
                                Used by Facebook to deliver a series of
                                advertisement products such as real time bidding
                                from third party advertisers.
                            </td>
                            <td>HTTP</td>
                            <td>
                                1 Day
                            </td>
                        </tr>
                        <tr>
                            <td>ads/ga-audiences</td>
                            <td>google.com</td>
                            <td>
                                Used by Google Ad Words to re-engage visitors
                                that are likely to convert to customers based on
                                the visitor's online behaviour across websites.
                            </td>
                            <td>Pixel</td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>fr</td>
                            <td>facebook.com</td>
                            <td>
                                Used by Facebook to deliver a series of
                                advertisement products such as real time bidding
                                from third party advertisers.
                            </td>
                            <td>HTTP</td>
                            <td>90 Days</td>
                        </tr>
                        <tr>
                            <td>GPS</td>
                            <td>youtube.com</td>
                            <td>
                                Registers a unique ID on mobile devices to
                                enable tracking based on geographical GPS
                                location.
                            </td>
                            <td>HTTP</td>
                            <td>
                                1 Day
                            </td>
                        </tr>
                        <tr>
                            <td>IDE</td>
                            <td>doubleclick.net</td>
                            <td>
                                Used by Google DoubleClick to register and
                                report the website user's actions after viewing
                                or clicking one of the advertiser's ads with the
                                purpose of measuring the efficacy of an ad and
                                to present targeted ads to the user.
                            </td>
                            <td>HTTP</td>
                            <td>
                                1 Year
                            </td>
                        </tr>
                        <tr>
                            <td>PREF</td>
                            <td>youtube.com</td>
                            <td>
                                Registers a unique ID that is used by Google to
                                keep statistics of how the visitor uses YouTube
                                videos across different websites.
                            </td>
                            <td>HTTP</td>
                            <td>243 Days</td>
                        </tr>
                        <tr>
                            <td>test_cookie</td>
                            <td>doubleclick.net</td>
                            <td>
                                Used to check if the user's browser supports
                                cookies.
                            </td>
                            <td>HTTP</td>
                            <td>
                                1 Day
                            </td>
                        </tr>
                        <tr>
                            <td>tr</td>
                            <td>facebook.com</td>
                            <td>
                                Used by Facebook to deliver a series of
                                advertisement products such as real time bidding
                                from third party advertisers.
                            </td>
                            <td>Pixel</td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>VISITOR_INFO1_LIVE</td>
                            <td>youtube.com</td>
                            <td>
                                Tries to estimate the users' bandwidth on pages
                                with integrated YouTube videos.
                            </td>
                            <td>HTTP</td>
                            <td>180 Days</td>
                        </tr>
                        <tr>
                            <td>YSC</td>
                            <td>youtube.com</td>
                            <td>
                                Registers a unique ID to keep statistics of what
                                videos from YouTube the user has seen.
                            </td>
                            <td>HTTP</td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>yt.innertube::nextId</td>
                            <td>youtube.com</td>
                            <td>
                                Unclassified
                            </td>
                            <td>HTML</td>
                            <td>
                                Persistent
                            </td>
                        </tr>
                        <tr>
                            <td>yt.innertube::requests</td>
                            <td>youtube.com</td>
                            <td>
                                Unclassified
                            </td>
                            <td>HTML</td>
                            <td>
                                Persistent
                            </td>
                        </tr>
                        <tr>
                            <td>yt-remote-cast-installed</td>
                            <td>youtube.com</td>
                            <td>
                                Stores the user's video player preferences using
                                embedded YouTube video.
                            </td>
                            <td>HTML</td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>yt-remote-connected-devices</td>
                            <td>youtube.com</td>
                            <td>
                                Stores the user's video player preferences using
                                embedded YouTube video.
                            </td>
                            <td>HTML</td>
                            <td>
                                Persistent
                            </td>
                        </tr>
                        <tr>
                            <td>yt-remote-device-id</td>
                            <td>youtube.com</td>
                            <td>
                                Stores the user's video player preferences using
                                embedded YouTube video.
                            </td>
                            <td>HTML</td>
                            <td>
                                Persistent
                            </td>
                        </tr>
                        <tr>
                            <td>yt-remote-fast-check-period</td>
                            <td>youtube.com</td>
                            <td>
                                Stores the user's video player preferences using
                                embedded YouTube video.
                            </td>
                            <td>HTML</td>
                            <td>
                                Persistent
                            </td>
                        </tr>
                        <tr>
                            <td>yt-remote-session-app</td>
                            <td>youtube.com</td>
                            <td>
                                Stores the user's video player preferences using
                                embedded YouTube video.
                            </td>
                            <td>HTML</td>
                            <td>
                                Persistent
                            </td>
                        </tr>
                        <tr>
                            <td>yt-remote-session-name</td>
                            <td>youtube.com</td>
                            <td>
                                Stores the user's video player preferences using
                                embedded YouTube video.
                            </td>
                            <td>HTML</td>
                            <td>
                                Persistent
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
h4 {
    font-weight: 500;
    margin-top: 2rem;
}
</style>
