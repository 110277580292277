<template>
    <div style="display:flex; align-items:center; justify-content:center;" v-if="productdetail">
        <div style="position:relative; width:816px; height:1056px; background:#fff;">

            <specsheet-header :prodstyle="productdetail.prod_key"></specsheet-header>
                <div style="padding: 50px 70px;">
                    <div class="left" style="float: left; width:374px; padding-right:25px;">
                        <div v-if="prodImage.length!=0 ">
                            <specsheet-prodimage :prodimgsrc="prodImage[0].img" :prodimgfolder="prodImage[0].folder"></specsheet-prodimage>
                        </div>

                        <specsheet-description :description="productdetail.short_desc" :brand="productdetail.brand"></specsheet-description>
                        <specsheet-features :scID="scID" :features="prodFeatures" :prop65="productdetail.prop_65"></specsheet-features>
                        <specsheet-applications :applications="productdetail.apps" v-if="productdetail.apps"></specsheet-applications>

                    </div>
                    <div class="right" style="float: left; width:300px;" >
                        <section class="mt-3">
                            <product-icons :product="productdetail"></product-icons>
                        </section>
                        <specsheet-techdata :product="productdetail" v-if="productdetail"></specsheet-techdata>
                        <specsheet-performdata :product="productdetail" v-if="productdetail"></specsheet-performdata>
                        <specsheet-careinstruct :product="productdetail" v-if="productdetail"></specsheet-careinstruct>
                    </div>
                </div>
            <specsheet-footer></specsheet-footer>
        </div>
    </div>


</template>

<script>
import { mapState } from "vuex";

export default {
    data(){
        return {
            productdetail:{},
            prodFeatures:[],
            prodImage:[],
            scID: null,
        }
    },
    created(){
        axios.get(`/api/product/${this.$route.params.id}`).then(response => {
           this.productdetail=response.data[0];

           this.prodFeatures=this.productdetail.features.split('|').filter(function(feature){
                if(feature != ' '){
                    return feature;
                }
            });

            this.prodImage=this.productdetail.images;
            this.scID=this.productdetail.category.select_code_id;


        });
    }
};
</script>
