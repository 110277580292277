<template>
    <div style="display:flex; align-items:center; justify-content:center;" v-if="productdetail">
        <div style="position:relative; width:816px; height:1056px; background:#fff;">

            <specsheet-header :prodstyle="productdetail.prod_key"></specsheet-header>
            <div style="padding: 50px 70px;">
                <specsheet-upc :product="productdetail" v-if="productdetail"></specsheet-upc>
            </div>
            <specsheet-footer></specsheet-footer>
        </div>
    </div>


</template>

<script>
import { mapState } from "vuex";

export default {
    data(){
        return {
            productdetail:{},
        }
    },
    created(){
        axios.get(`/api/product/${this.$route.params.id}`).then(response => {
           this.productdetail=response.data[0];

           this.prodFeatures=this.productdetail.features.split('|').filter(function(feature){
                if(feature != ' '){
                    return feature;
                }
            });
        });
    }
};
</script>
