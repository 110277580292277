<template>
  <div class="container reset-password mt-5">
      <transition name="fade" v-if="message">
        <div class="alert alert-warning" role="alert" >
            {{this.response}}
            </div>
      </transition>

    <div class="row justify-content-center">
      <div class="col-sm-6">
        <div class="card card-default">
          <div class="card-header">Forgot Password</div>
          <div class="card-body">
            <form autocomplete="off" @submit.prevent="requestResetPassword" method="post">
              <div class="form-group">
                  <label for="email">E-mail</label>
                  <input type="email" id="email" class="form-control" placeholder="user@example.com" v-model="email" required>
              </div>
              <button type="submit" class="btn btn-primary">Send Password Reset Link</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
    .reset-password{
        height:60vh;

    }
</style>
<script>
export default {
    data() {
      return {
        email: null,
        has_error: false,
        message:false,
        response:null,
      }
    },
    methods: {
        requestResetPassword() {
            axios.post(`/api/reset-password`, {email: this.email}).then(result => {
                this.message=true;
                var self=this;
                setTimeout(function(){
                    self.message=false;
                }, 5000);
                this.response=result.data.message;
            }, error => {
                var self=this;
                setTimeout(function(){
                    self.message=false;
                }, 5000);
                this.response=error;
            });

        }
    }
}
</script>
