<template>
    <div class="p-0 pip-main-nav" >
        <div id="pipheader">
            <div class="pip-site-header">
                <div class="pip-top-bar d-none d-xl-block">
                    <!-- <a href="https://www.pipglobal.com/" target="blank">
                        <img
                            id="pip-global-img"
                            src="/images/pip-global-family_lockup.svg"
                            alt=""
                        />
                    </a> -->
                </div>
            </div>
            <div class="pip-bottom-bar"></div>
            <div class="logo-bg d-none d-xl-block"></div>
        </div>
        <b-navbar toggleable="xl" class="navbar-horizontal" id="main-nav" :class="{'fix-nav': isScroll}">
            <b-navbar-brand class="text-center"
                ><router-link :to="{ name: 'home' }" width="100">
                    <b-img
                        :src="`/images/boss-logo.svg`"
                        alt="logo"
                    ></b-img>
                    </router-link
            ></b-navbar-brand>
            <a href="#mobile-nav"
                ><i class="fas fa-bars d-lg-block d-xl-none"></i
            ></a>
            <mobile-nav></mobile-nav>
            <b-collapse is-nav id="nav-collapse">
                <b-navbar-nav>
                    <li class="nav-item dropdown" v-if="useProducts">
                        <a
                            class="nav-link dropdown-toggle"
                            href="/products"
                            id="navbarDropdownMenuLink"
                            @mouseover="hover = true"
                            aria-haspopup="true"
                        >
                            {{ $t("nav.products") }}
                        </a>
                        <ul :class="{showMenu:hover}"
                            @mouseleave="hover = false"
                            id="products-menu"
                            class="dropdown-menu"
                            aria-labelledby="navbarDropdownMenuLink"
                        >
                            <li
                                v-for="(category, index) in categories"
                                v-menuMatch="'ThisTextIsNotUsed'"
                                :key="'scID' + index"
                                :class="{
                                    'dropdown-submenu': category.commodity_code.length
                                }"
                            >
                                <router-link
                                    :to="{
                                        name: 'products',
                                        query: { scID: category.select_code.id }
                                    }"
                                    :class="{
                                        'dropdown-toggle': category.commodity_code.length
                                    }"
                                    class="dropdown-item"
                                    >{{ category.select_code.title }}
                                </router-link>
                                <ul
                                    class="dropdown-menu submenu" 
                                    v-if="category.commodity_code"
                                > <!-- :class="{'maxHeight':submenuHeight(category.commodity_code)}" -->
                                    <li class="submenu-header mx-0">
                                        {{ category.select_code.title }}
                                    </li>
                                    <li
                                        v-for="(subCategory, index) in category.commodity_code"
                                        :key="'ccID' + index"
                                    >
                                        <router-link
                                            :to="{
                                                name: 'products',
                                                query: {
                                                    scID: category.select_code.id,
                                                    ccID: subCategory.id
                                                }
                                            }" v-html="subCategory.title"></router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>

                    <b-nav-item to="/aboutus">{{
                        $t("nav.aboutus")
                    }}</b-nav-item>
                    <b-nav-item to="/literature">{{
                        $t("nav.literature")
                    }}</b-nav-item>
                    <b-nav-item to="/contact">{{
                        $t("nav.contact")
                    }}</b-nav-item>
                    <b-nav-item href="https://www.amazon.com/stores/BOSS/page/FD2AF217-EAE1-46B2-97C3-DDBDDE4E51B4?ref_=ast_bln" target="_blank" aria-label="Open new tab to Boss page on Amazon.com">BUY NOW</b-nav-item>
                </b-navbar-nav>
                <div
                    class="ml-auto d-flex justify-content-center"
                    id="right-side-menu"
                >
                    <local-switcher v-if="useLocale"></local-switcher>
                    <div
                        class="pip-login-bar d-flex flex-column justify-content-center dropdown"
                        v-if="useAdmin"
                    >
                        <div class=" dropdown-toggle" data-toggle="dropdown">
                            <div v-if="isLoggedIn" class="px-3">
                                <div class="welcome">
                                    <small class="d-block">Welcome</small>
                                    <span
                                        >{{ user.name }}
                                        <i class="pl-2 fas fa-chevron-down"></i
                                    ></span>
                                </div>
                            </div>
                            <div v-else class="px-5">
                                <div class="login ">
                                    Distributor Log in
                                </div>
                            </div>
                        </div>

                        <div class="dropdown-menu ">
                            <div v-if="isLoggedIn">
                                <ul class="dashboard-menu py-2">
                                    <li id="account">
                                        <router-link :to="{ name: 'dashboard' }"
                                            >Dashboard</router-link
                                        >
                                    </li>
                                    <li id="account">
                                        <router-link :to="{ name: 'myAccount' }"
                                            >My Account</router-link
                                        >
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'changePassword' }"
                                            >Change Password</router-link
                                        >
                                    </li>
                                    <li v-if="user.role == 'admin'">
                                        <router-link :to="{ name: 'userList' }"
                                            >Users List</router-link
                                        >
                                    </li>
                                    <li v-if="user.approve == 1">
                                        <router-link :to="{ name: 'myTools' }"
                                            >My Tools</router-link
                                        >
                                    </li>
                                    <li>
                                        <a
                                            href="javascript:void(0);"
                                            @click.prevent="logout"
                                            >Log Out</a
                                        >
                                    </li>
                                </ul>
                            </div>
                            <div v-else class="login-block">
                                <div class="text-center mb-3">
                                    <img
                                        src="/images/west-chester-logo.svg"
                                        alt="logo"
                                        class="d-inline-block mb-2"
                                    />
                                    <span class="d-block text-center"
                                        >DISTRIBUTOR TOOLS</span
                                    >
                                </div>
                                <div>
                                    <small class="d-block"
                                        >Don't have an account?</small
                                    >
                                    <small class="mb-4 d-inline-block"
                                        ><router-link
                                            :to="{ name: 'login' }"
                                            class="text-light"
                                            >Click here to Sign Up</router-link
                                        ></small
                                    >
                                </div>
                                <form>
                                    <div class="form-group">
                                        <label for="email"
                                            >Email Address:</label
                                        >
                                        <input
                                            type="text"
                                            name="email"
                                            class="form-control"
                                            v-model="email"
                                        />
                                    </div>
                                    <div class="form-group mb-1">
                                        <label for="password">Password:</label>
                                        <input
                                            type="password"
                                            name="password"
                                            class="form-control"
                                            v-model="password"
                                        />
                                    </div>
                                    <small class="d-block text-right"
                                        ><router-link
                                            :to="{ name: 'reset-password' }"
                                            class="text-light"
                                            >Did you forget your
                                            password?</router-link
                                        ></small
                                    >

                                    <button
                                        class="btn btn-primary mt-4 border-white"
                                        type="submit"
                                        :disabled="loading"
                                        @click.prevent="dashboardLogin"
                                    >
                                        Login
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div
                        class="d-flex flex-wrap justify-content-center"
                        id="cart-container"
                        v-if="useCart"
                    >
                        <router-link
                            class="d-flex flex-column text-light position-relative align-items-center"
                            :to="{ name: 'order.checkout' }"
                        >
                            <i class="fas fa-shopping-cart 2x"></i>
                            <span style="font-size: .7rem;">
                                CART<span
                                    class="inline-block "
                                    id="cart-quantity"
                                    v-text="
                                        ' (' + $store.state.cart.length + ')'
                                    "
                                ></span>
                            </span>
                        </router-link>
                    </div>

                    <div
                        class="search text-light "
                        data-toggle="modal"
                        data-target="#searchPanel"

                    >
                        <i class="fas fa-search mr-2" role="presentation"></i>SEARCH PRODUCTS
                    </div>
                </div>
            </b-collapse>
            <i
                id="mobile-search"
                data-toggle="modal"
                data-target="#searchPanel"
                class="fas fa-search d-lg-block d-xl-none"

            ></i>
        </b-navbar>
        <search></search>
    </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import validationErrors from "../mixins/validationErrors";
import { logIn, logOut } from "../utils/auth";
export default {
    mixins: [validationErrors],
    data() {
        return {
            email: null,
            password: null,
            loading: false,
            success: false,
            isScroll: false,
            hover:false,
            categories: ""
        };
    },
    computed: {
        ...mapState([
            "isLoggedIn",
            "userEmail",
            "user",
            "useCart",
            "useAdmin",
            "useLocale",
            "useProducts"
        ]),


    },
    created() {
        if (this.isLoggedIn == true) {
            axios.get(`/api/getUser/${this.userEmail}`).then(response => {
                this.$store.state.user = response.data;
            });
        };
        window.addEventListener('scroll', this.handleScroll);

        axios.get("/api/productcategories").
            then(response => {
                this.categories = response.data;
            }
        );
        
        // const unwatch=this.$watch('categories',()=>{
        //     //this.initializeVariable();
   
        //     unwatch();
        // });
    },
    methods: {
        handleScroll (event) {
            if(window.scrollY<=100){
                return this.isScroll=false;
            }else{
                 return this.isScroll=true;
            }
        },
        submenuHeight(subcategory){
            var maxHeight=false;
            if(this.categories.length<subcategory.length){
                maxHeight=false;
            }else{
                maxHeight=true;
            }
            return maxHeight;
        },

        dashboardLogin() {
            axios
                .get(`/api/getUser/${this.email}`)
                .then(response => {
                    if (
                        response.data.email_verified_at == null &&
                        response.data.archive == 1
                    ) {
                        $(".show").removeClass("show");
                        this.$router.push({
                            name: "login",
                            query: { errorLogin: "true" }
                        });
                    } else {
                        this.login();
                    }
                })
                .catch(error => {
                    $(".show").removeClass("show");
                    this.$router.push({
                        name: "login",
                        query: { errorLogin: "true" }
                    });
                });
        },
        async login() {
            try {
                await axios.get(`/sanctum/csrf-cookie`);
                await axios
                    .post("/login", {
                        email: this.email,
                        password: this.password
                    })
                    .then(response => {
                        axios
                            .get(`/api/getUser/${this.email}`)
                            .then(response => {
                                logIn(
                                    response.data.email,
                                    response.data.approve,
                                    response.data.role
                                );
                                this.$store.dispatch("loadUser");
                                $(".show").removeClass("show");
                                this.$router.push({ name: "dashboard" });
                            });
                    });
            } catch (error) {
                $(".show").removeClass("show");
                this.$router.push({
                    name: "login",
                    query: { errorLogin: "true" }
                });
            }
        },
        async logout() {
            try {
                axios.post("/logout");
                this.$store.dispatch("logout");
                this.$router.push({ name: "home" });
            } catch (error) {
                this.$store.dispatch("logout");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.showMenu{
    display:block;
}

.logo-bg { background: #000;}
.logo-bg::after { background:#000; }

#main-nav{
    background:rgba(0,0,0,0.5) !important;
    @media screen and (max-width: 1200px) {  background:#000 !important; }

}
.fix-nav{
    background:rgba(0,0,0,0.5) !important;
    @media screen and (max-width: 1200px) {  background:#000 !important; }
}


#main-nav .navbar-nav .nav-item .nav-link{
    color:#ffffff;
}
//display submenu on hover
.navbar-nav li:hover > ul.dropdown-menu.submenu {
    display: block;
}

ul#products-menu {
    width: 225px;
    border-radius: none;
    top: 123%;
}

.dropdown-menu.submenu {
    //min-height: 130px;
    & > li {
        padding: 0.26em 0.8rem;
        &:first-child {
            &:hover {
                background: transparent;
            }
        }
    }
}
.dropdown-submenu{
    //height:200px;
    height: auto;
}
.dropdown-submenu > .dropdown-menu {
    top: -3px;
    left: 100%;
    border-radius: 0;
    width: 225px;
}

.submenu-header {
    margin: 0;
    font-weight: 700;
    font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    padding: 0.25rem 0.7rem;
    &:before {
        content: "///";
        color: lightgray;
        display: inline-block;
        font-weight: bold;
    }
}

.dropdown-menu {
    padding: 0;
    border-radius: 0;
    left: -0.5px;
    .dropdown-item {
        &::after {
            position: absolute;
            top: 17px;
            right: -4px;
        }
    }
    li {
        transition: all 0.4s ease-out;
        border-bottom: 1px dotted #333;
        padding: 0 0.5rem;
        &:last-child {
            border-bottom: none;
        }
        a {
            &:hover {
                //rotate caret on hover
                &:after {
                    text-decoration: underline;
                    transform: rotate(-90deg);
                }
            }
            &:after {
                color: lightgray;
            }

            &::before {
                content: "///";
                color: lightgray;
                margin-right: 0.2em;
                display: inline-block;
                font-weight: bold;
            }
        }
    }
    .submenu {
        li {
            a {
                color: #333;
                font-size: 1rem;
                font-family: "acumin-pro-condensed", Helvetica, Arial,
                    sans-serif;
                padding: 0;
                margin: 0;
                &:before {
                    content: "";
                }
            }
        }
    }
}

.dropdown-item {
    font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: unset;
    color: #333;
    padding: 0.25rem;
    position: relative;
    &:hover,
    &:focus {
        background: transparent;
    }
}

#right-side-menu {
    position: absolute;
    right: 0;
    top: 25px;
    height: 50px;
}

.search {

    font-size: 0.8rem;
    padding: 0.9rem 2.2rem;
    width: 300px;
    text-align: center;
    &:hover {
        cursor: pointer;
    }
}

i.fas.fa-bars {
    transform: translateY(-36px);
    color: white;
    position: absolute;
    top: 4rem;
    left: 2rem;
    z-index: 9;
    font-size: 1.5rem;
}

//Search Panel Styles
.fa-search {
    color: white;
}

i#mobile-search {
    position: absolute;
    right: 2rem;
    top: 1.9rem;
    font-size: 1.4rem;
}

.local-switcher {
    background: #000;
    padding: 0.59rem 0.5rem;
    border: none;
}
svg#cart {
    width: 10px;
    height: 10px;
    margin: 0.2rem;
}

#cart-container {
    padding: 0.25rem 0.8rem;
    align-items: center;
    background: #333;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}
span#cart-quantity {
    color: whitesmoke;
    // transform: translate(8px, -2px);
    font-size: 0.7rem;
}
i.fas.fa-shopping-cart {
    color: whitesmoke;
    font-size: 1rem;
}
#pip-global-img {
    font-size: 1rem;
    svg {
        color: white;
        margin: 0;
    }
}
</style>
