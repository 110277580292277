<template>
    <div>
        <div v-if="loading" >
            <div class="vh-100 d-flex justify-content-center align-items-center">
                <vue-loaders-ball-beat scale="2" color="black" />
            </div>

        </div>
        <div v-else>

            <div class="row no-gutters home-slider">
                <div class="w-100">
                    <VueSlickCarousel v-bind="settings">
                        <div id="slide1" class="w-100 position-relative slide">
                            <div class="content-container position-absolute">
                                <div class="text-container">
                                    <h1>Made for Working</h1>
                                    <p>
                                        Crafted from the most advanced materials and
                                        engineered to provide maximum protection and
                                        productivity for workers in tough environments. Boss® high-performance gloves
                                        deliver heavy-duty protection and offer the
                                        best fit, grip and function.
                                    </p>
                                </div>
                            </div>

                            <img
                                src="/images/headers/slider1-bg.jpg"
                                alt="slider-1"
                            />
                        </div>
                        <!-- <div id="slide2" class="w-100 position-relative slide">
                            <div class="content-container position-absolute">
                                <div class="text-container">
                                    <h1>STAND OUT.</h1>
                                    <p>WORK HARD. WEAR BOSS.®</p>
                                </div>
                            </div>

                            <img src="/images/stand-out-bg.jpg" alt="" />
                        </div>
                        <div id="slide3" class="w-100 position-relative slide">
                            <div class="content-container position-absolute">
                                <div class="text-container">
                                    <h1>GET A GRIP.</h1>
                                    <p>WORK HARD. WEAR BOSS.®</p>
                                </div>
                            </div>

                            <img src="/images/get-a-grip-bg.jpg" alt="" />
                        </div>
                        <div id="slide4" class="w-100 position-relative slide">
                            <div class="content-container position-absolute">
                                <div class="text-container">
                                    <h1>STAY DRY.</h1>
                                    <p>WORK HARD. WEAR BOSS.®</p>
                                </div>
                            </div>

                            <img src="/images/stay-dry-bg.jpg" alt="" />
                        </div> -->
                    </VueSlickCarousel>
                </div>
            </div>

            <div class="tag-line">
                <h2 class="text-center">WORK HARD. WEAR BOSS.<sup>&reg;</sup></h2>
            </div>

            <div class="w-100" >
                <div class="row no-gutters">
                    <div
                        class="industry col-lg-6 "
                        id="work-gloves"
                        v-bind:style="{
                            backgroundImage:
                                'url(/images/headers/workglove-tile.jpg)',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center left'
                        }"
                    >
                        <div class="industry-text">
                            <h3 class="text-center">WORK GLOVES</h3>

                            <router-link
                                :to="{
                                    name: 'products',
                                    query: { scID: '2560' }
                                }"
                                class="btn text-uppercase mx-auto"
                                >SHOP WORK GLOVES</router-link
                            >
                        </div>
                    </div>

                    <div
                        class="industry col-lg-6 "
                        id="boots"
                        v-bind:style="{
                            backgroundImage:
                                'linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(/images/headers/boots-tile.jpg)',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'bottom right'
                        }"
                    >
                        <div class="industry-text">
                            <h3 class="text-center">RAIN GEAR</h3>
                            <router-link
                                :to="{
                                    name: 'products',
                                    query: { 
                                        scID: '2563',
                                        ccID: '11558'
                                    }
                                }"
                                class="btn text-uppercase mx-auto"
                                tag="button"
                                >SHOP RAIN GEAR</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
    data: function() {
        return {
            loading:true,
            settings: {
                dots: true,
                dotsClass: "slick-dots custom-dot-class",
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                autoplay: true,
                controls: true,
                slidesToShow: 1,
                slidesToScroll: 1,

            }
        };
    },
    components: { VueSlickCarousel },
    mounted(){
        this.loading=false;
    },

    methods: {
        // getLogo() {
        //     return "/images/pip-logo-white.svg";
        // }
    }
};
</script>

<style lang="scss" scoped>
// .home-slider{
//     @media screen and (max-width: 1200px) {
//         margin-top:75px;
//     }
// }
main .industry .industry-text{
    background:rgba(0,0,0,0.5);
    h3{
        text-shadow:1px 2px 5px rgb(0 0 0 / 80%);
        transition:transform .35s;
    }
    .btn{
        opacity: 0;
        margin:20px 0 0 0;
        transition: opacity .2s,transform .35s;
        background:#cf152d;
        color:#ffffff;
    }
}

main .industry:hover .industry-text{
    background:none;

}

main .industry:hover .industry-text {
    h3{
        transform:translate3d(0,-30px,0);
    }
    .btn{
        margin:0;
        height: auto;
        opacity: 1;
        transform:translate3d(0,-30px,0);
        transition-delay: .05s;
        transition-duration: .35s;
    }
}
.tag-line{
    padding:1.4rem 0 1rem 0;
    h2{
        font-family: "Oswald", sans-serif;
    }
}
.slick-slider {
    max-width: 100%;
    max-height: 80vh;
    .slide {
        display: block !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media screen and (max-width: 1300px) {
        height: 75vh;

        }
    }

    img {
        width: 100vw;
        max-width: 100%;
        @media screen and (max-width: 1300px) {
            height: 75vh;
            width:100%;

            object-fit: cover;
            object-position: right;
        }
    }
    button.slick-arrow.slick-prev,
    button.slick-arrow.slick-next {
        z-index: 100 !important;
        &:before {
            font-size: 3rem !important;
        }
    }
    button.slick-arrow.slick-prev {
        left: 2rem !important;
        &:before {
            content: "\f104";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }
    }
    button.slick-arrow.slick-next {
        right: 2rem !important;
        &:before {
            content: "\f105";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }
    }
    .industry {
        background-color: #333;
    }
    .content-container {
        display: flex;
        align-items: center;
        height: 80vh;
        max-width: 33%;
        color: white;
        padding: 0 3rem ;
        @media screen and (max-width: 1480px) {
            max-width: 43%;
        }
        @media screen and (max-width: 1024px) {
        height: 75vh;
        }
        @media screen and (max-width: 1137px) {
            max-width: 60%;

        }
        @media screen and (max-width: 814px) {
            max-width: 80%;
            padding: 0 1rem ;
        }

        .text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2rem;
            @media screen and (max-width: 965px) {
                padding: 1rem;
            }
            h1 {
                // font-family: "Oswald", sans-serif;
                font-size: 5rem;
                color: white;
                @media screen and (max-width: 1400px) {
                    font-size: 4rem;
                }
                @media screen and (max-width: 1024px) {
                    font-size: 3.5rem;
                }
                @media screen and (max-width: 500px) {
                    font-size: 3rem;
                }
            }
            p {
                font-size: 1rem;
                font-weight: 100;
                margin-top:1rem;
                @media screen and (max-width: 965px) {
                    font-size: 0.9rem;
                }
                // @media screen and (max-width: 999px) {
                //     display: none;
                // }
            }
        }
    }
}

</style>
