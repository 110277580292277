<template>
    <div>
        <p style="margin:0.8rem 0;"><span style="font-size:24px; line-height:28px; font-weight:500;">Applications</span></p>
        <ul style="font-size: 11px; line-height:14px; padding-left:18px; ">
            <li v-for="(application,index) in applicationList " :key="index">{{ application }}</li>
        </ul>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        applications:String
    },
    data() {
        return {
            applicationList:[]
        }

    },
    computed: {

    },
    created(){
        this.applicationList=this.applications.split('--').filter(function(app){
                if(app != ' '){
                    return app;
                }
        });
    },

}
</script>
