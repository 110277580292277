<template>
    <div
        class="modal fade"
        id="searchPanel"
        tabindex="-1"
        role="dialog"
        aria-label="Search Panel"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header bg-dark" style="border-radius: 0;">
                    <div class="input-group">
                        <input
                            type="text"
                            v-model="searchValue"
                            class="form-control"
                            placeholder="Search Products"
                            id="search-input"
                            aria-label="Search Input"
                        />
                    </div>

                    <button
                        type="button"
                        class="btn btn-light close text-white"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex flex-column w-100" v-if="showList">
                        <div v-if="filteredProducts.data">
                            <div
                                v-for="(product, index) in filteredProducts.data.slice(
                                    0,
                                    6
                                )"
                                :key="'product' + index"
                                class="d-flex mb-2"
                            >
                                <router-link
                                    :to="{
                                        name: 'product',
                                        params: { id: product.prod_id }
                                    }"
                                    @click.native="closeSearchPanel"
                                    class="d-flex product-item"
                                >
                                    <section v-if="product.images">
                                        <div v-if="product.images[0].folder">
                                            <b-img
                                                :src="`/images/pim/1200/${product.images[0].folder}/${product.images[0].img}.jpg`"
                                                class="product-image"
                                            ></b-img>
                                        </div>
                                        <div v-else>
                                            <b-img
                                                :src="`/images/pim/600/${product.images[0].img}.jpg`"
                                                class="product-image"
                                            ></b-img>
                                        </div>
                                    </section>
                                    <section v-else>
                                        <img src="/images/products/img-coming-soon.png" alt="image">
                                    </section>

                                    <div class=" p-2">
                                        <p class="text-secondary font-weight-bold">
                                            {{ product.prod_key}}
                                        </p>
                                        <p
                                            class="desc"
                                            v-html=" product.short_desc"
                                        ></p>
                                    </div>
                                </router-link>
                            </div>
                            <div
                                class="info d-flex flex-wrap bg-secondary text-light justify-content-around"
                                v-if="filteredProducts.data.length"
                            >
                                <div class="p-3">
                                    SHOWING {{ displayResultsCount }} OF
                                    {{ filteredProducts.total }}
                                </div>
                                <div class="py-2">
                                    <b-button
                                        class="mt-1 btn btn-outline-light btn-sm"
                                        data-dismiss="modal"
                                        v-if="filteredProducts.total > 6"
                                        :to="{
                                            name: 'products',
                                            query: {
                                                q:'search',
                                                v: searchValue }
                                        }"
                                        @click="closeSearchPanel"
                                    >
                                        ALL RESULTS
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <p><strong>NO RECORDS FOUND</strong>   </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { assertExpressionStatement } from "babel-types";
// import { mapState } from "vuex";
export default {
    data() {
        return {
            searchValue: null,
            filteredProducts:{},
            errors:null,
            showList:false,
        };
    },
    computed: {
        displayResultsCount() {
            return this.filteredProducts.total > 5 ? 6 : this.filteredProducts.total;
        }
    },
    methods: {

        closeSearchPanel() {
            this.searchValue = "";
            $('.modal-backdrop').remove();
            $(".modal-backdrop").removeClass("show");
            $(".modal-backdrop").css("display","none");
            $("#searchPanel").modal("hide");
            $("body").css("padding-right","0");
            $("body").removeClass("modal-open");
        },

    },

    watch:{
        // on change search input value
        async searchValue(newInput){
            this.showList=true;
            if(newInput===""){
                this.filteredProducts={};
            }else{
                try {
                    await axios.get(`/api/searchProduct/${newInput}/`).then(response => {
                        this.filteredProducts=response.data;

                        if(this.filteredProducts.total==0){
                            this.filteredProducts={};
                        }

                    });

                } catch (error) {
                    this.errors = error.response && error.response.data.errors;
                }
            }



        }
    }
};
</script>

<style lang="scss" scoped>
.modal.fade .modal-dialog {
    position: fixed;
    margin: auto;
    width: 350px;
    max-width: 80%;
    height: 100%;
    right: -350px;
    transition: opacity 0.4s linear, right 0.4s ease-out;
}

.modal.fade.show .modal-dialog {
    right: 0;
}

.modal .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal .modal-body {
    padding: 15px 15px 80px;
    position: relative;
}

/* ----- MODAL STYLE ----- */
.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
}
img {
    max-width: 100%;
    object-fit: contain;
}
p {
    margin: 0;
}

#search-input {
    position: relative;
    border-radius: 0;
}

#searchClear {
    height: 14px;
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
    margin: auto;
    font-size: 1rem;
    cursor: pointer;
    color: #ccc;
}
.info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    font-size: 0.8rem;
}
.btn.close {
    span {
        font-size: 1.4rem;
    }

    &:hover {
        background: transparent;
    }
}
.product-item{
    section{
        width:35%;
    }
    >div{
        width:75%;
    }
}
.desc {
    color: #333;
    line-height: 1;
    font-size:0.9rem;
}
</style>
