var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('div',{staticClass:"vh-100 d-flex justify-content-center align-items-center"},[_c('vue-loaders-ball-beat',{attrs:{"scale":"2","color":"black"}})],1)]):_c('div',[_c('div',{staticClass:"row no-gutters home-slider"},[_c('div',{staticClass:"w-100"},[_c('VueSlickCarousel',_vm._b({},'VueSlickCarousel',_vm.settings,false),[_c('div',{staticClass:"w-100 position-relative slide",attrs:{"id":"slide1"}},[_c('div',{staticClass:"content-container position-absolute"},[_c('div',{staticClass:"text-container"},[_c('h1',[_vm._v("Made for Working")]),_vm._v(" "),_c('p',[_vm._v("\n                                    Crafted from the most advanced materials and\n                                    engineered to provide maximum protection and\n                                    productivity for workers in tough environments. Boss® high-performance gloves\n                                    deliver heavy-duty protection and offer the\n                                    best fit, grip and function.\n                                ")])])]),_vm._v(" "),_c('img',{attrs:{"src":"/images/headers/slider1-bg.jpg","alt":"slider-1"}})])])],1)]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"industry col-lg-6",style:({
                        backgroundImage:
                            'url(/images/headers/workglove-tile.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center left'
                    }),attrs:{"id":"work-gloves"}},[_c('div',{staticClass:"industry-text"},[_c('h3',{staticClass:"text-center"},[_vm._v("WORK GLOVES")]),_vm._v(" "),_c('router-link',{staticClass:"btn text-uppercase mx-auto",attrs:{"to":{
                                name: 'products',
                                query: { scID: '2560' }
                            }}},[_vm._v("SHOP WORK GLOVES")])],1)]),_vm._v(" "),_c('div',{staticClass:"industry col-lg-6",style:({
                        backgroundImage:
                            'linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(/images/headers/boots-tile.jpg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'bottom right'
                    }),attrs:{"id":"boots"}},[_c('div',{staticClass:"industry-text"},[_c('h3',{staticClass:"text-center"},[_vm._v("RAIN GEAR")]),_vm._v(" "),_c('router-link',{staticClass:"btn text-uppercase mx-auto",attrs:{"to":{
                                name: 'products',
                                query: { 
                                    scID: '2563',
                                    ccID: '11558'
                                }
                            },"tag":"button"}},[_vm._v("SHOP RAIN GEAR")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-line"},[_c('h2',{staticClass:"text-center"},[_vm._v("WORK HARD. WEAR BOSS."),_c('sup',[_vm._v("®")])])])
}]

export { render, staticRenderFns }