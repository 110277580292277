<template>
    <div class="local-switcher">
        <div id="locale-menu">
            <img
                id="selected-locale-flag"
                src="/images/flags/en.gif"
                width="30"
                alt=""
                @click="toggleLocaleMenu()"
            />
        </div>
        <ul id="locale-dropdown" class="hide" @mouseleave="hideLocaleMenu()">
            <li
                v-for="locale in locales"
                :key="locale.code"
                @click="selectLocale(locale.code)"
            >
                <img
                    class="locale-flag"
                    :src="`/images/flags/${locale.code}.gif`"
                    alt=""
                />{{ locale.name }}
            </li>
        </ul>
    </div>
</template>

<script>
import { getSupportedLocales } from "@c/utils/i18n/supported-locales";
import { setDocumentTitle, setDocumentLang } from "@c/utils/i18n/document";

export default {
    data: () => ({ locales: getSupportedLocales() }),
    methods: {
        toggleLocaleMenu() {
            if ($("#locale-dropdown").hasClass("hide"))
                $("#locale-dropdown").removeClass("hide");
            else $("#locale-dropdown").addClass("hide");
        },
        hideLocaleMenu() {
            $("#locale-dropdown").addClass("hide");
        },
        selectLocale(locale) {
            this.$i18n.locale = locale;
            var src = `/images/flags/${locale}.gif`;
            $("#locale-dropdown").addClass("hide");
            $("#selected-locale-flag").attr("src", src);
        }
    },
    mounted() {
        this.$watch(
            "$i18n.locale",
            (newLocale, oldLocale) => {
                if (newLocale === oldLocale) {
                    return;
                }
                setDocumentLang(newLocale);
                setDocumentTitle(this.$t("app.title"));
            },
            { immediate: true }
        );
    }
};
</script>

<style lang="scss" scoped>
.locale-flag {
    max-width: 30px;
    margin-right: 1.5rem;
}

#locale-menu {
    &:hover {
        cursor: pointer;
    }
}

#locale-dropdown {
    z-index: 1000;
    position: absolute;
    top: 3.8em;
    width: 225px;
    font-size: 0.75rem;
    list-style: none;
    background: #111;
    color: whitesmoke;
    left: 0;
    font-family: "Acumin Pro Condensed", Helvetica, Arial, sans-serif;
    li {
        padding: 0.4rem 1.2rem;
        margin-bottom: 0.3rem;
        margin-left: 0.5rem;
        border-bottom: 1px solid #333;
        transition: all 0.4s ease-out;
        &:first-child {
            padding-top: 1rem;
        }
        &:last-child {
            border: none;
        }
        &:hover {
            background: #222;
            cursor: pointer;
        }
    }
}
.hide {
    display: none;
}
</style>
