<template>
  <div class="container reset-password mt-5">
    <div class="row justify-content-center">
      <div class="col-sm-6">
          <fatal-error v-if="error">
              Passwords do not match.
          </fatal-error>
        <div class="card card-default">
          <div class="card-header">New Password</div>
          <div class="card-body">
            <form autocomplete="off" @submit.prevent="resetPassword" method="post">
              <div class="form-group">
                  <label for="email">*E-mail</label>
                  <input type="email" id="email" class="form-control" placeholder="user@example.com" v-model="email" disabled>
              </div>
              <div class="form-group">
                  <label for="email">*Password: <span>(MINIMUM OF 6 CHARACTERS)</span></label>
                  <input type="password" id="password" class="form-control" placeholder="" v-model="password" required>
              </div>
              <div class="form-group">
                  <label for="email">*Confirm Password:</label>
                  <input type="password" id="password_confirmation" class="form-control" placeholder="" v-model="password_confirmation" required>
              </div>
              <button type="submit" class="btn btn-primary">Update</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
    .reset-password{
        height:70vh;
    }
    label{
        margin-bottom: 0;
        font-weight: 600;
        span{
            font-size: 0.75rem;
            color: #666666;
            font-weight:400;
            margin: 1rem 0;
        }
    }
</style>
<script>
import { logIn} from "../components/utils/auth";
export default {
    data() {
      return {
        token: null,
        email: null,
        password: null,
        password_confirmation: null,
        error: false,
      }
    },
    created(){
        this.email=this.$route.query.email;
    },
    methods: {
        async resetPassword() {
            axios.post("/api/reset/password", {
                token: this.$route.params.token,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation
            })
            .then(result => {
                this.login()

            }, error => {
                this.error=true;
                var self=this;
                setTimeout(function(){
                    self.error=false;
                }, 4000);

            });
        },
        async login(){
                try {
                    await axios.get(`/sanctum/csrf-cookie`);
                    await axios.post("/login", {
                        email: this.email,
                        password: this.password
                    }).then(response=>{
                        axios.get(`/api/getUser/${this.email}`)
                        .then(response=> {
                            logIn(response.data.email,response.data.approve,response.data.role);
                            this.$store.dispatch("loadUser");
                            $(".show").removeClass("show");
                            this.$router.push({ name: "dashboard" });
                        });

                    });

                } catch (error) {
                    this.error=true;
                    var self=this;
                    setTimeout(function(){
                        self.error=false;
                    }, 4000);

                }
            },
    }
}
</script>
