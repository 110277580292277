<template>

    <div v-if="product.en388"  class="product-icon-tooltip">
        <div  class="prod-icon icon-tall d-flex flex-column align-items-center">
            <i v-html="getIcon[0].svg"></i>
            <small class="icon-text-small" ><span v-html="product.en388.trim()"></span><span v-if="product.en388_N != 'X'">{{ product.en388_N }}</span></small>
        </div>
        <section class="icon-tooltip">
            <div>
                <p>
                    <span>EN 388 2016 Results:</span>
                    <strong v-html="product.en388_2016"></strong>
                </p>
                <p>
                    <span>Abrasion:</span>
                    <strong v-html="product.en388_A"></strong>
                </p>
                <p>
                    <span>Cut (Coupe Test):</span>
                    <strong v-html="product.en388_C"></strong>
                </p>
                <p>
                    <span>Tear:</span>
                    <strong v-html="product.en388_T"></strong>
                </p>
                <p>
                    <span>Puncture:</span>
                    <strong v-html="product.en388_P"></strong>
                </p>
                <p>
                    <span>Cut (TDM-100 Test):</span>
                    <strong v-html="product.en388_N"></strong>
                </p>
                <p>
                    <span>Impact Protection:</span>
                    <strong v-html="product.en388_I"></strong>
                </p>
                <p>
                    EN 388 is a European Standard.
                </p>
                <p>
                    Cut Level is determined by the number of cycles it takes a spinning circular blade, that is pulled across the material under a constant weight of 500 grams, to cut the fabric. As the number of cycles increase, so does the glove's ratings.
                </p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='en-388'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
