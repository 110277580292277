<template>
    <div>
        <div v-if="loading" >
            <div class="vh-100 d-flex justify-content-center align-items-center">
                <vue-loaders-ball-beat scale="2" color="black" />
            </div>

        </div>
        <div v-else>
            <product-header></product-header>

            <div id="main-container" class="position-relative d-flex flex-wrap justify-content-between ">
                <div class="sidebar">
                    <div id="filter-menu">
                        <smart-filters-list :filterOptions="filterOptions"></smart-filters-list>

                    </div>

                </div>

                <div class="product-container">

                    <!-- product navigation -->
                    <div
                        class="d-flex justify-content-between"
                        v-if="filteredProducts"
                        style="position:relative;"
                    >
                        <div>
                            <p
                                class="m-0 font-weight-bold text-secondary d-inline"
                                style="font-size:0.9rem; font-family: Helvetica, Arial, sans-serif;"
                                v-if="filteredProducts.data"
                            >
                                SHOWING {{filteredProducts.from}}-{{filteredProducts.to}} OF
                                {{ filteredProducts.total }} ITEMS
                            </p>
                            <p
                                v-else
                                class="m-0 font-weight-bold text-secondary d-inline"
                                style="font-size: 0.9rem; font-family: Helvetica, Arial, sans-serif;"
                            >
                                NO RESULTS FOUND
                            </p>
                        </div>

                        <!-- pagination -->
                        <div class="ml-auto d-inline font-weight-bold product-list-pagination" v-if="filteredProducts.total>0">

                            <button @click="navigatePage(filteredProducts.current_page-1)" :class="{'active': filteredProducts.prev_page_url}" class="nav-arrow"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>

                            <span>{{filteredProducts.current_page}}</span> of <span>{{ filteredProducts.last_page }}</span>

                            <button  @click="navigatePage(filteredProducts.current_page+1)" :class="{'active': filteredProducts.next_page_url}"  class="nav-arrow"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>

                        </div>
                    </div>

                    <!-- List of Filter -->
                    <div v-if="this.paramSet">
                        <product-filter-list ></product-filter-list>
                    </div>


                    <!-- product list -->
                    <div id="product-list-container" >
                        <div  class="product-list" v-if="filteredProducts.data">
                            <!-- diplaying only 25 product per page -->
                            <div
                                v-for="product in filteredProducts.data"
                                :key="product.id"
                                class="d-flex flex-column product justify-content-start mb-3"
                            >
                                <product-list-item :product="product"></product-list-item>
                            </div>
                        </div>
                    </div>

                    <!-- product navigation -->
                    <div
                        class="d-flex justify-content-between pt-4"
                        v-if="filteredProducts"
                        style="position:relative;"
                    >
                        <div>
                            <p
                                class="m-0 font-weight-bold text-secondary d-inline"
                                style="font-size:0.9rem; font-family: Helvetica, Arial, sans-serif;"
                                v-if="filteredProducts.data"
                            >
                                SHOWING {{filteredProducts.from}}-{{filteredProducts.to}} OF
                                {{ filteredProducts.total }} ITEMS
                            </p>
                        </div>

                        <div class="ml-auto d-inline font-weight-bold product-list-pagination" v-if="filteredProducts.total>0">

                            <button @click="navigatePage(filteredProducts.current_page-1)" :class="{'active': filteredProducts.prev_page_url}" class="nav-arrow"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>

                            <span>{{filteredProducts.current_page}}</span> of <span>{{ filteredProducts.last_page }}</span>

                            <button  @click="navigatePage(filteredProducts.current_page+1)" :class="{'active': filteredProducts.next_page_url}"  class="nav-arrow"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script type="text/javascript">
export default {
    data() {
        return {
            loading: true,
            currentParam:this.$route.query,
            filteredProducts:{},
            filterOptions:[],
        };
    },

    mounted(){
        window.addEventListener("resize", this.handleResize);


    },

    created(){


        if(!this.currentParam.hasOwnProperty("page")){
            this.currentParam["page"]=1;
        }

        if(Object.keys(this.currentParam).length ===1){
            axios.get(`/api/products/`).then(response => {
                this.filteredProducts=response.data;
                this.loading=false;
            });
        }else if(this.currentParam.q=="search" ){
            axios.get(`/api/searchProduct/${this.currentParam.v}/`).then(response => {
                this.filteredProducts=response.data;
                this.loading=false;

            });

        }else{
            axios.post(`/api/getfilteredprodlist`,this.currentParam).then(response => {
                this.filteredProducts=response.data;

                // get the filter options only if ccID is set
                if(this.currentParam.ccID != null){
                    axios.post(`/api/getfilteroptionslist`,this.currentParam).then(response => {
                        this.filterOptions=response.data;
                        this.loading=false;

                    });
                }else{
                    this.loading=false;
                }
            });
        }
    },


    computed: {
        // check if the param is set
        paramSet(){
            const params={...this.currentParam};

            if(Object.keys(params).length > 3){
                return true;
            }else{
                return false;
            }
        },



    },
    methods: {
        handleResize(e){
            let width=window.innerWidth;
            let prodGallery=document.getElementsByClassName("product-list")[0];
            if (width>1024){
                prodGallery.classList.remove("product-horizontal-layout");
            }
        },


        navigatePage(page){
            this.currentParam["page"]=page;

            if(Object.keys(this.currentParam).length ===1){
                axios.get(`/api/products/?page=${page}`).then(response => {
                    this.filteredProducts=response.data;
                });
            }else if(this.currentParam.hasOwnProperty("q") && this.currentParam.q=="search" ){
                axios.get(`/api/searchProduct/${this.currentParam.v}/?page=${page}`).then(response => {
                    this.filteredProducts=response.data;
                });

            }else{
                axios.post(`/api/getfilteredprodlist`,this.currentParam).then(response => {
                    this.filteredProducts=response.data;

                });
            }


        }
    },

    watch:{
        loading(next,prev){
            setTimeout(function() {
                document.getElementById("main-container").scrollIntoView({ block: 'start',  behavior: 'smooth' });

            }, 500);


        }
    },

    beforeDestroy(){
        window.removeEventListener("resize", this.handleResize);

    },
};
</script>

<style lang="scss" scoped>
#main-container{
    width:95%;
    margin: 0 auto;
    padding:3rem 0;
    scroll-margin-top: 60px;
    @media screen and (max-width: 1024px) {
        padding:0;
        margin:0;
        width:100%;
    }
    .sidebar {
        width:20%;

        @media screen and (max-width: 1024px) {
            width:100%;
        }
    }
    .product-container{
        width:78%;
        @media screen and (max-width: 1024px) {
            width:100%;
            padding:1rem;
        }
    }
}


.active{
    color:#cd1b00;
    cursor: pointer ;
    display:inline-block !important;
}
.nav-arrow{
    background:transparent;
    border:none;
    display:none;
    padding:0.2rem 0.4rem;
    i{
        font-size:1.2rem;
    }

}
li.page-item.page-number.active .page-link {
    font-size: 12px !important;
    padding: 6px 13px !important;
    font-family: "Acumin Pro", Arial, Helvetica, sans-serif !important;
}

.product-list-pagination{
    width: 120px;
    text-align:right;

}

.currentPage {
    font-family: "Acumin Pro", Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    position: absolute;
    top: 13px;
    right: 1.7rem;
}
</style>
