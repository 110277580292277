<template>

    <div v-if="product.en_374_5"  class="product-icon-tooltip">
        <div  class="prod-icon icon-tall" v-if="product.en_374_5 == 'Bacteria and Fungi'">
            <i v-html="getIcon('Bacteria and Fungi')"></i>
        </div>
        <div  class="prod-icon icon-tall" v-if="product.en_374_5 == 'Bacteria, Fungi and Virus'">
            <i v-html="getIcon('Bacteria, Fungi and Virus')"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p>Protective Gloves Against Micro-organisms</p>
                <p><span>ANSI Impact Level:</span> <strong v-html="product.ansi_impact"></strong></p>
                <p v-if="product.en_374_5 == 'Bacteria and Fungi'">
                    The EN ISO 374-5  standard measures the ability of gloves to protect users against bacteria and fungi. Gloves with this marking must pass EN374-2 for leak testing.
                </p>
                <p v-if="product.en_374_5 == 'Bacteria, Fungi and Virus'">
                    The EN ISO 374-5 VIRUS standard measures the ability of gloves to protect users against bacteria, fungi and viruses. Gloves with this marking must pass EN374-2 for leak testing.
                </p>

            </div>
        </section>
    </div>


</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(iconName){
            self=this;
            let iconFound;
            iconFound=this.icons.filter(function(icon){
                if(icon.name==iconName){
                    return icon;
                }
            });

            return iconFound[0].svg;
        }
    },
    created(){
    },

}
</script>
