<template>
    <div >
        <router-link :to="{name:'editUser', params:{id:this.data.id}}" class="btn btn-dark-orange" ><i class="fas fa-edit"></i></router-link>
    </div>
</template>
<script>
export default {
    name: "EditUserButton",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>
