<template>
<div class="row">
    <div class="col-md-2 sidebar">
        <h1 class="font-weight-normal pb-3">Edit User</h1>
        <div class="lines d-sm-none d-none d-lg-block"></div>
    </div>
     <div class="col-md-10">
        <div class="col-sm-6" v-if="editUser">
            <form class="mb-5">
                <div class="form-group">
                    <label for="name">Name:</label>
                    <input type="text" name="name" class="form-control" v-model="editUser.name" :class="[{'is-invalid':errorFor('editUser.name')}]">
                    <v-errors :errors="errorFor('name')"></v-errors>
                </div>
                <div class="form-group">
                    <label for="company">Company:</label>
                    <input type="text" name="company" class="form-control" v-model="editUser.company" :class="[{'is-invalid':errorFor('editUser.company')}]">
                    <v-errors :errors="errorFor('company')"></v-errors>
                </div>
                <div class="form-group">
                    <label for="title">Title:</label>
                    <input type="text" name="title" class="form-control" v-model="editUser.title" :class="[{'is-invalid':errorFor('editUser.title')}]">
                    <v-errors :errors="errorFor('title')"></v-errors>
                </div>
                <div class="form-group">
                    <label for="role">Role:</label>
                    <select name="role" v-model="editUser.role" class="form-select">
                        <option>customer</option>
                        <option>pip employee</option>
                        <option>rep agency</option>
                        <option>admin</option>
                    </select>
                    <v-errors :errors="errorFor('editUser.role')"></v-errors>
                </div>
                <div class="form-group">
                    <label for="address">Address:</label>
                    <input type="text" name="address" class="form-control" v-model="editUser.address" :class="[{'is-invalid':errorFor('editUser.address')}]">
                    <v-errors :errors="errorFor('address')"></v-errors>
                </div>
                <div class="form-group">
                    <label for="address2">Address Line 2:</label>
                    <input type="text" name="address2" class="form-control" v-model="editUser.address2" :class="[{'is-invalid':errorFor('editUser.address2')}]">
                    <v-errors :errors="errorFor('address2')"></v-errors>
                </div>
                <div class="form-group">
                    <label for="city">City:</label>
                    <input type="text" name="city" class="form-control" v-model="editUser.city" :class="[{'is-invalid':errorFor('editUser.city')}]">
                    <v-errors :errors="errorFor('city')"></v-errors>
                </div>
                <div class="form-group">
                    <label for="state">State:</label>
                    <input type="text" name="state" class="form-control" v-model="editUser.state" :class="[{'is-invalid':errorFor('editUser.state')}]">
                    <v-errors :errors="errorFor('state')"></v-errors>
                </div>
                <div class="form-group">
                    <label for="zipcode">Zipcode:</label>
                    <input type="text" name="zipcode" class="form-control" v-model="editUser.zipcode" :class="[{'is-invalid':errorFor('editUser.zipcode')}]">
                    <v-errors :errors="errorFor('zipcode')"></v-errors>
                </div>
                <div class="form-group">
                    <label for="email">Email Address:</label>
                    <input type="email" name="email" class="form-control" v-model="editUser.email" :class="[{'is-invalid':errorFor('editUser.email')}]" disabled>
                    <small class="form-text text-muted">You cannot edit the email.</small>
                    <v-errors :errors="errorFor('email')"></v-errors>
                </div>
                <div class="form-group">
                    <label for="phone">Phone Number:</label>
                    <input type="text" name="phone" class="form-control" v-model="editUser.phone" :class="[{'is-invalid':errorFor('editUser.phone')}]">
                    <v-errors :errors="errorFor('phone')"></v-errors>
                </div>
                <button  class="btn btn-success mr-3" :disabled="loading" @click.prevent="saveUser">SAVE</button>
                <!-- <router-link  class="btn btn-danger mr-3" :to="{name:'dashboard'}" >CANCEL</router-link> -->


            </form>
            <success v-if="success">
                User data has been updated !!!
            </success>
        </div>



    </div>
</div>

</template>
<style lang="scss" scoped>
    select{
        display: block;
        width:100%;
        padding: 0.375rem 0.75rem;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.6;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        text-transform: capitalize;
    }
    label{
        margin-bottom: 0;
        font-weight: 600;

    }
</style>
<script>
import validationErrors from '../mixins/validationErrors';
import {mapState, mapGetters} from  "vuex";
    export default{
        mixins: [validationErrors],
        data(){
            return{
                loading:false,
                message:false,
                editUser:null
            };
        },
         computed:{
             ...mapState({user:state=>state.user}),
            success(){
                return !this.loading && this.message;
            }
         },
         created(){
            this.loading=true;
            axios.get(`/api/user/${this.$route.params.id}`)
                .then(response=> {
                    this.editUser=response.data;
                    this.loading=false;
                });


        },
        methods:{
            async saveUser(){
                this.loading=true;
                this.errors=null;
                try {
                    await axios.post(`/api/user`, this.editUser).then((response) => {
                            this.message=true;
                            var self=this;
                            setTimeout(function(){
                                self.message=false;
                            }, 3000);
                        });
                        this.$store.dispatch("loadUser");
                } catch (error) {
                     this.errors=error.response && error.response.data.errors;

                }
                this.loading=false;
            },
            // async reloadUser(){
            //     this.loading=true;
            //     this.errors=null;

            //     try {
            //         const response=await axios.get(`/api/user/${this.editUser.id}`);
            //         if(200==response.status){
            //             this.$store.dispatch("loadUser");
            //         }
            //     } catch (error) {
            //          this.errors=error.response && error.response.data.errors;
            //     }
            //     this.loading=false;
            // }
        },
    };
</script>
