var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.product.ansi_type_class)?_c('div',{staticClass:"product-icon-tooltip"},[_c('div',{staticClass:"prod-icon icon-square"},[_c('i',{domProps:{"innerHTML":_vm._s(_vm.getIcon[0].svg)}})]),_vm._v(" "),_c('section',{staticClass:"icon-tooltip"},[_c('div',{directives:[{name:"switch",rawName:"v-switch",value:(_vm.product.ansi_type_class),expression:"product.ansi_type_class"}]},[_c('div',{directives:[{name:"case",rawName:"v-case",value:('R3'),expression:"'R3'"}]},[_vm._m(0)]),_vm._v(" "),_c('div',{directives:[{name:"case",rawName:"v-case",value:('R2'),expression:"'R2'"}]},[_vm._m(1)]),_vm._v(" "),_c('div',{directives:[{name:"case",rawName:"v-case",value:('P2'),expression:"'P2'"}]},[_vm._m(2)]),_vm._v(" "),_c('div',{directives:[{name:"case",rawName:"v-case",value:('O1'),expression:"'O1'"}]},[_vm._m(3)]),_vm._v(" "),_c('div',{directives:[{name:"case",rawName:"v-case",value:('N'),expression:"'N'"}]},[_vm._m(4)]),_vm._v(" "),_c('div',{directives:[{name:"case",rawName:"v-case",value:('E'),expression:"'E'"}]},[_vm._m(5)])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_c('span',[_vm._v("Type/ Class")]),_vm._v(" "),_c('strong',[_vm._v("R3")])]),_vm._v(" "),_c('p',[_vm._v("\n                        Class R3 apparel is intended for workers requiring the highest level of visibility, have high task loads and need to be visible throughout a full range of body motions.\n                    ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_c('span',[_vm._v("Type/ Class")]),_c('strong',[_vm._v("R2")])]),_vm._v(" "),_c('p',[_vm._v("Type/Class R2 apparel is necessary for workers exposed to traffic traveling over 25 mph and who work against complex backgrounds.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_c('span',[_vm._v("Type/ Class")]),_c('strong',[_vm._v("P2")])]),_vm._v(" "),_c('p',[_vm._v("\n                        The Type/Class P2 standard was developed to provide enhanced visibility for emergency responders and law enforcement personnel in both roadway and off-road environments. All vests meet revised ANSI/ISEA 207-2015 standard.\n                    ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_c('span',[_vm._v("Type/ Class")]),_c('strong',[_vm._v("O1")])]),_vm._v(" "),_c('p',[_vm._v("\n                        Class O1 apparel is intended off-road use, to provide added visibility to workers who are not exposed to traffic.\n                    ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_c('span',[_vm._v("Non-ANSI")])]),_vm._v(" "),_c('p',[_vm._v("\n                        Non-ANSI garments are color enhanced and offer an additional level of safety for low risk activities, but do not meet ANSI standards.\n                    ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',[_c('span',[_vm._v("ANSI Classe E")])]),_vm._v(" "),_c('p',[_vm._v("\n                        Applies to retro-reflective equipped pants or shorts, designed to be worn in combination with either Class 2 or Class 3 tops. When worn in this manner, the ensemble is rated as Performance Class 3.\n                    ")])])
}]

export { render, staticRenderFns }