<template>

    <div v-if="product.ansi_puncture"  class="product-icon-tooltip">
        <div  class="prod-icon icon-square">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p><span>ANSI PUNCTURE LEVEL:</span><strong v-html="product.ansi_puncture"></strong></p>
                <p><span>Newtons:</span><strong v-html="product.ansi_puncture_newtons"></strong></p>
                <p>The ANSI/ISEA 105-2016 blunt force puncture testing uses a probe to simulate a tear or burst hazard. The test measures the amount of force needed for a blunt probe to pierce through PPE material. Results are given in Newtons, which is converted into a 1-5 scale and spans from 10 newtons (Level 1) to 150+ newtons (Level 5) of puncture resistance.</p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='ansi-puncture-'+self.product.ansi_puncture){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
