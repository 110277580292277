<template>
<div class="row ">
    <div class="col-md-2  sidebar">
        <h1 class="font-weight-normal pb-3">Change <br>Password</h1>
        <div class="lines d-sm-none d-none d-lg-block"></div>
    </div>
    <div class="col-md-10">

        <success v-if="success">
            Successfully updated your password.
        </success>
        <fatal-error v-if="error">
            Unable to update your password. Please try again.
        </fatal-error>
        <div class="col-sm-6">
            <form>
                <div class="form-group">
                <label for="new-password">New Password: <span>(MINIMUM OF 6 CHARACTERS)</span></label>
                <input type="password" class="form-control" placeholder="New Password" name="newPassword" v-model="changePassword['newPassword']" :class="[{'is-invalid': errorFor('newPassword')}]">
                <v-errors :errors="errorFor('newPassword')"></v-errors>
                </div>
                <div class="form-group">
                    <label for="re-type-password">Re-Type New Password:</label>
                    <input type="password" class="form-control"  placeholder="Re-Type New Password" name="confirmPassword"  v-model="changePassword['confirmPassword']" :class="[{'is-invalid': errorFor('confirmPassword')}]">
                    <v-errors :errors="errorFor('confirmPassword')"></v-errors>
                </div>
                <input type="hidden"  name="id" v-model="changePassword['id']" >
                <button type="submit" class="btn btn-primary" @click.prevent="savePassword">Change Password</button>
            </form>
        </div>
    </div>
</div>

</template>
<style lang="scss" scoped>
    label{
        margin-bottom: 0;
        font-weight: 600;
        span{
            font-size: 0.75rem;
            color: #666666;
            font-weight:400;
            margin: 1rem 0;
        }
    }
</style>
<script>
import {mapState, mapGetters} from  "vuex";
import validationErrors from '../mixins/validationErrors';
    export default{
        mixins: [validationErrors],
        data(){
            return {
                message:false,
                success:false,
                error:false,
                changePassword:{
                    confirmPassword:null,
                    newPassword:null,
                }
            }
        },
        computed:{
            ...mapState({user:state=>state.user}),
         },
        methods:{

            async savePassword () {
                this.errors=null;
                try{
                    console.log(this.changePassword);
                 await axios.put( `/api/user/changePassword/`, this.changePassword).then((response)=>{
                    this.changePassword.confirmPassword='';
                    this.changePassword.newPassword='';
                    this.success=true;
                    var self=this;
                    setTimeout(function(){
                        self.success=false;
                    }, 3000);
                 });
                } catch(error) {
                        this.errors=error.response && error.response.data.errors;
                        this.error=true;
                        var self=this;
                        setTimeout(function(){
                            self.error=false;
                        }, 3000);
                    }
            }
        }
    }
</script>
