<template>
    <div class="login-container">
        <div class="row">
            <div class="col-lg-2 sidebar">
                <h1 class="font-weight-normal pb-3">Login or Sign Up</h1>
                <div class="lines d-sm-none d-none d-lg-block"></div>
            </div>
            <div class="col-lg-10">
                <transition name="fade" v-if="errorLogin">
                    <fatal-error>Could not login, your credentials do not match our records. Please try again. </fatal-error>
                </transition>
                <transition name="fade" v-if="verifyMessage">
                    <success>
                        Your Email has been verified you can log in to the portal now.
                    </success>
                </transition>
                <fatal-error v-if="hasNotVerified">
                    You have not verified your email address.
                </fatal-error>
                <fatal-error v-if="verifyInvalid">
                    Invalid code for verification.
                </fatal-error>
                <div class="modal fade"  id="customerVerification" tabindex="-1" role="dialog" aria-labelledby="customerVerificationLabel" aria-hidden="true" >
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                Please check you email for user verification. You can login to the portal once the email has been verified.
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal" >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="loading">
                    Data Loading...
                </div>
                <div class="row" v-else>
                    <div class="col-md-5">
                        <div class="mb-5">
                            <h3>LOGIN: <span>I HAVE A PASSWORD</span></h3>
                            <form>
                                <div class="form-group">
                                    <label for="login_email">* Email Address:</label>
                                    <input type="text" name="login_email" class="form-control" v-model="login_email"
                                    :class="[{'is-invalid': errorFor('login_email')}]">
                                    <v-errors :errors="errorFor('login_email')"></v-errors>
                                </div>
                                <div class="form-group">
                                    <label for="login_password">* Password:</label>
                                    <input type="password" name="login_password"  class="form-control" v-model="login_password"
                                    :class="[{'is-invalid': errorFor('login_password')}]">
                                    <v-errors :errors="errorFor('login_password')"></v-errors>
                                </div>
                                <button class="btn btn-primary" type="submit"  @click.prevent="dashboardLogin">Login</button>
                                <hr />
                                <div class="text-nowrap">
                                    Forgot your password?
                                    <router-link :to="{path: '/customer/reset-password'}" class="font-weight-bold">Reset password</router-link>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-md-5">
                        <div class="mb-5">
                            <h3>SIGN UP: <span>I don't have a password</span></h3>
                            <div >
                                <form>
                                    <div class="form-group">
                                        <label for="firstname">* First Name:</label>
                                        <input type="text" name="firstname"  class="form-control" v-model="user.firstname"
                                        :class="[{'is-invalid': errorFor('firstname')}]">
                                        <v-errors :errors="errorFor('firstname')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="lastname">Last Name:</label>
                                        <input type="text" name="lastname"  class="form-control" v-model="user.lastname"
                                        :class="[{'is-invalid': errorFor('lastname')}]">
                                        <v-errors :errors="errorFor('lastname')"></v-errors>
                                    </div>

                                    <div class="form-group">
                                        <label for="email">* Email:</label>
                                        <input type="text" name="email" class="form-control" v-model="user.email"
                                        :class="[{'is-invalid': errorFor('email')}]" >
                                        <v-errors :errors="errorFor('email')"></v-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="password">* Password:</label>
                                        <input type="password" name="password" class="form-control" v-model="user.password"
                                        :class="[{'is-invalid': errorFor('password')}]">
                                        <v-errors :errors="errorFor('password')"></v-errors>
                                    </div>
                                    <button class="btn btn-primary" type="submit"  @click.prevent="register" >SIGN UP</button>
                                    <hr />
                                    <div class="text-nowrap">
                                        Already have an account?
                                        <router-link :to="{name: 'login'}" class="font-weight-bold">Log-in</router-link>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>



</template>

<script>
import validationErrors from '../components/mixins/validationErrors';
import { customerLogIn, customerLogOut } from "../components/utils/auth";
    export default{
        mixins: [validationErrors],
        data(){
            return{
                loading:true,
                login_email:null,
                login_password:null,
                success:false,
                verifyMessage:false,
                verifyInvalid:false,
                hasNotVerified:false,
                errorLogin:false,
                user:{ },

            };
        },
        created(){

            if (this.$route.path=="/auth/customer/login/verify"){
                  axios.get(`/api/customer/verify/${this.$route.query.code}`).then(response=>{
                      this.loading=false;
                        if(response.data){
                            this.verifyMessage=true;
                             var self=this;
                                setTimeout(function(){
                                    self.verifyMessage=false;
                                }, 6000);
                        }else{
                            this.verifyInvalid=true;
                            var self=this;
                            setTimeout(function(){
                                self.verifyInvalid=false;
                            }, 6000);
                        }
                    });
            }
            if(this.$route.query.errorLogin=='true'){
                this.loading=false;
                this.errorLogin=true;
                var self=this;
                setTimeout(function(){
                    self.errorLogin=false;
                }, 3000);
            }
        },
        methods:{
            async dashboardLogin(){
                this.error=null;
                delete this.$route.query.errorLogin;
                this.errorLogin=false;
                try{
                    await axios.get(`/api/getCustomer/${this.login_email}`).then(response=>{
                        if(response.data != false){
                            if(response.data.email_verified_at ==null){
                                this.hasNotVerified=true;
                                var self=this;
                                setTimeout(function(){
                                    self.hasNotVerified=false;
                                }, 5000);
                            };
                            if (response.data.email_verified_at !=null ){
                                this.login();
                            }
                        }else{
                            this.errorLogin=true;
                            var self=this;
                            setTimeout(function(){
                                self.errorLogin=false;
                            }, 4000);
                        }


                    });
                }catch(error){
                    this.errorLogin=true;
                    var self=this;
                    setTimeout(function(){
                        self.errorLogin=false;
                    }, 4000);
                };

            },
            async login(){
                try {
                    await axios.get(`/sanctum/csrf-cookie`);
                    await axios.post(`/api/customer/login`, {
                            email: this.login_email,
                            password: this.login_password
                        }).then(response=>{
                            console.log(response.data);
                        });
                        customerLogIn(this.login_email);
                        this.$store.dispatch("loadCustomer");
                        $(".show").removeClass("show");
                        this.$router.push({ name: "dashboard" });

                } catch (error) {
                    this.$router.push({ name: "home"});

                }
            },
            async register(){
                this.errors=null;
                // delete this.$route.query.errorLogin;
                // this.errorLogin=false;
                try {

                    await axios.post(`/api/customer/register`, this.user).then(response=>{
                        this.user={};
                        $("#customerVerification").modal("show");
                    });
                } catch (error) {
                    this.errors=error.response && error.response.data.errors;
                }

            }

        }
    };
</script>
