<template>

    <div v-if="product.en_407"  class="product-icon-tooltip">
        <div  class="prod-icon icon-tall">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p><span>Protective Gloves Against Thermal Hazards: </span><strong v-html="product.en_407"></strong></p>
                <p><span>Resistance to Flammability: </span><strong v-html="product.en_407_flame"></strong></p>
                <p><span>Contact Heat Resistance: </span><strong v-html="product.en_407_contact_heat"></strong></p>
                <p><span>Convective Heat Resistance: </span><strong v-html="product.en_407_convec_heat"></strong></p>
                <p><span>Radiant Heat Resistance: </span><strong v-html="product.en_407_radiant_heat"></strong></p>
                <p><span>Resistance to Small Splashes of Molten Metal: </span><strong v-html="product.en_407_small_splash"></strong></p>
                <p><span>Resistance to Large Splashes of Molten Metal: </span><strong v-html="product.en_407_large_splash"></strong></p>
                <p>
                    EN 407 is a general European standard designed to be used for any glove that is to be sold as providing protection against thermal hazards. All six tests are graded on a scale from 0 to 4, with 0 signifying that the glove failed the test, and 4 demonstrating it has achieved the maximum resistance in that specific area.
                </p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='en-407'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
