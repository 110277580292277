<template>
    <div>
        <button  class="btn btn-success" @click.prevent="restore()"><i class="fas fa-check"></i></button>
    </div>
</template>
<script>
export default {
    name: "RestoreUserButton",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    methods:{
        restore(){
            this.$store.dispatch("restoreUser",this.data);

        },
    }
};
</script>
