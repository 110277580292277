<template>
    <nav id="mobile-nav">
        <ul id="nav-list">
            <li>
                <router-link
                    :to="{ path: '/' }"
                    class="mm-listitem__text r-link"
                    >Home</router-link
                >
            </li>
            <li v-if="useProducts">
                <span>Products</span>
                <ul>

                    <li v-for="(category, index) in categories"
                                :key="'scID' + index">
                        <strong class="category-heading">{{ category.select_code.title }}</strong>
                        <ul>
                            <li v-for="(subCategory, index) in category.commodity_code"
                                        :key="'ccID' + index">
                                        <router-link
                                            :to="{
                                                name: 'products',
                                                query: {
                                                    scID: category.select_code.id,
                                                    ccID: subCategory.id
                                                }
                                            }" class="mm-listitem__text r-link">{{ subCategory.title }}</router-link>
                            </li>

                        </ul>
                    </li>


                </ul>
            </li>

            <li>
                <router-link
                    :to="{ path: '/aboutus' }"
                    class="mm-listitem__text r-link"
                    >About Us</router-link
                >
            </li>
            <li>
                <router-link
                    :to="{ path: '/literature' }"
                    class="mm-listitem__text r-link"
                    >Literature</router-link
                >
            </li>
            <li>
                <router-link
                    :to="{ path: '/contact' }"
                    class="mm-listitem__text r-link"
                    >Contact Us</router-link
                >
            </li>
            <li>
                <a
                    href="https://www.amazon.com/stores/BOSS/page/FD2AF217-EAE1-46B2-97C3-DDBDDE4E51B4?ref_=ast_bln"
                    target="_blank"
                    class="mm-listitem__text r-link"
                    >BUY NOW</a>
            </li>
            <li v-if="isLoggedIn">
                <router-link
                    :to="{ name: 'dashboard' }"
                    class="mm-listitem__text r-link"
                    >User Dashboard</router-link
                >
            </li>
            <li v-if="isLoggedIn">
                <router-link
                    :to="{ name: 'myAccount' }"
                    class="mm-listitem__text r-link"
                    >My Account</router-link
                >
            </li>
            <li v-if="isLoggedIn">
                <router-link
                    :to="{ name: 'changePassword' }"
                    class="mm-listitem__text r-link"
                    >Change Password</router-link
                >
            </li>
            <li v-if="isLoggedIn && user.role == 'admin'">
                <router-link
                    :to="{ name: 'userList' }"
                    class="mm-listitem__text r-link"
                    >Users List</router-link
                >
            </li>
            <li v-if="isLoggedIn && user.approve == 1">
                <router-link
                    :to="{ name: 'myTools' }"
                    class="mm-listitem__text r-link"
                    >My Tools</router-link
                >
            </li>

            <li v-if="isLoggedIn">
                <a
                    href="javascript:void(0);"
                    @click.prevent="logout"
                    class="mm-listitem__text r-link"
                    >Log Out</a
                >
            </li>

            <li v-else>
                <router-link
                    :to="{ path: '/auth/login' }"
                    class="mm-listitem__text r-link"
                    >Distributor Login</router-link
                >
            </li>
            <li>
                <router-link
                    :to="{ name: 'privacy' }"
                    class="mm-listitem__text r-link"
                    >Privacy Policy</router-link
                >
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { logIn, logOut } from "../utils/auth";
export default {
    data() {
        return {};
    },
    computed: {

        ...mapState([
            "isLoggedIn",
            "userEmail",
            "user",
            "useCart",
            "useAdmin",
            "useLocale",
            "categories",
            "useProducts"
        ]),

    },
    methods: {
        closeMenu() {
            api.close();
        },

        async logout() {
            try {
                axios.post("/logout");
                this.$store.dispatch("logout");
                this.$router.push({ name: "home" });
            } catch (error) {
                this.$store.dispatch("logout");
            }
        }
    },
    created() {
        setInterval( document.addEventListener("DOMContentLoaded", () => {
            const menu = new Mmenu("#mobile-nav", {
                extensions: ["theme-dark"],
                counters: false,
                navbars: [
                    {
                        position: "top",
                        content: ["prev", "title"]
                    },
                    {
                        position: "bottom",
                        content: [
                            "<a class='fab fa-facebook fa-2x' href='https://www.facebook.com/BossGloves' target='blank'></a>",
                            "<a class='fab fa-twitter-square fa-2x' href='https://twitter.com/BossGloves' target='blank'></a>"
                        ]
                    }
                ]
            });
            const api = menu.API;

            $(".r-link").click(function() {
                api.close();
            });

        }),3000);

    },

};
</script>

<style lang="scss" scoped>
.category-heading{
    background: #000;
    display:block;
    padding:1rem 0.4rem;

}
nav #mobile-nav{
    display:none;
}
.modal.fade .modal-dialog {
    position: fixed;
    margin: auto;
    width: 500px;
    height: 100%;
    left: -500px;
    transition: opacity 0.4s linear, left 0.4s ease-out;
}

.modal.fade.show .modal-dialog {
    left: 0;
}

.modal .modal-content {
    height: 100%;
    overflow-y: auto;
}

.modal .modal-body {
    padding: 15px 15px 80px;
}

/* ----- MODAL STYLE ----- */
.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    border-bottom-color: #eeeeee;
    background-color: #fafafa;
}

button {
    &:hover {
        background: transparent;
    }
}

.router-link-active {
    border-bottom: 1px solid #222;
}
</style>
