<template>
    <div v-if="performanceData.length == pdFILTER.length">
        <p style="margin:1rem 0 0.5rem 0;">
            <span style="font-size:24px; font-weight:500;">Performance Data</span>
        </p>
        <table style="font-size:10px; width:100%;">
            <tbody>
                <tr v-for="(value,index) in performanceData" :key="index">
                    <td style="width:50%;">
                        {{ value.attribute }}
                    </td>
                    <td style="width:50%;">
                        {{ value.value }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<style lang="scss" scoped>
table tbody tr:nth-child(2n) {
    background-color: #f7f7f7;
}
</style>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
            pdFILTERArray:null,

            pdFILTER:[],
            performanceData:[]
        }

    },
    created() {

        const unwatch=this.$watch('product',()=>{
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //PERFORMANCE DATA: FILTER/////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            switch (this.product.category.commodity_code_id.toString()) {

                //ARM PROTECTION///////////////////////////////////////////////////////////////////////////////////////
                //Arc Rated Sleeve
                case "11651":
                    this.pdFILTERArray= "12|49|182";
                    break;
                //Claw Cover Sleeves
                case "11733":
                    this.pdFILTERArray= "12|150|182";
                    break;
                //Cut Resistant Sleeve
                case "11622":
                    this.pdFILTERArray= "12|150|151|182";
                break;
                //General Duty Sleeve
                case "11666":
                    this.pdFILTERArray= "";
                break;
                //Heat Defense Sleeve
                case "11650":
                    this.pdFILTERArray= "12|182";
                break;
                //Metal Mesh Sleeves
                case "11725":
                    this.pdFILTERArray= "";
                break;


                //COLD PROTECTION///////////////////////////////////////////////////////////////////////////////////////
                //Heated Apparel
                case "11712":
                    this.pdFILTERArray= "";
                break;
                //Warming
                case "11662":
                    this.pdFILTERArray= "";
                break;

                //CONTROLLED ENVIRNOMENTS///////////////////////////////////////////////////////////////////////////////////////
                //CE Apron
                case "11588":
                    this.pdFILTERArray= "131|132|133";
                break;
                //CE Fingercots
                case "11686":
                    this.pdFILTERArray= "131|132|133";
                break;
                //CE Gloves
                case "11553":
                    this.pdFILTERArray= "131|132|133";
                break;
                //CE Supplies
                case "11548":
                    this.pdFILTERArray= "131|132|133";
                break;
                //Reusable Clothing
                case "11697":
                    this.pdFILTERArray= "131|157|132|133";
                break;

                //ELECTRICAL SAFETY///////////////////////////////////////////////////////////////////////////////////////
                //Arc Accessories
                case "11640":
                    this.pdFILTERArray= "49|84";
                break;
                //Arc Clothing
                case "11642":
                    this.pdFILTERArray= "49|84|41";
                break;
                //Arc Protection Kits
                case "11644":
                    this.pdFILTERArray= "49|84";
                break;
                //Electrical Glove Protection
                case "11624":
                    this.pdFILTERArray= "63";
                break;
                //Glove Accessories
                case "11648":
                    this.pdFILTERArray= "";
                break;
                //Insulating Blankets
                case "11698":
                    this.pdFILTERArray= "63";
                break;
                //Insulating Glove Kit
                case "11649":
                    this.pdFILTERArray= "63";
                break;
                //Insulating Gloves
                case "11620":
                    this.pdFILTERArray= "63";
                break;
                //Insulating Sleeves
                case "11670":
                    this.pdFILTERArray= "63";
                break;

                //ERGONOMICS/////////////////////////////////////////////////////////////////////////////////////////////
                //Ergonomic Supports
                case "11614":
                    this.pdFILTERArray= "";
                break;
                //Knee Protection
                case "11564":
                    this.pdFILTERArray= "";
                break;

                //EYE PROTECTION/////////////////////////////////////////////////////////////////////////////////////////////
                //Safety Eyewear
                case "11613":
                    this.pdFILTERArray= "";
                break;
                //Safety Goggles
                case "11554":
                    this.pdFILTERArray= "";
                break;
                //Face Protection
                case "11611":
                    this.pdFILTERArray= "";
                break;
                //Safety Eyewear Accessories
                case "11611":
                    this.pdFILTERArray= "";
                break;

                //FALL PROTECTION/////////////////////////////////////////////////////////////////////////////////////////////
                //Tool Tethering
                case "11693":
                    this.pdFILTERArray= "";
                break;

                //FIRST AID///////////////////////////////////////////////////////////////////////////////////////////////////
                //First Aid Kit
                case "11582":
                    this.pdFILTERArray= "152|154";
                break;

                //FOOT PROTECTION///////////////////////////////////////////////////////////////////////////////////////////////////
                //Over-The-Shoe
                case "11708":
                    this.pdFILTERArray= "129";
                break;
                //Over-The-Sock
                case "11707":
                    this.pdFILTERArray= "129";
                break;

                //HAND PROTECTION///////////////////////////////////////////////////////////////////////////////////////
                //Chemical Resistant Gloves
                case "11559":
                    this.pdFILTERArray= "12|EN374|144|92|2|93|94";
                break;
                //Claw Cover Gloves
                case "11732":
                    this.pdFILTERArray= "12|122|150|105|144|92|2|93|94";
                break;
                //Cut Resistant Gloves
                case "11570":
                    this.pdFILTERArray= "12|122|150|105|151|144|92|2|93|94|182";
                break;
                //Cold Protection Gloves
                case "11587":

                    this.pdFILTERArray= "12|150|92|2|93|94";
                break;
                //Disposable Gloves
                case "11547":
                    this.pdFILTERArray= "EN374";
                break;
                //Extended Use Disposable Gloves
                case "11731":
                    this.pdFILTERArray= "EN374";
                break;
                //Fabric Work Gloves
                case "11552":
                    this.pdFILTERArray= "";
                break;
                //General Purpose Gloves - Coated
                case "11549":
                    this.pdFILTERArray= "122|150|105|144|92|2|93|94";
                break;
                //General Purpose Gloves - Uncoated
                case "11550":
                    this.pdFILTERArray= "150|144|92|2|93|94";
                break;
                //Heat Protection Gloves
                case "11578":
                    this.pdFILTERArray= "151|12|150";
                break;
                //High Performance Gloves
                case "11581":
                    this.pdFILTERArray= "12|122|150|105|49|151|144|92|2|93|94";

                    break;
                //Leather Drivers Gloves
                case "11573":
                    this.pdFILTERArray= "12|150|144|92|2|93|94";
                break;
                //Leather Palm Gloves
                case "11621":
                    this.pdFILTERArray= "150";
                break;
                //Metal Mesh Gloves
                case "11724":
                    this.pdFILTERArray= "";
                break;
                //Parade & Uniform Gloves
                case "11568":
                    this.pdFILTERArray= "";
                break;

                //HEAD PROTECTION///////////////////////////////////////////////////////////////////////////////////////////////////
                //Accessories
                case "11721":
                    this.pdFILTERArray= "";
                break;
                //Bump Caps
                case "11576":
                    this.pdFILTERArray= "";
                break;
                //FR Hoods
                case "11652":
                    this.pdFILTERArray= "";
                break;
                //Go-To-Work Kits
                case "11645":
                    this.pdFILTERArray= "";
                break;
                //Hard Hats
                case "11575":
                    this.pdFILTERArray= "123|147|63";
                break;
                //Hoods & Paint Caps
                case "11619":
                    this.pdFILTERArray= "";
                break;
                //Safety Helmets
                case "11738":
                    this.pdFILTERArray= "123|147|63";
                break;
                //Winter Caps & Liners
                case "11628":
                    this.pdFILTERArray= "";
                break;

                //HEARING PROTECTION///////////////////////////////////////////////////////////////////////////////////////////////////
                //Ear Muffs
                case "11574":
                    this.pdFILTERArray= "52|158|160|163|161";
                break;
                //Ear Plugs
                case "11598":
                    this.pdFILTERArray= "52|158|160|163|161";
                break;

                //HEAT STRESS//////////////////////////////////////////////////////////////////////////////////////////////////////////
                //Absorptive
                case "11655":
                    this.pdFILTERArray= "82";
                break;
                //Evaporative Cooling
                case "11616":
                    this.pdFILTERArray= "82";
                break;
                //Phase Change Cooling
                case "11627":
                    this.pdFILTERArray= "82";
                break;
                //Sun Protection
                case "11656":
                    this.pdFILTERArray= "";
                break;

                //Hydration
                case "11736":
                    this.pdFILTERArray= "";
                break;

                //PROTECTIVE CLOTHING////////////////////////////////////////////////////////////////////////////////////////////////////
                //Aprons
                case "11560":
                    this.pdFILTERArray= "";
                break;
                //Cut Resistant Wear
                case "11654":
                    this.pdFILTERArray= "12|165|166|167|168|169|170|171|172|173|174|175";
                break;
                //Disposable Clothing
                case "11561":
                    this.pdFILTERArray= "";
                break;
                //FR Clothing
                case "11566":
                    this.pdFILTERArray= "49|41";
                break;
                //Face Protection
                case "11694":
                    this.pdFILTERArray= "";
                break;
                //Hi-Vis Apparel
                case "11629":
                    this.pdFILTERArray= "41";
                break;
                //Hi-Vis Cold Gear
                case "11711":
                    this.pdFILTERArray= "41";
                break;
                //Metal Mesh Clothing
                case "11723":
                    this.pdFILTERArray= "";
                break;
                //Rainwear
                case "11558":
                    this.pdFILTERArray= "";
                break;

                //RESPIRATORY SAFETY////////////////////////////////////////////////////////////////////////////////////////////////////
                //Cartridges
                case "11671":
                    this.pdFILTERArray= "";
                break;
                //Disposable Masks
                case "11571":
                    this.pdFILTERArray= "";
                break;
                //Reusable Facepieces
                case "11668":
                    this.pdFILTERArray= "";
                break;

                //WARNING BEACONS////////////////////////////////////////////////////////////////////////////////////////////////////
                //Eflare Beacons
                case "11601":
                    this.pdFILTERArray= "";
                break;
                //Flash Baton
                case "11572":
                    this.pdFILTERArray= "";
                break;

                //WELDING SAFETY////////////////////////////////////////////////////////////////////////////////////////////////////
                //Accessories
                case "11709":
                    this.pdFILTERArray= "";
                break;
                //FR Clothing
                case "11701":
                    this.pdFILTERArray= "49|41";
                break;
                //FR Hoods
                case "11702":
                    this.pdFILTERArray= "";
                break;
                //Hand Protection
                case "11703":
                    if (this.product.hide) {
                        this.pdFILTERArray= "49|84|12|150";
                    } else {
                        this.pdFILTERArray= "49|84|12|150|144|92|2|93|94";
                    }
                break;
                //Winter Caps & Liners
                case "11706":
                    this.pdFILTERArray= "49|84";
                break;
            } //switch(this.product.category.commodity_code_id)


            if(this.pdFILTERArray){
                //exlode the filter ids
                this.pdFILTER = this.pdFILTERArray.split("|");

                //cycle through the filter ids
                this.pdFILTER.forEach((pdFID, index )=>{

                    let pdHEAD=null,pdATTR=null;

                    axios.get(`/api/filter/${pdFID}/`).then(response => {
                        let FILTERrow=response.data[0];

                        //put filter in a variable
                        let FILTERcol = FILTERrow.filter;
                        pdHEAD = FILTERrow.head_EN;

                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        //PERFORMANCE DATA: ATTRIBUTE//////////////////////////////////////////////////////////////////////////////////
                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

                        //get attribute data
                        pdATTR = this.product[FILTERcol];

                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
                        //PERFORMANCE DATA: APPEND/////////////////////////////////////////////////////////////////////////////////////
                        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

                        switch (pdFID) {
                            case "52": pdHEAD = pdHEAD+" (ANSI S3.19-1974)"; break;
                            case "161": pdATTR = "Class " +pdATTR; break;
                            case "163": pdATTR = "Class " +pdATTR; break;
                            case "84": pdATTR = pdATTR +" cal/cm2"; break;
                            case "63": pdATTR = pdATTR.replace("O", "0"); break;
                        }

                        //ESD S20.20
                        if (pdFID == "157") {
                            switch(pdATTR) {
                                case "Static Control Garment": pdATTR = pdATTR+"<br />&lt; 1x10<sup>11</sup>&#8486; Point to point"; break;
                                case "Groundable Static Control Garment": pdATTR = pdATTR+"<br />&lt; 1x10<sup>9</sup>&#8486; Point to groundable point"; break;
                                case "Groundable Static Control Garment System": pdATTR = pdATTR+"<br />&lt; 3.5x10<sup>7</sup>&#8486; Measured in combination with a person"; break;
                            }//switch($tdATTR)
                        }//if ($tdFID == 157)


                        //do not show these attributes if there is no value
                        let arATTRnoEmptyDisplay = [165,166,167,168,169,170,171,172,173,174,175];

                        //if empty and not in the "do not show" array, replace empty string with double dash
                        if (pdATTR == "" && !arATTRnoEmptyDisplay.includes(pdFID)) {
                            pdATTR = "--";
                        }

                        //show results when attribute value is not empty
                        if (pdATTR != "") {
                            this.performanceData.push({attribute:pdHEAD, value:pdATTR, filterID:pdFID});

                            // sort array data
                            this.sortPerformanceData();
                        }
                    })
                    .catch(error=>{
                        if(pdFID=="EN374"){
                            pdHEAD = "EN 374";
                            if(this.product.en374.length!=0){
                                pdATTR = this.product.en374.en374;
                            }else{
                                pdATTR="--";
                            }

                        }

                        //show results when attribute value is not empty
                        if (pdATTR != "") {
                            this.performanceData.push({attribute:pdHEAD, value:pdATTR, filterID:pdFID});

                            // sort array data
                            this.sortPerformanceData();
                        }
                    });



                });

            }

            unwatch();
        });

    },
    methods:{
        sortPerformanceData(){
             // sort the performance data based on the tdFILTER
             if(this.performanceData.length==this.pdFILTER.length){
                        this.performanceData.sort(function(a, b) {
                                var indexA = this.pdFILTER.indexOf(a.filterID);
                                var indexB = this.pdFILTER.indexOf(b.filterID);
                                return indexA - indexB;
                            });
                    }
        }
    }
    // watch:{
    //     prodData: function(newVal){
    //         this.product=newVal;

    //     },
    //     performanceData: function(newVal){
    //         this.performanceData=newVal;
    //     }
    // }

}
</script>
