<template>

    <div v-if="product.construction &&  product.construction.indexOf('--ESD Safe--') != -1"  class="product-icon-tooltip">
        <div  class="prod-icon icon-square">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p><strong>ESD Safe</strong></p>
                <p>
                    ESD is an acronym for electrostatic discharge. This discharge is the rapid transfer of static between two different objects. Although ESD doesn’t typically cause harm to the human body - you might only experience it as a small, surprising shock - it can cause extensive damage to electrical equipment and sensitive instruments. This damage may either be permanent, causing the device to malfunction (known as a catastrophic failure), or it may occur on a smaller scale that would be difficult to detect before the device is sent out for service (known as latent defect)
                </p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='esd-safe'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
