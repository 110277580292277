<template>
    <div>

        <span style="font-size:24px; line-height:28px; font-weight:500;" v-html="brand"></span>
        <br />
        <span style="display:inline-block; line-height:1.2;" v-html="description"></span>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        brand:String,
        description:String
    },
    data() {
        return {
        }

    },
    computed: {

    },
    created(){
    },

}
</script>
