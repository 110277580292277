<template>

    <div v-if="product.ansi_cut_9"  class="product-icon-tooltip">
        <div  class="prod-icon icon-square">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p><span>ANSI Cut Level:</span><strong v-html="product.ansi_cut_9"></strong></p>
                <p><span>Test Method:</span><strong>ASTM F2992-15</strong></p>
                <p><span>Gram Score:</span><strong v-html="product.ansi_gram"></strong></p>
                <p>
                    New edition ANSI/ISEA 105-2016 outlines a new test method for determining cut scores and a revised scale from A1-A9.
                </p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='ansi-'+self.product.ansi_cut_9){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
