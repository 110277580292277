<template>

    <div v-if="product.hrc_level"  class="product-icon-tooltip">
        <div  class="prod-icon icon-square">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p>
                    <span v-html="product.hrc_method"></span>
                    <strong>{{ product.hrc_cal }} Cal/cm<sup>2</sup></strong>
                </p>
                <p>
                    The NFPA has identified the various FR hazardous risk category levels, which range from PPE 1 (which is low risk and requires AR clothing with a minimum arc rating of 4), up to PPE 4 (which is high risk and requires AR clothing with a minimum arc rating of 40). Each level, 1-4, is rated at a certain amount of flame resistance measured in cal/cm2.
                </p>
            </div>
        </section>
    </div>


</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='ppe-'+self.product.hrc_level+'-color'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
