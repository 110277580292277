<template>

    <div v-if="product.en_16350"  class="product-icon-tooltip">
        <div  class="prod-icon icon-tall">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p><span>Electrostatic Properties </span></p>

                <p>
                    EN 16350 European standard defines the test conditions and requirements for electrostatic properties of  protective gloves used in ATEX area (Atmosphere Explosive). The vertical resistance must be below 10<sup>8</sup> Ω.
                </p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='en_16350'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
