<template>
    <div class="d-flex flex-row">

        <!-- <icon-custom-logo :product="product"></icon-custom-logo> -->
        <icon-ansi-abrasion :product="product"></icon-ansi-abrasion>
        <icon-ansi-class :product="product"></icon-ansi-class>
        <icon-ansi-contact-heat :product="product"></icon-ansi-contact-heat>
        <icon-ansi-impact :product="product"></icon-ansi-impact>
        <icon-ansi-puncture :product="product"></icon-ansi-puncture>
        <icon-chemical-permeation :product="product"></icon-chemical-permeation>
        <icon-cut-level :product="product"></icon-cut-level>
        <icon-cut-resistant-clothing :product="product"></icon-cut-resistant-clothing>
        <icon-en388 :product="product"></icon-en388>
        <icon-en-374-5 :product="product"></icon-en-374-5>
        <icon-en-407 :product="product"></icon-en-407>
        <icon-en-511 :product="product"></icon-en-511>
        <icon-en-16350 :product="product"></icon-en-16350>
        <icon-esd-safe :product="product"></icon-esd-safe>
        <icon-flame-resistant :product="product"></icon-flame-resistant>
        <icon-hrc-level :product="product"></icon-hrc-level>
        <icon-prop-65 :product="product"></icon-prop-65>
        <icon-self-extinguishing :product="product"></icon-self-extinguishing>
        <icon-sustainability :product="product"></icon-sustainability>
        <icon-touchscreen-compatible :product="product"></icon-touchscreen-compatible>
        <icon-video :product="product"></icon-video>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {

    },
    created(){
    },

}
</script>
