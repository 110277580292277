<template>
    <div class="action-buttons">
        <button type="button" class="btn btn-danger"  data-toggle="modal" data-target="#archive"><i class="fas fa-times"></i></button>
        <modal id="archive" title="Archive" @confirmFunction="archive()">
            Do you want to archive the user?
        </modal>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: "ArchiveUserButton",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    methods:{
        archive(){
            this.$store.dispatch("archiveUser",this.data);

            $("#archive").modal('hide');

        },
    }
};
</script>
