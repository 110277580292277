<template>
    <div>
        <button  class="btn btn-success" @click.prevent="approve()"><i class="fas fa-check"></i></button>
        <button type="button"  class="btn btn-danger" data-toggle="modal"  data-target="#archive"><i class="fas fa-times"></i></button>

    <modal id="archive" title="Archive" @confirmFunction="archive()"> Do you want to archive the user?</modal>
    </div>
</template>
<script>
export default {
    name: "ApproveActionButton",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    methods:{
        archive(){
            this.$store.dispatch("archiveUser",this.data);
            $("#archive").modal('hide');

        },
        approve(){
            this.$store.dispatch("approveUser",this.data);
        },
    }
};
</script>
