var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 sidebar"},[_c('div',{staticClass:"font-weight-normal pb-3"},[(_vm.user.role == 'admin')?_c('div',[_c('h1',[_vm._v("Edit User")])]):_c('div',[_c('h1',[_vm._v("Account Details")])])]),_vm._v(" "),_c('div',{staticClass:"lines d-none d-sm-none d-lg-block"})]),_vm._v(" "),_c('div',{staticClass:"col-md-10"},[(_vm.user)?_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('form',{staticClass:"mb-5"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Name:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.name),expression:"user.name"}],staticClass:"form-control",class:[{ 'is-invalid': _vm.errorFor('user.name') }],attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.user.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "name", $event.target.value)}}}),_vm._v(" "),_c('v-errors',{attrs:{"errors":_vm.errorFor('name')}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"company"}},[_vm._v("Company:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.company),expression:"user.company"}],staticClass:"form-control",class:[
                            { 'is-invalid': _vm.errorFor('user.company') }
                        ],attrs:{"type":"text","name":"company"},domProps:{"value":(_vm.user.company)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "company", $event.target.value)}}}),_vm._v(" "),_c('v-errors',{attrs:{"errors":_vm.errorFor('company')}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Title:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.title),expression:"user.title"}],staticClass:"form-control",class:[{ 'is-invalid': _vm.errorFor('user.title') }],attrs:{"type":"text","name":"title"},domProps:{"value":(_vm.user.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "title", $event.target.value)}}}),_vm._v(" "),_c('v-errors',{attrs:{"errors":_vm.errorFor('title')}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.role),expression:"user.role"}],staticClass:"form-control",attrs:{"type":"hidden","name":"role"},domProps:{"value":(_vm.user.role)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "role", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"address"}},[_vm._v("Address:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.address),expression:"user.address"}],staticClass:"form-control",class:[
                            { 'is-invalid': _vm.errorFor('user.address') }
                        ],attrs:{"type":"text","name":"address"},domProps:{"value":(_vm.user.address)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "address", $event.target.value)}}}),_vm._v(" "),_c('v-errors',{attrs:{"errors":_vm.errorFor('address')}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"address2"}},[_vm._v("Address 2:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.address2),expression:"user.address2"}],staticClass:"form-control",class:[
                            { 'is-invalid': _vm.errorFor('user.address2') }
                        ],attrs:{"type":"text","name":"address2"},domProps:{"value":(_vm.user.address2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "address2", $event.target.value)}}}),_vm._v(" "),_c('v-errors',{attrs:{"errors":_vm.errorFor('address2')}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"city"}},[_vm._v("City:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.city),expression:"user.city"}],staticClass:"form-control",class:[{ 'is-invalid': _vm.errorFor('user.city') }],attrs:{"type":"text","name":"city"},domProps:{"value":(_vm.user.city)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "city", $event.target.value)}}}),_vm._v(" "),_c('v-errors',{attrs:{"errors":_vm.errorFor('city')}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"state"}},[_vm._v("State:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.state),expression:"user.state"}],staticClass:"form-control",class:[{ 'is-invalid': _vm.errorFor('user.state') }],attrs:{"type":"text","name":"state"},domProps:{"value":(_vm.user.state)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "state", $event.target.value)}}}),_vm._v(" "),_c('v-errors',{attrs:{"errors":_vm.errorFor('state')}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"zipcode"}},[_vm._v("Zipcode:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.zipcode),expression:"user.zipcode"}],staticClass:"form-control",class:[
                            { 'is-invalid': _vm.errorFor('user.zipcode') }
                        ],attrs:{"type":"text","name":"zipcode"},domProps:{"value":(_vm.user.zipcode)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "zipcode", $event.target.value)}}}),_vm._v(" "),_c('v-errors',{attrs:{"errors":_vm.errorFor('zipcode')}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",class:[{ 'is-invalid': _vm.errorFor('user.email') }],attrs:{"type":"email","name":"email","disabled":""},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "email", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"form-text text-muted"},[_vm._v("You cannot edit the email.")]),_vm._v(" "),_c('v-errors',{attrs:{"errors":_vm.errorFor('email')}})],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Phone Number:")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.phone),expression:"user.phone"}],staticClass:"form-control",class:[{ 'is-invalid': _vm.errorFor('user.phone') }],attrs:{"type":"text","name":"phone"},domProps:{"value":(_vm.user.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "phone", $event.target.value)}}}),_vm._v(" "),_c('v-errors',{attrs:{"errors":_vm.errorFor('phone')}})],1),_vm._v(" "),_c('button',{staticClass:"btn btn-success mr-3",attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.saveUser.apply(null, arguments)}}},[_vm._v("\n                    SAVE\n                ")])]),_vm._v(" "),(_vm.success)?_c('success',[_vm._v("\n                Your data has been updated !!!\n            ")]):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }