<template>

    <div v-if="product.fr_treated"  class="product-icon-tooltip">
        <div  class="prod-icon icon-tall">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p>
                    <strong>SELF-EXTINGUISHING (SE)</strong>
                </p>
                <p>
                    Self Extinguishing garments are made from material that is not inherently flame retardant, but are chemically treated to become flame resistant. They are NOT designed for use in ARC flash or flash fire applications. When a flame source is removed from the treated material, the material will self-extinguish within a given time frame. The treated material can melt and drip and has a limited life span dependent on washings and what the garment is exposed to. Performance is established using the ASTM D6413 test method.
                </p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='self-extinguishing'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
