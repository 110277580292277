<template>
    <div>
        <div class="row no-gutters literature-header" >
            <h1 class="text-light" style="position: absolute; bottom: 2rem; left: 3rem;">
                Literature
            </h1>
        </div>
        <div class="row no-gutters p-5">
            <div class="col-md-12 mb-5">
                <h2 class="text-uppercase">
                    product catalogs
                </h2>
                <div id="literature-container" class="d-flex flex-wrap mt-4">
                    <div
                        class="literature d-flex flex-column mb-2"
                        v-for="literature in catalogs"
                        :key="'lit' + literature.id"
                    >
                        <router-link
                            :to="'/images/catalogs/' + literature.path"
                            target="_blank"
                        >
                            <img
                                :src="
                                    '/images/catalogs/' + literature.image_path
                                "
                                alt=""
                                class="mb-3"
                            />
                        </router-link>
                        <router-link
                            :to="'/images/catalogs/' + literature.path"
                            target="_blank"
                            class="btn btn-primary"
                            >DOWNLOAD</router-link
                        >
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            catalogs: null,
            flyers: null,
            charts: null
        };
    },
    created() {
        axios.get(`/api/catalogs`).then(response => {
            this.catalogs = _.orderBy(response.data,"id");
        });


    }
};
</script>

<style scoped lang="scss">
h1 {
    font-size: 3.5rem;
}
h2 {

    font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
}
.literature {
    max-width: 280px;
    margin-right: 1.5em;
    transition: all 0.4s ease-out;
    img {
        max-width: 100%;
    }
}
.literature-header{
    background-image: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url('/images/Literature.jpg') ;
    background-position:top bottom ;
    background-repeat:no-repeat; background-size:cover; height: 60vh; position: relative;

    @media screen and (max-width: 786px) {
         height:40vh;
         h1{
             font-size:2.5rem;
         }
    }
}
</style>
