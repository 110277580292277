export default {
    en: "USA-English",
    caen: "CANADA-English",
    cafr: "CANADA-French",
    mx: "MEXICO-Espaňol",
    euen: "EUROPE-English",
    eues: "EUROPE-Espaňol",
    eufr: "EUROPE-Français",
    euit: "EUROPE-Italiano",
    ch: "CHINA-简体中文",
    ap: "ASIA PACIFIC-English",
    au: "AUSTALIA-English",
    ex: "EXPORT-English"
  }