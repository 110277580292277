<template>

    <div v-if="product.prop_65"  class="product-icon-tooltip">
        <div  class="prod-icon icon-square">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p>
                    <strong>California Proposition 65</strong>
                </p>
                <p>
                    WARNING: {{ product.prop_65 }} - <a href="//www.P65Warnings.ca.gov" target="_blank">www.P65Warnings.ca.gov</a>
                </p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='prop-65'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
