<template>
<div class="row">
    <div class="col-md-2 sidebar">
        <h1 class="font-weight-normal pb-3">User List</h1>
        <div class="lines d-sm-none d-none d-lg-block"></div>
    </div>
    <div class="dashboard-user col-md-10">

        <div v-if="loading">Data Loading...........</div>

        <div v-else>
            <transition name="fade">
                <success v-if="success">
                    {{message}}
                </success>
                <fatal-error v-if="error">
                    {{message}}
                </fatal-error>
            </transition>
            <br>
            <div v-if="loading">
                Data Loading........
            </div>
            <div v-else>
            <b-tabs content-class="mt-3">
                <b-tab title="All Users" active @click="displayAllUsers">
                    <div>
                        <div class="d-flex justify-content-between">
                            <h4>All Users</h4>
                        </div>

                        <div class="table-responsive">
                            <data-table v-bind="parametersAllUsersTable"/>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Verify New User" @click="verifyUserList">
                    <div>
                        <div class="d-flex justify-content-between">
                            <h4>Verify New User</h4>
                        </div>
                        <div class="table-responsive">
                            <data-table v-bind="parametersVerifyUsersTable"/>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Archive" @click="archiveUserList">
                    <div>
                        <div class="d-flex justify-content-between">
                            <h4>Archive</h4>
                        </div>

                        <div class="table-responsive">
                            <data-table v-bind="parametersArchiveUsersTable"/>
                        </div>
                    </div>
                </b-tab>
                <!-- <template #tabs-end>
                    <li role="presentation" class="nav-item align-self-center ml-auto  ">
                        <form class="mb-2 d-flex align-items-start">
                            <input type="text"  v-model="searchUser" name="searchUser">
                            <button class="btn btn-primary btn-search" type="button" @click.prevent="findUser()"> Search</button>
                        </form>
                    </li>
                </template> -->
            </b-tabs>
            </div>




        </div>

    </div>
</div>
</template>

<script>
    import { mapState } from "vuex";
    import EditUserButton from './EditUserButton';
    import ApproveActionButton from './ApproveActionButton';
    import ArchiveUserButton from './ArchiveUserButton';
    import RestoreUserButton from './RestoreUserButton';
    import UserFormRole from './UserFormRole';
    export default{

        data(){
            return{
                loading:false,
                allUser:true,
                searchUser:null,
                loading:true,
            }
        },
        created(){

            var self=this;
                setTimeout(function(){
                    self.loading=false;
                }, 2500);
            this.loading=true;
            this.$store.dispatch("loadUsersList");

        },
        computed: {
            ...mapState([
                "message",
                "success",
                "error",
                "filteredUsersList",
                "usersList"

            ]),
            parametersAllUsersTable() {
                return {
                    data: this.filteredUsersList,
                    actionMode: "single",
                    perPageSizes: [5, 10, 25, 50, 100],
                    defaultPerPage: 5,
                    text:{
                        searchText: "Search: ",
                        downloadText: "Export as: "
                    },
                    showDownloadButton:false,

                    columns: [
                        {
                            key:"id",
                            title:"ID"
                        },
                        {
                            key:"name",
                            title:"Name"
                        },
                        {
                            key:"company",
                            title:"Company"
                        },
                        {
                            key:"title",
                            title:"Title"
                        },
                        {
                            key:"role",
                            title:"Role"
                        },
                        {
                            key:"email",
                            title:"Email Address"
                        },
                        {
                            title:"Edit",
                            component: EditUserButton

                        },
                        {
                            title:"Delete",
                            component: ArchiveUserButton
                        }
                    ]

                };
            },
            parametersVerifyUsersTable() {
                return {
                    data: this.filteredUsersList,
                    actionMode: "single",
                    perPageSizes: [5, 10, 25, 50, 100],
                    defaultPerPage: 5,
                    text:{
                        searchText: "Search: ",
                        downloadText: "Export as: "
                    },
                    showDownloadButton:false,
                    columns: [
                        {
                            key:"id",
                            title:"ID"
                        },
                        {
                            key:"name",
                            title:"Name"
                        },
                        {
                            key:"company",
                            title:"Company"
                        },
                        {
                            key:"title",
                            title:"Title"
                        },
                        {

                            title:"Role",
                            component:UserFormRole
                        },
                        {
                            key:"email",
                            title:"Email Address"
                        },
                        {
                            title:"Approve",
                            component: ApproveActionButton
                        }
                    ]

                };
            },
            parametersArchiveUsersTable() {
                return {
                    data: this.filteredUsersList,
                    actionMode: "single",
                    perPageSizes: [5, 10, 25, 50, 100],
                    defaultPerPage: 5,
                    text:{
                        searchText: "Search: ",
                        downloadText: "Export as: "
                    },
                    showDownloadButton:false,
                    columns: [
                        {
                            key:"id",
                            title:"ID"
                        },
                        {
                            key:"name",
                            title:"Name"
                        },
                        {
                            key:"company",
                            title:"Company"
                        },
                        {
                            key:"title",
                            title:"Title"
                        },
                        {
                            title:"Role",
                            component:UserFormRole
                        },
                        {
                            key:"email",
                            title:"Email Address"
                        },
                        {
                            title:"Restore",
                            component: RestoreUserButton
                        }
                    ]

                };
            },

        },
        methods:{
            verifyUserList(){
                const filteredList= this.usersList.filter(user=>{
                    return user.role.toLowerCase()=='customer' && user.archive==0;
                });
                this.$store.commit("setFilteredUsersList", filteredList);
            },
            archiveUserList(){
                const filteredList= this.usersList.filter(user=>{
                    return user.archive==1;
                });
                this.$store.commit("setFilteredUsersList", filteredList);
            },
            displayAllUsers(){
                this.$store.dispatch("loadUsersList");
            }

        }
    }
</script>
