require('./bootstrap');
// import {alias} from 'laravel-mix';
import router from './routes';
import VueRouter from 'vue-router';
import Vue from 'vue';
import i18n from "./i18n";
var VueScrollTo = require('vue-scrollto');

import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import JwPagination from 'jw-vue-pagination';
import Vuex, { mapState } from 'vuex';
import storeDefinition from "./store";
import Index from './Index';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';
import VueSlickCarousel from 'vue-slick-carousel';
import VSwitch from 'v-switch-case';
Vue.use(VSwitch);

import DataTable from "@andresouzaabreu/vue-data-table";
Vue.component("data-table", DataTable);
import "@andresouzaabreu/vue-data-table/dist/DataTable.css";

// importing vue loaders
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';

Vue.use(VueLoaders);

window.Vue = require('vue').default;
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueScrollTo, {
    container: "body",
     duration: 500,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
});


Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.config.productionTip = false;

const store = new Vuex.Store(storeDefinition,{

    plugins: [
        createPersistedState({
          getState: (key) => Cookies.getJSON(key),
          setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: true })
        })
      ]
});
//Global component registrations
Vue.component('main-nav', require('./components/nav/MainNav.vue').default);
Vue.component('mobile-nav', require('./components/nav/MobileNav.vue').default);
Vue.component('local-switcher', require('./components/nav/LocaleSwitcher.vue').default);
Vue.component('home', require('./components/pages/Home.vue').default);
Vue.component('main-footer', require('./components/Footer.vue').default);
Vue.component('hero-slider', require('./components/HeroSlider.vue').default);
Vue.component('slide', require('./components/shared/Slide.vue').default);
Vue.component('category', require('./components/pages/Category.vue').default);
Vue.component('product-list', require('./components/product/ProductList.vue').default);
Vue.component('product-header', require('./components/product/ProductListHeader.vue').default);
Vue.component('product-list-item', require('./components/product/ProductListItem.vue').default);
Vue.component('smart-filters-list', require('./components/filters/SmartFiltersList.vue').default);
Vue.component('smart-filter', require('./components/filters/SmartFilter.vue').default);
Vue.component('product', require('./components/product/Product.vue').default);
Vue.component('jw-pagination', JwPagination);
Vue.component('contact', require('./components/pages/Contact.vue').default);
Vue.component('v-errors', require('./components/shared/ValidationErrors.vue').default);
Vue.component('success', require('./components/shared/Success.vue').default);
Vue.component('fatal-error', require('./components/shared/FatalError.vue').default);
Vue.component('modal', require('./components/shared/Modal.vue').default);
Vue.component('search', require('./components/filters/Search.vue').default);
Vue.component('back-to-top', require('./components/shared/BackToTop.vue').default);
Vue.component('privacy', require('./components/pages/PrivacyPolicy.vue').default);
Vue.component('product-filter-list', require('./components/product/ProductFilterList.vue').default);
Vue.component('product-icons', require('./components/product/ProductIcons.vue').default);

// product icon component list
Vue.component('icon-ansi-abrasion', require('./components/product-icons/ansi-abrasion.vue').default);
Vue.component('icon-ansi-class', require('./components/product-icons/ansi-class.vue').default);
Vue.component('icon-ansi-contact-heat', require('./components/product-icons/ansi-contact-heat.vue').default);
Vue.component('icon-ansi-impact', require('./components/product-icons/ansi-impact.vue').default);
Vue.component('icon-ansi-puncture', require('./components/product-icons/ansi-puncture.vue').default);
Vue.component('icon-chemical-permeation', require('./components/product-icons/chemical-permeation.vue').default);
Vue.component('icon-custom-logo', require('./components/product-icons/custom-logo.vue').default);
Vue.component('icon-cut-level', require('./components/product-icons/ansi-cut-9.vue').default);
Vue.component('icon-cut-resistant-clothing', require('./components/product-icons/cut-resistant-clothing.vue').default);
Vue.component('icon-en388', require('./components/product-icons/en388.vue').default);
Vue.component('icon-en-374-5', require('./components/product-icons/en-374-5.vue').default);
Vue.component('icon-en-407', require('./components/product-icons/en-407.vue').default);
Vue.component('icon-en-511', require('./components/product-icons/en-511.vue').default);
Vue.component('icon-en-16350', require('./components/product-icons/en-16350.vue').default);
Vue.component('icon-esd-safe', require('./components/product-icons/esd-safe.vue').default);
Vue.component('icon-flame-resistant', require('./components/product-icons/flame-resistant.vue').default);
Vue.component('icon-hrc-level', require('./components/product-icons/hrc-level.vue').default);
Vue.component('icon-prop-65', require('./components/product-icons/prop-65.vue').default);
Vue.component('icon-self-extinguishing', require('./components/product-icons/self-extinguishing.vue').default);
Vue.component('icon-sustainability', require('./components/product-icons/sustainability.vue').default);
Vue.component('icon-touchscreen-compatible', require('./components/product-icons/touchscreen-compatible.vue').default);
Vue.component('icon-video', require('./components/product-icons/video.vue').default);

// dynamic spec sheet
Vue.component('specsheet-header', require('./components/spec-sheet/Header.vue').default);
Vue.component('specsheet-footer', require('./components/spec-sheet/Footer.vue').default);
Vue.component('specsheet-prodimage', require('./components/spec-sheet/ProdImage.vue').default);
Vue.component('specsheet-description', require('./components/spec-sheet/ShortDescription.vue').default);
Vue.component('specsheet-features', require('./components/spec-sheet/Features.vue').default);
Vue.component('specsheet-applications', require('./components/spec-sheet/Applications.vue').default);

Vue.component('specsheet-techdata', require('./components/spec-sheet/TechnicalData.vue').default);
Vue.component('specsheet-performdata', require('./components/spec-sheet/PerformanceData.vue').default);
Vue.component('specsheet-careinstruct', require('./components/spec-sheet/CareInstruction.vue').default);
Vue.component('specsheet-upc', require('./components/spec-sheet/UPC.vue').default);

// interceptor is like a plugin,
window.axios.interceptors.response.use(
    // it is always called when you get a response for anything, like a global thing
    response=>{
        return response;
    },
    error=>{
        if(401==error.response.status){
            store.dispatch("logout");
        }

        // line to add to continue working
        return Promise.reject(error);
    }
);

Vue.directive('menuMatch', (el, binding)=> {
    
    Vue.nextTick(()=>{
        // //Set highest to the Parent UL list count
        var maxListCount = el.children.length;
        let siblings = el.parentNode.querySelectorAll('ul');
        
        for (var i = 0; i < siblings.length; i++) {
            //loop through our nested ul's compare their list lengths
            var liCount = siblings[i].querySelectorAll('li').length;
            if (liCount > maxListCount) {
                maxListCount = liCount;
            }
        }
        
        //Shouldn't hardcode, but you can't access the height when its hidden and not rendered, so, hardcode!
        var liHeight = "34";

        var newPadding = 0;
        var paddingString = "";

        //Now that we have established our maxListCount, update our ULs accordingly
        if (el.children.length != maxListCount) {

            newPadding = ((maxListCount - el.children.length) * liHeight);
            paddingString = newPadding + "px";
            el.parentNode.style.setProperty('padding-bottom' , paddingString);
        }

        for (var n = 0; n < siblings.length; n++) {
            var siblingListLength = siblings[n].querySelectorAll('li').length;
            if (siblingListLength != maxListCount){
                newPadding = ((maxListCount - siblingListLength) * liHeight);
                paddingString = newPadding + "px";
                siblings[n].style.setProperty('padding-bottom' , paddingString);
            }
        }
    });

})


const app = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    components: {
        'index': Index,
    },
    created() {
        this.$store.dispatch('loadIcons');
        this.$store.dispatch('loadStoredState');
        if(this.$store.state.useProducts) {
            this.$store.dispatch('loadCategories');
        }
    },
});

