<template>
    <div v-if="careList || careTxt">
        <p style="margin:1rem 0 0.5rem 0;">
            <span style="font-size:24px; line-height:28px; font-weight:500;" >Care Instructions</span>
        </p>

        <div >
            <ul id="ssCareIcons" style="font-size: 8px; line-height:9px;" v-if="careList" v-html="careList"></ul>
            <p id="ssCareTxt" class="tiny5" v-if="careTxt" v-html="careTxt"></p>
        </div>
    </div>
</template>
<style lang="scss" scoped>

#ssCareIcons{
    padding:0;
    margin:0;
    list-style:none;
    display:flex;
    flex-direction:row;

}
</style>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
            careList:"",
            careTxt:"",
            careWash_Machine:0,
            careWash_Hand:0,
            careWash_No:0,
            careTemp_40_10:0,
            careTemp_55_130:0,
            careTemp_60_140:0,
            careBleach_Yes:0,
            careBleach_No:0,
            careDry_Tumble:0,
            careDry_Low:0,
            careDry_Med:0,
            careDry_Hang:0,
            careDry_Clean:0,
            careDry_CleanNo:0,
            careWashCycles_25:0,
            careWashCycles_50:0,
        }

    },
    created() {

        // var this.careWash_Machine=0;
        // let this.careWash_Hand=0;
        // let this.careWash_No=0;
        // let this.careTemp_40_105=0;
        // let this.careTemp_55_130=0;
        // let this.careTemp_60_140=0;
        // let this.careBleach_Yes=0;
        // let this.careBleach_No=0;
        // let this.careDry_Tumble=0;
        // let this.careDry_Low=0;
        // let this.careDry_Med=0;
        // let this.careDry_Hang=0;
        // let this.careDry_Clean=0;
        // let this.careDry_CleanNo=0;
        // let this.careWashCycles_25=0;
        // let this.careWashCycles_50=0;
        let liner_material="";
        let material="";
        let fabric="";
        let plugMaterial="";


        const unwatch=this.$watch('product',()=>{
            this.initializeVariable();

            (this.product.liner_material != null)? liner_material=this.product.liner_material : liner_material="";
            (this.product.fabric != null)? fabric=this.product.fabric: fabric="";
            (this.product.material != null)? material=this.product.material : material="";

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //ICON FILTER: LINER_MATERIAL, FABRIC /////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

            //ACP
            let careACP = liner_material.indexOf("--ACP--");
            if (careACP !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
                this.careDry_Low = 1;
                if (this.product.coating == "Uncoated") {
                    this.careTemp_55_130 = 1;
                } else {
                    this.careTemp_40_105 = 1;
                }
            }


            //ATA Fiber Technology
            let careATA = liner_material.indexOf("--ATA Fiber Technology--");
            if (careATA !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
                this.careDry_Low = 1;
                if (this.product.coating == "Uncoated") {
                this.careTemp_55_130 = 1;
                } else {
                this.careTemp_40_105 = 1;
                }
            }

            //Canvas
            let careCanvas = liner_material.indexOf("--Canvas--");
            let careCanvasMaterial = material.indexOf( "--Canvas--");
            if (careCanvas !== -1 || careCanvasMaterial !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careTemp_40_105 = 1;
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
                this.careDry_Low = 1;
            }

            //Cotton or Terry Cloth
            let careCotton = liner_material.indexOf("--Cotton--");
            let careTerryCloth = liner_material.indexOf("--Terry Cloth--");
            let careCottonFabric = fabric.indexOf( "--Cotton--");
            if (careCotton !== -1 || careCottonFabric !== -1 || careTerryCloth !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                if (careCotton !== -1 || careTerryCloth !== -1) {
                    this.careTemp_40_105 = 1;
                }
                if (careCottonFabric !== -1) {
                    this.careTemp_60_140 = 0;
                }
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
                this.careDry_Low = 1;
                if (this.product.fr == "1") {
                    this.careWashCycles_50 = 1;
                }
            }

            //Denim
            let careDenim = liner_material.indexOf("--Denim--");
            let careDenimFabric = fabric.indexOf( "--Denim--");
            if (careDenim !== -1 || careDenimFabric !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careTemp_40_105 = 1;
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
                this.careDry_Low = 1;
            }

            //Dyneema //HPPE
            let careDyneema = liner_material.indexOf("--Dyneema--");
            let careHPPE = liner_material.indexOf("--HPPE--");
            if (careDyneema !== -1 || careHPPE !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careBleach_Yes = 1;
                this.careDry_Tumble = 1;
                this.careDry_Low = 1;
                if (this.product.coating == "Uncoated") {
                    this.careTemp_55_130 = 1;
                } else {
                    this.careTemp_40_105 = 1;
                }
            }



            //Dyneema Diamond Technology
            let careDyneemaDiamondTechnology = liner_material.indexOf("--Dyneema Diamond Technology--");
            if (careDyneemaDiamondTechnology !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
                this.careDry_Low = 1;
                if (this.product.coating == "Uncoated") {
                    this.careTemp_55_130 = 1;
                } else {
                    this.careTemp_40_105 = 1;
                }
            }

            //Kevlar //Aramid
            let careKevlar = liner_material.indexOf("--Kevlar--");
            let careAramid = liner_material.indexOf("--Aramid--");
            let careKevlarFabric = fabric.indexOf( "--Kevlar--");
            if (careKevlar !== -1 || careAramid !== -1 || careKevlarFabric !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
                if (this.product.coating == "Uncoated") {
                this.careTemp_55_130 = 1;
                this.careDry_Med = 1;
                } else {
                this.careTemp_40_105 = 1;
                this.careDry_Low = 1;
                }
                let careTwillFabric = fabric.indexOf( "--Twill--");
                if (careTwillFabric !== -1) {
                    this.careDry_Clean = 1;
                }
            }

            //Neoprene
            let careNeopreneFabric = fabric.indexOf( "--Neoprene--");
            if (careNeopreneFabric !== -1) {
                this.initializeVariable();
                this.careWash_Hand = 1;
                this.careBleach_No = 1;
                this.careDry_Hang = 1;
            }

            //Nitrile
            let careNitrileFabric = fabric.indexOf( "--Nitrile--");
            if (careNitrileFabric !== -1) {
                this.initializeVariable();
                this.careWash_Hand = 1;
                this.careBleach_No = 1;
                this.careDry_Hang = 1;
            }

            //Modacrylic
            let careModacrylicFabric = fabric.indexOf( "--Modacrylic--");
            if (careModacrylicFabric !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careTemp_55_130 = 1;
                this.careBleach_No = 1;
                this.careDry_Hang = 1;
                this.careWashCycles_50 = 1;
            }

            //Neoprene
            careNeopreneFabric = fabric.indexOf( "--Neoprene--");
            if (careNeopreneFabric !== -1) {
                this.initializeVariable();
                this.careWash_No = 1;
            }

            //Nylon
            let careNylon = liner_material.indexOf("--Nylon--");
            let careNylonFabric = fabric.indexOf( "--Nylon--");
            let careNylonMaterial = material.indexOf( "--Nylon--");
            if (careNylon !== -1 || careNylonFabric !== -1 || careNylonMaterial !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
                if (careNylon !== -1 || careNylonMaterial !== -1) {
                    this.careTemp_40_105 = 1;
                    this.careDry_Low = 1;
                }
                if (careNylonFabric !== -1) {
                    this.careTemp_60_140 = 1;
                    this.careDry_Med = 1;
                }
                if (this.product.polymer == "Silicone") {
                    this.careDry_Tumble=0;
                    this.careDry_Low=0;
                    this.careDry_Hang = 1;
                }
            }

            //Polyester
            let carePolyester = liner_material.indexOf("--Polyester--");
            let carePolyesterFabric = fabric.indexOf( "--Polyester--");
            let careMeshFabric = fabric.indexOf( "--Mesh--");
            let careFleeceFabric = fabric.indexOf( "--Fleece--");
            if (carePolyester !== -1 || carePolyesterFabric !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careTemp_40_105 = 1;
                this.careBleach_No = 1;
                if (careFleeceFabric !== -1 || this.product.fr_treated) {
                    this.careDry_Hang = 1;
                    this.careDry_CleanNo = 1;
                } else {
                    this.careDry_Tumble = 1;
                    this.careDry_Low = 1;
                }
                if (careMeshFabric !== -1 || careFleeceFabric !== -1 || this.product.fr_treated) {
                    this.careWashCycles_25 = 1;
                }
            }

            //Polyethylene
            let carePolyethyleneFabric = fabric.indexOf( "--Polyethylene--");
            if (carePolyethyleneFabric !== -1) {
                this.initializeVariable();
                this.careWash_No = 1;
            }

            //PolyKor$carePolyKorX7
            let carePolyKor = liner_material.indexOf("--PolyKor--");
            let carePolyKorX7 = liner_material.indexOf("--PolyKor X7--");
            if (carePolyKor !== -1 || carePolyKorX7 !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
                if (this.product.coating == "Uncoated") {
                    this.careTemp_55_130 = 1;
                    this.careDry_Med = 1;
                } else {
                    this.careTemp_40_105 = 1;
                    this.careDry_Low = 1;
                }
            }

            //Pritex
            let carePritex = liner_material.indexOf("--Pritex--");
            if (carePritex !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careTemp_55_130 = 1;
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
            }
            if (carePritex !== -1 && careDyneema !== -1) {
                this.careDry_Low = 1;
            }
            if (carePritex !== -1 && careHPPE !== -1) {
                this.careDry_Low = 1;
            }
            if (carePritex !== -1 && careKevlar !== -1) {
                this.careDry_Med = 1;
            }

            //Recycled Plastic
            let careRecycledPlastic = liner_material.indexOf("--Recycled Plastic--");
            if (careRecycledPlastic !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
                this.careTemp_40_105 = 1;
                this.careDry_Low = 1;
            }

            //Ripstop
            let carevFabric = fabric.indexOf( "--Ripstop--");
            if (carevFabric !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careTemp_40_105 = 1;
                this.careBleach_No = 1;
                this.careDry_Hang = 1;
                this.careDry_Clean = 1;
                this.careWashCycles_25 = 1;
            }

            //Sateen
            let careSatinFabric = fabric.indexOf( "--Sateen--");
            if (careSatinFabric !== -1) {
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careTemp_60_140 = 1;
                this.careBleach_No = 1;
                this.careDry_Tumble = 1;
                this.careDry_Med = 1;
                this.careDry_Clean = 1;
            }

            //Vinyl
            let careVinylFabric = fabric.indexOf( "--Vinyl--");
            if (careVinylFabric !== -1) {
                this.initializeVariable();
                this.careWash_Hand = 1;
                this.careDry_Hang = 1;
            }


            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //ICON FILTER: COMBINATIONS ///////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            if (careCottonFabric !== -1 && carePolyesterFabric) {
                this.initializeVariable();
                this.careWash_Hand = 1;
                this.careBleach_No = 1;
                this.careDry_Hang = 1;
            }


            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //ICON FILTER: SELECT CODE ////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            switch (this.product.category.select_code_id) {

                //Foot Protection
                case "2586":
                this.initializeVariable();
                this.careWash_Hand = 1;
                break;
            } //switch($WEBrow["select_code_id"])


            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //ICON FILTER: COMMODITY CODE /////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

            switch (this.product.category.commodity_code_id) {
                //Arc Clothing
                case "11642":
                    this.initializeVariable();
                    this.careWash_Machine = 1;
                    this.careTemp_60_140 = 1;
                    this.careBleach_No = 1;
                    this.careDry_Tumble = 1;
                    this.careDry_Low = 1;
                break;

                //Bump Caps
                case "11576":
                this.initializeVariable();
                    if (this.product.ansi_type) {
                        this.careTxt = "The liner may be cleaned with soap and warm water and dried with a soft cloth. Chemical or abrasive cleaners should NOT be used. The standard cover can be brushed clean and is hand washable. Should be stored out of direct sunlight, away from chemicals and not exposed to extremes of temperature.";
                    } //if (!empty(this.product.ansi_type"]))
                break;

                //Cut Resistant Clothing
                case "11654":
                    this.initializeVariable();
                    this.careWash_Machine = 1;
                    this.careTemp_40_105 = 1;
                    this.careBleach_No = 1;
                    this.careDry_Tumble = 1;
                    this.careDry_Low = 1;
                break;

                //Chemical Resistant Gloves
                case "11559":
                    this.initializeVariable();
                    this.careWash_No = 1;
                break;

                //Disposable Clothing
                case "11561":
                    this.initializeVariable();
                    this.careWash_No = 1;
                break;

                //Disposable Gloves
                case "11547":
                    this.initializeVariable();
                    this.careWash_No = 1;
                break;

                //Extended Use Disposable Gloves
                case "11731":
                    this.initializeVariable();
                    this.careWash_No = 1;
                break;


                //Ear Plugs
                case "11598":
                    this.initializeVariable();
                    plugMaterial = this.product.plug_material.indexOf("Thermoplastic");
                    if (plugMaterial !== -1) {
                        this.careTxt = "After use, clean and disinfect the plugs with mild soap and warm water. Let plugs dry before reuse. The ear plugs should be regularly inspected for serviceability.";
                    } //if ($plugMaterial !== -1)
                    else {
                        this.careTxt = "These ear plugs are disposable, but when not in use should be kept in clean, dry condition.";
                    }
                break;

                //Ear Muffs
                case "11574":
                this.initializeVariable();
                    this.careTxt = "Routinely wash with mild soap and water. Allow cushions to air dry.";
                break;

                //Electrical Glove Protection
                case "11624":
                this.initializeVariable();
                this.careWash_No = 1;
                break;

                //Face Protection
                case "11694":
                this.initializeVariable();
                this.careWash_Machine = 1;
                this.careTemp_40_105 = 1;
                this.careDry_Tumble = 1;
                this.careDry_Low = 1;
                this.careDry_Hang = 1;
                break;

                //Hard Hats
                case "11575":
                this.initializeVariable();
                    if (this.product.ansi_type) {
                        this.careTxt = "The unit may be cleaned with soap and warm water and dried with a soft cloth. The helmet should not be cleaned with chemical or abrasive cleaners. The helmet should be stored out of direct sunlight, away from chemicals and not exposed to extremes of temperature.";
                    } //if (!empty(this.product.ansi_type"]))
                break;

                //Heated Apparel
                case "11712":
                this.initializeVariable();
                    this.careTxt = "Machine washable. Always store in clean, dry conditions. DO NOT store in places subject to direct, strong sunlight. Please put the vest into the laundry bag before putting it in the wash machine, and air dry the USB before use. ";
                break;

                //High Performance Gloves
                case "11581":
                    this.initializeVariable();
                    if (this.product.palm == "Leather") {
                        this.careWash_No = 1;
                    } else {
                        this.careWash_Machine = 1;
                        this.careTemp_40_105 = 1;
                        this.careBleach_No = 1;
                        this.careDry_Hang = 1;
                    }
                break;

                //Insulating Blankets
                case "11698":
                this.initializeVariable();
                    this.careTxt = "Blankets must always be stored flat in the the original box or a designated storage space. The blanket should never be folded, stored under an object or in the direct rays of sun or where hot air can blow on them. Before storing, make sure the blanket is free of all petroleum-based products and/or foreign substances.";

                break;

                //Insulating Gloves
                case "11620":
                this.initializeVariable();
                    this.careTxt = "Wash in warm water (29°C/85°F) with mild detergent and disinfect with a small amount of chlorine bleach. Air or tumble dry at low heat (74°C/165°F). Gloves may be dusted lightly with talc to reduce tackiness. Gloves should be stored in a glove bag, finger tips up, hung and not laid flat. Leather protecting glove should be removed before storage. Never fold gloves. Store in as dry and cool (35°C/95°F maximum) location as possible, away from sources of ozone and direct sunlight.";

                break;

                //Insulating Sleeves
                case "11670":
                this.initializeVariable();
                    this.careTxt = "Wash either by hand or in a washing machine to remove dirt with mild soap or mild non-bleaching detergent. After washing, rinse sleeves in clean water and dry thoroughly. Drying temperatures can not exceed 66°C/150°F.";

                break;

                //Leather Drivers Gloves
                case "11573":
                this.initializeVariable();
                this.careWash_No = 1;
                break;

                //Leather Palm Gloves
                case "11621":
                    this.initializeVariable();
                    this.careWash_No = 1;
                break;

                //Metal Mesh Gloves
                case "11724":
                    this.initializeVariable();
                    this.careWash_Hand = 1;
                    this.careBleach_No = 1;
                    this.careDry_Hang = 1;
                break;

                //Rainwear
                case "11558":
                    this.initializeVariable();
                    this.careWash_No = 1;
                break;

                //Reusable Clothing
                case "11697":
                this.initializeVariable();
                    this.careTxt = "Make sure all zippers and snaps are closed. Use less harsh soap (milk alkaline or neutral soaps) or detergents. Wash at 120°F for colors and 140°F for whites. Rinse cold. No bleach. Tumble dry moderate (140°F). After drying tumble dry cold for 5 minutes. No iron. Our garments do not come wearer ready- meaning they need to be laundered before an employee wears them in an ISO class cleanroom.  Average wear and tear live span is 50-100 washes.";

                break;

                //Safety Helmets
                case "11738":
                    this.initializeVariable();
                    if (this.product.ansi_type) {
                        this.careTxt = "The unit may be cleaned with soap and warm water and dried with a soft cloth. The helmet should not be cleaned with chemical or abrasive cleaners. The helmet should be stored out of direct sunlight, away from chemicals and not exposed to extremes of temperature.";

                    } //if (!empty(this.product.ansi_type"]))
                break;

                //Warming
                case "11662":
                    this.initializeVariable();
                    this.careTxt = "One time use.";

                break;

            } //switch($WEBrow["commodity_code_id")


            ///UNIVERSAL
            if (this.product.hide) {
                this.initializeVariable();
                this.careWash_No = 1;
            }

            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            //CARE INSTRUCTIONS: ICONS/////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

            if (this.careWash_Machine==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careWash-Machine.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Machine Wash</li>";
            }
            if (this.careWash_Hand==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careWash-Hand.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Hand Wash</li>";
            }
            if (this.careWash_No==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careWash-No.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Do Not Wash</li>";
            }
            if (this.careTemp_40_105==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careTemp-40-105.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Water Temp 40°C</li>";
            }
            if (this.careTemp_55_130==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careTemp-55-130.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Water Temp 55°C</li>";
            }
            if (this.careTemp_60_140==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careTemp-60-140.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Water Temp 60°C</li>";
            }
            if (this.careBleach_Yes==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careBleach-Yes.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Bleach</li>";
            }
            if (this.careBleach_No==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careBleach-No.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Do Not Bleach</li>";
            }
            if (this.careDry_Tumble==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careDry-Tumble.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Tumble Dry</li>";
            }
            if (this.careDry_Low==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careDry-Low.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Low Heat</li>";
            }
            if (this.careDry_Med==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careDry-Med.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Medium Heat</li>";
            }
            if (this.careDry_Hang==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careDry-Hang.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Hang Dry</li>";
            }

            if (this.careDry_Clean==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careDry-Clean.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Dryclean</li>";
            }

            if (this.careDry_CleanNo==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careDry-CleanNo.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Do Not Dryclean</li>";
            }

            if (this.careWashCycles_25==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careWashCycles-25.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Maximum Wash Cycles</li>";
            }

            if (this.careWashCycles_50==1) {
                 this.careList=this.careList+"<li style=\"width: 58px; padding: 5px 8px 0 0; text-align: center;\"><img src=\"/images/care-icons/icon-careWashCycles-50.svg\" style=\"height: 25px; width: auto; margin-bottom: 5px;\" /><br />Maximum Wash Cycles</li>";
            }

            unwatch();
        });


    },
    methods:{

        initializeVariable(){
            this.careWash_Machine=0;
            this.careWash_Hand=0;
            this.careWash_No=0;
            this.careTemp_40_105=0;
            this.careTemp_55_130=0;
            this.careTemp_60_140=0;
            this.careBleach_Yes=0;
            this.careBleach_No=0;
            this.careDry_Tumble=0;
            this.careDry_Low=0;
            this.careDry_Med=0;
            this.careDry_Hang=0;
            this.careDry_Clean=0;
            this.careDry_CleanNo=0;
            this.careWashCycles_25=0;
            this.careWashCycles_50=0;
        }

    },
    // watch:{
    //     prodData: function(newVal){
    //         this.product=newVal;

    //     },

    // }

}
</script>
