<template>
    <div>
        <div v-if="product.ansi_abrasion" class="product-icon-tooltip">
            <div  class="prod-icon icon-square pb-2">
                <i v-html="getIcon[0].svg"></i>
            </div>
            <section class="icon-tooltip">
                <div>
                    <p><span>ANSI Abrasion Level:</span><strong v-html="product.ansi_abrasion"></strong></p>
                    <p v-if="product.coating.toLowerCase()=='uncoated' && product.coating==''">
                        <span>Test Method:</span>
                        <strong>ASTM D3389-09</strong>
                    </p>
                    <p v-else>
                        <span>Test Method:</span>
                        <strong>ASTM D3389-10</strong>
                    </p>
                    <p><span>Cycles:</span><strong v-html="Number(product.ansi_abrasion_cycles).toFixed(2)"></strong></p>
                    <p v-if="product.coating.toLowerCase()=='uncoated' && product.coating==''">
                        The ANSI/ISEA 105-2016 standard outlines test methods for abrasion and is scored from 0-6. The ASTM D3884-09 is used for uncoated gloves and the end point (failure) is the number of abrasion cycles when the first thread or yarn is broken. The larger numbers of cycles indicates greater abrasion resistance of the product and a higher ANSI Abrasion Level.
                    </p>
                    <p v-else>
                        The ANSI/ISEA 105-2016 standard outlines test methods for abrasion and is scored from 0-6. The ASTM D3389-10 test is used for coated gloves and the end point (failure) is the number of abrasion cycles it takes for the coating to wear through. The larger numbers of cycles indicates greater abrasion resistance of the product and a higher ANSI Abrasion Level.
                    </p>
                </div>
            </section>
        </div>

    </div>





</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='ansi-abrasion-'+self.product.ansi_abrasion){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
