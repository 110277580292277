var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"id":"mobile-nav"}},[_c('ul',{attrs:{"id":"nav-list"}},[_c('li',[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{ path: '/' }}},[_vm._v("Home")])],1),_vm._v(" "),(_vm.useProducts)?_c('li',[_c('span',[_vm._v("Products")]),_vm._v(" "),_c('ul',_vm._l((_vm.categories),function(category,index){return _c('li',{key:'scID' + index},[_c('strong',{staticClass:"category-heading"},[_vm._v(_vm._s(category.select_code.title))]),_vm._v(" "),_c('ul',_vm._l((category.commodity_code),function(subCategory,index){return _c('li',{key:'ccID' + index},[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{
                                            name: 'products',
                                            query: {
                                                scID: category.select_code.id,
                                                ccID: subCategory.id
                                            }
                                        }}},[_vm._v(_vm._s(subCategory.title))])],1)}),0)])}),0)]):_vm._e(),_vm._v(" "),_c('li',[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{ path: '/aboutus' }}},[_vm._v("About Us")])],1),_vm._v(" "),_c('li',[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{ path: '/literature' }}},[_vm._v("Literature")])],1),_vm._v(" "),_c('li',[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{ path: '/contact' }}},[_vm._v("Contact Us")])],1),_vm._v(" "),_vm._m(0),_vm._v(" "),(_vm.isLoggedIn)?_c('li',[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{ name: 'dashboard' }}},[_vm._v("User Dashboard")])],1):_vm._e(),_vm._v(" "),(_vm.isLoggedIn)?_c('li',[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{ name: 'myAccount' }}},[_vm._v("My Account")])],1):_vm._e(),_vm._v(" "),(_vm.isLoggedIn)?_c('li',[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{ name: 'changePassword' }}},[_vm._v("Change Password")])],1):_vm._e(),_vm._v(" "),(_vm.isLoggedIn && _vm.user.role == 'admin')?_c('li',[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{ name: 'userList' }}},[_vm._v("Users List")])],1):_vm._e(),_vm._v(" "),(_vm.isLoggedIn && _vm.user.approve == 1)?_c('li',[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{ name: 'myTools' }}},[_vm._v("My Tools")])],1):_vm._e(),_vm._v(" "),(_vm.isLoggedIn)?_c('li',[_c('a',{staticClass:"mm-listitem__text r-link",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v("Log Out")])]):_c('li',[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{ path: '/auth/login' }}},[_vm._v("Distributor Login")])],1),_vm._v(" "),_c('li',[_c('router-link',{staticClass:"mm-listitem__text r-link",attrs:{"to":{ name: 'privacy' }}},[_vm._v("Privacy Policy")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"mm-listitem__text r-link",attrs:{"href":"https://www.amazon.com/stores/BOSS/page/FD2AF217-EAE1-46B2-97C3-DDBDDE4E51B4?ref_=ast_bln","target":"_blank"}},[_vm._v("BUY NOW")])])
}]

export { render, staticRenderFns }