<template>

    <div v-if="product.construction && product.construction.indexOf('Touchscreen Compatible') != -1"  class="product-icon-tooltip">
        <div  class="prod-icon icon-small">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p>
                    <strong>Touchscreen Compatible</strong>
                </p>
                <p>
                    A common misconception is that touchscreens work based on heat from fingers. It's not heat that generates touchscreen functionality, instead, most screens work either on finger pressure applied or electrical field disruption. Basically, this means there are two main types of touchscreen technologies, Resistive and Capacitive.
                </p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='touchscreen'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
