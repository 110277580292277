<template>
    <div>
        <div >

            <product-list></product-list>

        </div>
    </div>
</template>

<script>
export default {
    computed: {

    }
};
</script>

<style lang="scss" scoped>

#filters-button {
    transition: all 0.4s ease-in-out;
    color: whitesmoke;
    top: 10px;
    left: 20px;
    &:hover {
        cursor: pointer;
    }
}
.mobile-filters-container {
    border-top: 1px solid white;
    h5 {
        font-family: "Acumin Pro Condensed", Helvetica, Arial, sans-serif;
        font-size: 0.9rem;
        text-transform: uppercase;
    }
}

.filters-table {
    td {
        font-size: 0.8rem;
    }
}
label {
    &:hover {
        cursor: pointer;
    }
}

.hero-text {
    width: 50%;
    position: absolute;
    bottom: 3rem;
    left: 2rem;

    #header-text {
        font-size: 0.9rem;
        width: 80%;
    }
    #heading {
        font-size: 4rem;
    }
    @media screen and (max-width: 1200px){
        margin-top: 75px;
    }
    @media screen and (max-width: 750px) {
        width: 100%;
        position: unset;
        padding: 2rem;
        #heading {
            font-size: 2rem;
        }
    }
}

</style>
