<template>
    <div v-if="product.sustainability " class="d-flex flex-row">
        <div v-if="product.sustainability.indexOf('Made from Recycled Materials') != -1"  class="product-icon-tooltip">
            <div  class="prod-icon icon-medium">
                <i v-html="iconSustainabilityLeaf[0].svg"></i>
            </div>
            <section class="icon-tooltip">
                <div>
                    <p>
                        <span>Beginning of Life –</span><strong>Made from Recycled Materials</strong>
                    </p>
                    <p>
                        PIP® has taken our innovative platform to the next level, we use recycled P.E.T. water bottles – shredded and extruded as a fiber creating a comfortable and cool material as well as recycled polyester yarns used to create yarns for gloves and apparel.
                    </p>
                </div>
            </section>
        </div>
        <div v-if="product.sustainability.indexOf('Made from Bio-Based Materials') != -1 "  class="product-icon-tooltip">
            <div  class="prod-icon icon-medium">
                <i v-html="iconSustainabilityLeaf[0].svg"></i>
            </div>
            <section class="icon-tooltip">
                <div>
                    <p>
                        <span>Beginning of Life –</span><strong>Made from Bio-Based Materials</strong>
                    </p>
                    <p>
                        PIP® has collaborated with global partners like Dyneema® to develop bio-based ultra-high molecular weight polyethylene fiber delivers the same performance as conventional HPPE fibers, but with a lower carbon footprint. This innovative technique utilizes the mass balance approach and further reduces our reliance on fossil fuel-based resources.
                    </p>
                </div>
            </section>
        </div>
        <div v-if="product.sustainability.indexOf('Recyclable via Terracycle Program') != -1 "  class="product-icon-tooltip">
            <div  class="prod-icon icon-medium">
                <i v-html="iconSustainabilityRecycle[0].svg"></i>
            </div>
            <section class="icon-tooltip">
                <div>
                    <p>
                        <span>End of Life –</span><strong>Recyclable via Terracycle® Program</strong>
                    </p>
                    <p>
                        Whether it’s earplugs, gloves, safety eyewear or other recyclable PPE used in a manufacturing facility or jobsite, PIP®, in collaboration with TerraCycle® provides an easy and convenient way of collecting used PPE to help reduce the amount of waste that ends up in landfills. Contaminated PPE cannot be recycled.
                    </p>
                </div>
            </section>
        </div>
        <div v-if="product.sustainability.indexOf('Reusable / Launderable') != -1 "  class="product-icon-tooltip">
            <div  class="prod-icon icon-medium">
                <i v-html="iconSustainabilityWM[0].svg"></i>
            </div>
            <section class="icon-tooltip">
                <div>
                    <p>
                        <span>Extended Life –</span><strong>Reusable/Launderable</strong>
                    </p>
                    <p>
                        Reusing or laundering products not only reduces cost, but also reduces the amount of waste that is put into our landfills in turn reducing the environmental impact.
                    </p>
                </div>
            </section>
        </div>

    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        iconSustainabilityLeaf(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='sustainability-leaf'){
                    return icon;
                }
            });
        },
        iconSustainabilityWM(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='sustainability-wm'){
                    return icon;
                }
            });
        },
        iconSustainabilityRecycle(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='sustainability-recycle'){
                    return icon;
                }
            });
        },

    },
    created(){
    },

}
</script>
