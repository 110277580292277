<template>
<div class="row">
    <div class="col-md-2 sidebar">
        <h1 class="font-weight-normal pb-3">My Tools</h1>
        <div class="lines d-sm-none d-none d-lg-block"></div>
    </div>
    <div class="my-tools col-md-10">
        <div v-if="user.role==='rep agency'">
            <h3>MARKETING DOCUMENTS</h3>
            <ul>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0019_Group-20.png" alt="my-tools-icon"></figure>
                    <form>
                        <select @change="onChange($event)">
                            <option value="?" style="display:none;">Catalogs</option>
                            <option disabled style="color:#999;">West Chester Protective Gear&reg;</option>
                            <option value="/docs/Catalogs/WCPG/2020-Display-Assortment-Catalog.pdf">2020 Display Assortment Catalog</option>
                            <option value="/docs/Catalogs/WCPG/EW-Catalog.pdf">Extreme Work Catalog</option>
                            <option value=" /docs/Catalogs/WCPG/WCPG-Core-Glove-Catalog.pdf">West Chester Protective Gear&reg;  Core Glove Catalog</option>
                            <option value=" /docs/Catalogs/WCPG/WCPG- Rainwear-Catalog.pdf">West Chester Protective Gear&reg;  Rainwear Catalog</option>
                            <option value=" /docs/CatalogsWCPG//WCPG-Winter-Catalog.pdf">West Chester Protective Gear&reg;  Winter Catalog</option>
                            <option disabled style="color:#999;">Boss&reg;</option>
                            <option value=" /docs/Catalogs/Boss/Consumer-Catalog.pdf">Boss&reg; Consumer Catalog</option>
                            <option value=" /docs/Catalogs/Boss/Garden-Catalog.pdf">Boss&reg; Garden Catalog</option>
                            <option value=" /docs/Catalogs/Boss/Winter-Sport-Catalog.pdf">Boss&reg; Winter Sport Catalog</option>
                            <option disabled style="color:#999;">Safety Works&reg;</option>
                            <option value=" /docs/Catalogs/SW/SW-Display-Catalog.pdf">Safety Works&reg; Display Catalog</option>
                            <option value=" /docs/Catalogs/SW/SW-Product-Catalog.pdf">Safety Works&reg; Product Catalog</option>
                            <option disabled style="color:#999;">Mud&reg;</option>
                            <option value=" /docs/Catalogs/Mud/2021-Mud-Catalog-LR.pdf">Mud&reg; Garden Gloves Catalog</option>
                            <option disabled style="color:#999;">Hearos&reg;</option>
                            <option value=" /docs/Catalogs/Hearos/Hearos-Catalog.pdf">Hearos&reg; Catalog</option>
                            <option disabled style="color:#999;">CAT&reg;</option>
                            <option value=" /docs/Catalogs/CAT/CAT-Catalog.pdf">CAT&reg; Catalog</option>
                            <option disabled style="color:#999;">Scotts&reg; & Miracle Gro&reg;</option>
                            <option value=" /docs/Catalogs/SMG/SMG-Catalog.pdf">Scotts&reg; and Miracle Gro&reg; Catalog</option>
                            <option disabled style="color:#999;">John Deere&reg;</option>
                            <option value=" /docs/Catalogs/JD/JD-Catalog.pdf">John Deere&reg; Catalog</option>

                        </select>
                    </form>
                </li>
            </ul>
            <br>
            <br>
            <h3>SALES DOCUMENTS</h3>
            <ul>
                <li>
                    <figure><img src="/images/my-tools-icons/WCPG-Dash-Icons_0008_Group-9.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Price List</option>
                        <option value="/docs/PriceList/WC-Price-List-Effective-February-1-2021-WHDS.xlsx">WC Price List Effective February, 2021</option>

                        </select>
                    </form>
                </li>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0016_Group-17.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                            <option value="?" style="display:none;">Sell Sheets</option>
                            <option value=" /docs/SellSheets/2917-Sell-Sheet.pdf">2917-Sell-Sheet</option>
                            <option value=" /docs/SellSheets/2918-Sell-Sheet.pdf">2918-Sell-Sheet</option>
                            <option value=" /docs/SellSheets/10068529-Sell-Sheet.pdf">10068529-Sell-Sheet</option>
                            <option value=" /docs/SellSheets/FC20-03-Sell-Sheett.pdf">FC20-03-Sell-Sheet</option>
                            <option value=" /docs/SellSheets/SW46001-Sell-Sheet.pdf">SW46001-Sell-Sheet</option>
                            <option value=" /docs/SellSheets/SWX00478-Sell-Sheet.pdf">SWX00478-Sell-Sheet</option>
                            <option value="../wp-content/themes/pipcustom/docs/SellSheets/Safety-Works0-2021-Cone-Sell-Sheet.pdf">Safety Works 2021 Cone Sell Sheet.pdf </option>
                        </select>
                    </form>
                </li>

                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0011_Group-12.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Rep Agent Docs</option>
                        <option value=" /docs/RepAgent/Safety-Works-Inventory-Forecast-Template.xlsx">Inventory Forecast Template</option>
                        <option value=" /docs/RepAgent/Direct-Deposit-Authorization-Independent-Rep-Safety-Works.pdf">Direct Deposit Authorization - Independent Rep</option>
                        <option value=" /docs/RepAgent/W-9-2018.pdf">W-9</option>
                        </select>
                    </form>
                </li>
            </ul>

            <br>
            <br>
            <h3>WEBSITE TOOLS</h3>
            <ul>
                <li>
                    <a href="<?php echo (esc_url( wc_get_endpoint_url('downloads'))); ?>">
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0018_Group-19.png" alt="my-tools-icon"></figure>
                    <span class="btn-download">Download Product Images</span></a>
                </li>
            </ul>
        </div>
        <div v-else>
            <h3>CORPORATE BRANDING AND IDENTITY</h3>
            <ul>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0003_Group-4.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Logos</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Logos/Westchestergear-Logo.zip">West Chester Protective Gear&reg;</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Logos/Boss-Logo.zip">Boss&reg;</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Logos/Safety-Works-Professional-Logo.zip">Safety Works&reg; Professional</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Logos/Mud-Glove-Logo.zip">Mud&reg;</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Logos/West-County-Gardener-Logo.zip">West County Gardener&reg;</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Logos/Extreme-Work-Logo.zip">Extreme Work&reg;</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Logos/Ambi-dex-Logo.zip">Ambi-dex&reg;</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Logos/Maximum-Safety-Logo.zip">Maximum Safety&reg;</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Logos/Hearos-Logo.zip">Hearos&reg;</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Logos/Brahma-Logo.zip">Brahma&reg;</option>
                        </select>
                    </form>
                </li>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0002_Group-3.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Letterhead</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Letterhead/West-Chester-Protective-Gear-Letterhead.docx">West Chester Protective Gear&reg; Letterhead</option>
                        </select>
                    </form>
                </li>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0000_Group-1.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Powerpoint</option>
                        <option value="https://www.westchestergear.com/wp-content/themes/pip-custom/docs/Powerpoint/West-Chester-Protective-Gear-Template.pptx">West Chester Protective Gear&reg; Template</option>

                        </select>
                    </form>
                </li>
            </ul>
            <br>
            <br>
            <h3>MARKETING DOCUMENTS</h3>
            <ul>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0019_Group-20.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                            <option value="?" style="display:none;">Catalogs</option>
                            <option disabled style="color:#999;">West Chester Protective Gear&reg;</option>
                            <option value=" /docs/Catalogs/WCPG/2020-Display-Assortment-Catalog.pdf">2020 Display Assortment Catalog</option>
                            <option value=" /docs/Catalogs/WCPG/EW-Catalog.pdf">Extreme Work Catalog</option>
                            <option value=" /docs/Catalogs/WCPG/WCPG-Core-Glove-Catalog.pdf">West Chester Protective Gear&reg;  Core Glove Catalog</option>
                            <option value=" /docs/Catalogs/WCPG/WCPG- Rainwear-Catalog.pdf">West Chester Protective Gear&reg;  Rainwear Catalog</option>
                            <option value=" /docs/CatalogsWCPG//WCPG-Winter-Catalog.pdf">West Chester Protective Gear&reg;  Winter Catalog</option>
                            <option disabled style="color:#999;">Boss&reg;</option>
                            <option value=" /docs/Catalogs/Boss/Consumer-Catalog.pdf">Boss&reg; Consumer Catalog</option>
                            <option value=" /docs/Catalogs/Boss/Garden-Catalog.pdf">Boss&reg; Garden Catalog</option>
                            <option value=" /docs/Catalogs/Boss/Winter-Sport-Catalog.pdf">Boss&reg; Winter Sport Catalog</option>
                            <option disabled style="color:#999;">Safety Works&reg;</option>
                            <option value=" /docs/Catalogs/SW/SW-Display-Catalog.pdf">Safety Works&reg; Display Catalog</option>
                            <option value=" /docs/Catalogs/SW/SW-Product-Catalog.pdf">Safety Works&reg; Product Catalog</option>
                            <option disabled style="color:#999;">Mud&reg;</option>
                            <option value=" /docs/Catalogs/Mud/2021-Mud-Catalog-LR.pdf">Mud&reg; Garden Gloves Catalog</option>
                            <option disabled style="color:#999;">Hearos&reg;</option>
                            <option value=" /docs/Catalogs/Hearos/Hearos-Catalog.pdf">Hearos&reg; Catalog</option>
                            <option disabled style="color:#999;">CAT&reg;</option>
                            <option value=" /docs/Catalogs/CAT/CAT-Catalog.pdf">CAT&reg; Catalog</option>
                            <option disabled style="color:#999;">Scotts&reg; & Miracle Gro&reg;</option>
                            <option value=" /docs/Catalogs/SMG/SMG-Catalog.pdf">Scotts&reg; and Miracle Gro&reg; Catalog</option>
                            <option disabled style="color:#999;">John Deere&reg;</option>
                            <option value=" /docs/Catalogs/JD/JD-Catalog.pdf">John Deere&reg; Catalog</option>

                        </select>
                    </form>
                </li>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0005_Group-6.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Tradeshow Docs</option>
                        <option value=" /docs/Tradeshow/Follow-Up-Form.pdf">Follow Up Form</option>
                        <option value=" /docs/Tradeshow/Show-Specials-SetUp-Form.pdf">Specials Set Up Form</option>
                        <option value=" /docs/Tradeshow/Tradeshow-Recap-Form.docx">Tradeshow Recap Form</option>
                        <option value=" /docs/Tradeshow/Tradeshow-Order-Form.xlsx">Tradeshow Order Form</option>
                        </select>
                    </form>
                </li>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0001_Group-2.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Training</option>
                        <option value=" /docs/Training/Eye-and-Face-Protection.pptx">Eye and Face Protection</option>
                        <option value=" /docs/Training/Fall-Protection.pptx">Fall Protection</option>
                        <option value=" /docs/Training/Gloves-in-the-Grocery.pdf">Gloves in the Grocery</option>
                        <option value=" /docs/Training/Hand-Protection-Basics-GP-Chem.pptx">Hand Protection Basics GP Chem</option>
                        <option value=" /docs/Training/Head-Protection-and-Liners.pptx">Head Protection and Liners</option>
                        <option value=" /docs/Training/Hearing-Protection-Long-Version.pptx">Hearing Protection Long Version</option>
                        <option value=" /docs/Training/High-Visibility-Garments-Cold-and-Heat-Stress.pptx">High Visibility Garments Cold and Heat Stress</option>
                        <option value=" /docs/Training/Respiratory-Protection-Long-Version.pptx">Respiratory Protection Long Version</option>
                        <option value=" /docs/Training/Sales-Meeting.pptx">Sales Meeting</option>
                        </select>
                    </form>
                </li>
            </ul>
            <br>
            <br>
            <h3>SALES DOCUMENTS</h3>
            <ul>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0008_Group-9.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Price List</option>
                        <!-- <option value=" /docs/PriceList/Retail-Price-List-2-1-2020.xlsx">Retail Price List 2-1-2020</option>
                        <option value=" /docs/PriceList/Mud-Retail-Price-List-5-1-2020.xlsx">Mud Retail Price List 5-1-2020</option>
                        <option value=" /docs/PriceList/Safety-Works-Industrial-Drop-Ship-Program-Effective-February-1-2019.xlsx">SW Industrial Drop Ship Program (.xls) - Effective 2/1/19</option>
                        <option value=" /docs/PriceList/Safety-Works-Industrial-Drop-Ship-Program-Effective-February-1-2019.pdf">SW Industrial Drop Ship Program (.pdf) - Effective 2/1/19</option>
                        <option value=" /docs/PriceList/SW-Price-Level-Review-process-10-31-18.docx">Price Level Review Process</option> -->
                        <option value=" /docs/PriceList/CAT-Price-List.pdf"> CAT Price List</option>
                        <option value=" /docs/PriceList/Consumer-Price-List-Effective-10-11-2019.pdf">Consumer Price List</option>
                        <option value=" /docs/PriceList/SMG-Retail-2021-Price-List-5-26-2020.pdf">SMG Retail 2021 Price List</option>
                        <option value=" /docs/PriceList/SW-Price-Level-Review-process-10-31-18.docx">SW Price Level Review Process</option>
                        <option value=" /docs/PriceList/PIP-IND-Aug-1-2020-LIST.xlsx">PIP IND Aug 2020 Price List</option>
                        <option value=" /docs/PriceList/Mud-Retail-2020-Price-List.xlsx">Mud Retail Price List</option>

                        </select>
                    </form>
                </li>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0009_Group-10.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Internal Docs</option>

                        <option value=" /docs/InternalDocs/Inventory-forecast-template.xlsx">Inventory Forecast Template</option>
                        <option value=" /docs/InternalDocs/RetailCustomerProgram.pdf">Retail Customer Program</option>
                        <option value=" /docs/InternalDocs/WCPG-Product-Quality-Form.pdf">Product Quality Form</option>
                        <option value=" /docs/InternalDocs/WCPG-Price-Level-Review-process-10-31-18.docx">Price Level Review Process</option>
                        </select>
                    </form>
                </li>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0007_Group-8.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">New Account Setup</option>
                        <option value=" /docs/NewAccount/New-Agent-Form.pdf">New Agent Form</option>
                        <option value=" /docs/NewAccount/New-Account-and-New-Agent-Process.docx">New Account and New Agent Process</option>
                        <option value=" /docs/NewAccount/WEST-CHESTER-Ship-to-Form-Retail-111720.pdf">New Ship-to Form</option>
                        <option value=" /docs/NewAccount/West-Chester-part-of-PIP-family-Credit-Application.pdf">Credit Application Form</option>
                        <option value=" /docs/NewAccount/Credit-Application-Processing.docx">Credit Application Processing</option>
                        </select>
                    </form>
                </li>

                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0015_Group-16.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Customer Service</option>
                        <option value=" /docs/CustomService/Artwork-Guidelines.pdf">Artwork Guidelines</option>
                        <option value=" /docs/CustomService/Logo-Process.docx">Logo Process</option>
                        <option value=" /docs/CustomService/SW-Imprinting-Request-Form-Clothing.pdf">Imprint Request Form - Clothing</option>
                        <option value=" /docs/CustomService/SWX-Gloves-Request-Form-5-17.pdf">Imprint Request Form - Gloves</option>
                        <option value=" /docs/CustomService/SWX-Hard-Hat-Request-Form-5-17.pdf">Imprint Request Form - Hard Hats</option>
                        <option value=" /docs/CustomService/SWX-Eyewear-Request-Form-5-17.pdf">Imprint Request Form - Eyewear</option>
                        <option value=" /docs/CustomService/SWX-Embroidery-Request-Form-5-17.pdf">Imprint Request Form - Embroidery</option>
                        <option value=" /docs/CustomService/SWX-Proof-Update-Request-Form-5-17.pdf">Proof Update Request Form</option>
                        <option value=" /docs/CustomService/Custom-Logo-Stock-Colors-Safety-Works-1.pdf">Stock Colors</option>
                        </select>
                    </form>
                </li>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0013_Group-14.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Calendars</option>
                        <option value="https://pipusa.sharepoint.com/sites/WCPG/Lists/Retail%20Events%20Calendar/calendar.aspx" target="_blank">View</option>
                        </select>
                    </form>
                </li>



                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0016_Group-17.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                            <option value="?" style="display:none;">Sell Sheets</option>
                            <option value=" /docs/SellSheets/2917-Sell-Sheet.pdf">2917-Sell-Sheet</option>
                            <option value=" /docs/SellSheets/2918-Sell-Sheet.pdf">2918-Sell-Sheet</option>
                            <option value=" /docs/SellSheets/10068529-Sell-Sheet.pdf">10068529-Sell-Sheet</option>
                            <option value=" /docs/SellSheets/FC20-03-Sell-Sheett.pdf">FC20-03-Sell-Sheet</option>
                            <option value=" /docs/SellSheets/SW46001-Sell-Sheet.pdf">SW46001-Sell-Sheet</option>
                            <option value=" /docs/SellSheets/SWX00478-Sell-Sheet.pdf">SWX00478-Sell-Sheet</option>
                            <option value=" /docs/SellSheets/Safety-Works0-2021-Cone-Sell-Sheet.pdf">Safety Works 2021 Cone Sell Sheet </option>
                        </select>
                    </form>
                </li>

                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0011_Group-12.png" alt="my-tools-icon"></figure>
                    <form>
                        <select  @change="onChange($event)">
                        <option value="?" style="display:none;">Rep Agent Docs</option>
                        <option value=" /docs/RepAgent/Safety-Works-Inventory-Forecast-Template.xlsx">Inventory Forecast Template</option>
                        <option value=" /docs/RepAgent/Direct-Deposit-Authorization-Independent-Rep-Safety-Works.pdf">Direct Deposit Authorization - Independent Rep</option>
                        <option value=" /docs/RepAgent/W-9-2018.pdf">W-9</option>
                        </select>
                    </form>
                </li>

            </ul>
            <br>
            <br>
            <h3>WEBSITE TOOLS</h3>
            <ul>
                <li>
                    <a href="<?php echo (esc_url( wc_get_endpoint_url('downloads'))); ?>">
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0018_Group-19.png" alt="my-tools-icon"></figure>
                    <span class="btn-download">Download Product Images</span></a>
                </li>
                <!-- <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0017_Group-18.png" alt="my-tools-icon"></figure>
                    <span><a href="#">Spec Sheets</a></span>
                </li>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0010_Group-11.png" alt="my-tools-icon"></figure>
                    <span><a href="#">User Guide</a></span>
                </li>
                <li>
                    <figure><img src=" /images/my-tools-icons/WCPG-Dash-Icons_0019_Group-20.png" alt="my-tools-icon"></figure>
                    <span><a href="#">Literature</a></span>
                </li> -->
            </ul>

        </div>

    </div>
</div>

</template>
<style lang="scss" scoped>
    h3{
        text-transform: uppercase;
        font: 16px/1.1 "acumin-pro", Helvetica, Arial, sans-serif;
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight:600;
        padding-bottom:0.3rem;
        border-bottom: dotted 1px #ccc;
    }
    select{
        font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
        color: #666666;
        font-size: 1rem;
        padding: 0.25rem 0.5rem;
        width: 100%;
        background: white;
    }
</style>
<script>
import {mapState} from  "vuex";


    export default{
        computed:{
             ...mapState({user:state=>state.user}),
         },
        methods:{
            onChange(event) {
                window.open(event.target.value);
            }
        }
    }
</script>
