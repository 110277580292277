<template>
    <div class="">
        <div
            class="row no-gutters bg-secondary d-flex flex-column justify-content-end p-4"
            style="border-bottom: 2px solid white; min-height: 30vh;"
        >
            <h1 class="text-light ml-4 text-center text-md-left">
                Privacy Policy
            </h1>
        </div>
        <div class="row p-5">
            <div class="col-12">
                <h4>TERMS AND CONDITIONS OF USE &amp; DISCLAIMERS</h4>
                <p>
                    By using this Web site, you signify your assent to the
                    following Terms and Conditions of Use. If you do not agree
                    to these Terms of Use, please do not use this site.
                </p>
                <p>
                    We reserve the right, at our discretion, to change, modify,
                    add, or remove portions of these terms at any time. Please
                    check the terms periodically for changes. Your continued use
                    of the Web site following the posting of changes to these
                    terms will mean you accept those changes. Protective
                    Industrial Products (West Chester) reserves the right to
                    deny or terminate service to anyone for any or no reason.
                </p>
                <p>
                    This site contains copyrighted material, trademarks, and
                    other proprietary information, including, but not limited
                    to, text, photographs, graphics, images, illustrations and
                    software, and the entire content is protected by copyright
                    as a collective work and/or compilation under copyright
                    laws. West Chester owns a copyright in the content original
                    and in the selection, coordination, arrangement, and
                    enhancement of all content.
                </p>
                <p>
                    Any reproduction, modification, publication, transmission,
                    retransmission, framing, misuse, misappropriation,
                    unauthorized use, transfer, sale, distribution, performance,
                    display, creation of a derivative work or any other
                    exploitation of any content, in whole or in part, without
                    the express written consent of West Chester is strictly
                    prohibited. Notwithstanding the foregoing, any user of this
                    site is granted a limited license to download content for
                    personal use only provided that all copyright and other
                    notices in any downloaded content are maintained. By
                    downloading content, the user agrees and acknowledges that
                    the user does not acquire any ownership or other use rights
                    to any content or this site by downloading such content.
                    Except as expressly authorized above, nothing contained in
                    this site or these Terms and Conditions of access shall be
                    construed as conferring, by implication, estoppel or
                    otherwise, any license or right under any patent, trademark,
                    copyright or other property rights of West Chester or any
                    third party.
                </p>
                <h4>OWNERSHIP</h4>
                <p>
                    Unless otherwise indicated, this web site and its design,
                    text, content, selection, and arrangement of elements,
                    organization, graphics, design, compilation, magnetic
                    translation, digital conversion and other matters related to
                    this web site are protected under applicable copyrights,
                    trademarks, and other proprietary rights (including, but not
                    limited to, intellectual property) and are the property of
                    West Chester or the material is included with the permission
                    of the rights owner and is protected pursuant to copyright
                    and trademark laws. ALL RIGHTS RESERVED.
                </p>
                <p>
                    The posting of any such elements on this web site does not
                    constitute a waiver of any right in such elements. You do
                    not acquire ownership rights to any such elements viewed
                    through this web site. Except as otherwise provided herein,
                    none of these elements may be used, copied, reproduced,
                    distributed, republished, downloaded, modified, displayed,
                    posted or transmitted in any form or by any means,
                    including, but not limited to, electronic, mechanical,
                    photocopying, recording, or otherwise, without our express
                    prior written permission.
                </p>
                <p>
                    Permission is hereby granted to the extent necessary to
                    lawfully access and use this web site and/or information,
                    materials, products and/or services available on it to
                    display, download, archive and print in hard copy, portions
                    of this web site for personal use only, provided you do not
                    modify the materials and that you retain any and all
                    copyright and other proprietary notices contained in the
                    materials. This permission terminates automatically if you
                    breach any of these Terms of Use. Upon termination, you must
                    immediately destroy any downloaded, archived or printed
                    materials.
                </p>
                <h4>DISCLAIMER OF WARRANTY AND LIMITATION OF LIABILITY</h4>
                <p>
                    Neither West Chester nor any of its affiliates, officers,
                    directors, employees, third-party content providers,
                    licensors, agents or representatives makes any warranty or
                    representation of any kind, either express or implied, with
                    respect to this site, any content or any products or
                    services offered through this site, including, but not
                    limited to, any warranty that this site will be operable,
                    uninterrupted, virus-free or error-free, any warranty of
                    title, any warranty of privacy, any warranty of
                    dependability, accuracy, timeliness, authenticity,
                    completeness or reliability, or any warranty of
                    merchantability or fitness for a particular purpose.
                </p>
                <p>
                    By using this site, you acknowledge and agree that this site
                    and all content are provided on an “as is, where at” basis
                    without any warranty or representation of any kind, either
                    express or implied, with respect to this site, any content
                    or any products or services offered through this site,
                    including, but not limited to, any warranty that this site
                    will be operable, uninterrupted, virus free or error free,
                    any warranty of title, any warranty of privacy, any warranty
                    of dependability, accuracy, timeliness, authenticity,
                    completeness or reliability, or any warranty of
                    merchantability or fitness for a particular purpose.
                </p>
                <p>
                    In no event will West Chester or any of its affiliates,
                    officers, directors, employees, third party content
                    providers, licensors, agents, or representatives be liable
                    to any person or entity for damages of any nature whatsoever
                    arising out of or in connection with the use of this site or
                    any linked web site. This is a comprehensive limitation of
                    liability that you agree is fair and reasonable concerning
                    your use of this site and applies to all harm, liabilities,
                    injuries, losses, damages, costs and expenses of any kind
                    whatsoever, including, but not limited to, direct, indirect,
                    incidental, special, compensatory, or consequential damages,
                    regardless of how same arise, whether in tort, negligence,
                    statute, contract, common law, equity or otherwise, and
                    whether for or in connection with loss of programs or data,
                    loss of income, revenues or profits, loss of anticipated
                    sales, loss of opportunity, business interruption, failure
                    to realize expected savings, damage to person or property,
                    claims of third parties or otherwise.
                </p>
                <h4>LINKS</h4>
                <p>
                    This site may from time to time be linked to other web
                    sites. West Chester is not responsible for any content
                    contained on any web site that is not maintained by West
                    Chester. No link from this site to a third party web site,
                    or from a third party web site to this site, is an
                    endorsement, sponsorship or recommendation by West Chester
                    of such third party web site. West Chester will have no
                    liability to users arising from any link from a third party
                    web site to this site or from this site to a third party web
                    site.
                </p>
                <h4>INFORMATION PROVIDED</h4>
                <p>
                    All comments, graphics, ideas and other information you
                    provide to West Chester through this site will become the
                    property of West Chester without any compensation to the
                    user. To the extent you are requested to provide any
                    information to West Chester while using this site, you
                    represent and warrant that such information will be accurate
                    and complete in all respects.
                </p>
                <h4>INFORMATION COLLECTED</h4>
                <p>
                    West Chester may collect information about you and your use
                    of this site (including, but not limited to, your name,
                    email address, street address and telephone number, credit
                    card information, and length of time spent by you on this
                    site) for many purposes. You consent to West Chester’s use
                    of such information for any lawful purpose. Any personally
                    identifiable information you provide through this site
                    (e.g., information that can identify the user, including,
                    but not limited to, your name, email address, street
                    address, telephone number, employer and occupational title)
                    will be subject to the Privacy Policy posted on this site.
                </p>
                <h4>MISUSE OF INFORMATION</h4>
                <p>
                    West Chester uses precautions to protect information
                    provided by you to West Chester through this site from
                    misuse or unauthorized disclosure in accordance with the
                    Privacy Policy posted on this site. However, West Chester
                    does not guarantee that any such information is secure from
                    misuse or unauthorized disclosure, and the West Chester will
                    not have any liability to you for any misuse or unauthorized
                    disclosure of such information.
                </p>
                <h4>INDEMNIFICATION AND HOLD HARMLESS</h4>
                <p>
                    You understand and expressly agree that all access to and
                    use of this site is at your sole risk.
                </p>
                <p>
                    You will indemnify and hold harmless West Chester from and
                    against all harm, liabilities, injuries, losses, damages,
                    costs and expenses incurred by West Chester (including, but
                    not limited to, fees and disbursements of counsel to West
                    Chester in connection with your access to or use of this
                    site or any content). You agree that you shall not take any
                    action against West Chester or any of its affiliates,
                    officers, directors, employees, third party content
                    providers, licensors, agents, or representatives in any
                    connection with any matter relating to or arising out of
                    your access to or use of this site.
                </p>
                <h4>TERMINATION AND MODIFICATION</h4>
                <p>
                    West Chester may terminate this site and modify any content
                    at any time, in each case, without notice to you and without
                    liability to you for such termination or modification.
                </p>
                <h4>MISCELLANEOUS</h4>
                <p>
                    These Terms of Use (a) may be rectified by West Chester at
                    any time without notice to you, (b) inure to the benefit of
                    and are binding upon you and West Chester and each of your
                    and West Chester successors and assigns, except that you may
                    not assign any of your obligations under these Terms of Use
                    without the written consent of West Chester. All orders are
                    subject to our approval. We reserve the right to limit
                    quantities. We reserve the right to cancel any order. West
                    Chester is not responsible for typographical errors. The
                    laws of the State of New York shall be the choice of law for
                    any issue arising out of these terms and conditions.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
h1 {
    font-size: 3.5rem;
}
</style>
