<template>
    <div class="container my-5">
        <div class="row">
            <div class="col-md-12">
                <div class="error-template d-flex align-items-center flex-column">
                    <h1>
                        Oops!</h1>
                    <h2>
                        404 Not Found</h2>
                    <div class="error-details mb-2">
                        Sorry, an error has occured, Requested page not found!
                    </div>
                    <div class="error-actions">
                        <a href="/" class="btn btn-primary btn-lg">Take Me Home </a>
                        <a href="/contact" class="btn btn-info btn-lg"> Contact Support </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style scoped>
.error-template{
    min-height:50vh;
}

</style>
