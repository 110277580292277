var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"searchPanel","tabindex":"-1","role":"dialog","aria-label":"Search Panel"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-dark",staticStyle:{"border-radius":"0"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Search Products","id":"search-input","aria-label":"Search Input"},domProps:{"value":(_vm.searchValue)},on:{"input":function($event){if($event.target.composing)return;_vm.searchValue=$event.target.value}}})]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[(_vm.showList)?_c('div',{staticClass:"d-flex flex-column w-100"},[(_vm.filteredProducts.data)?_c('div',[_vm._l((_vm.filteredProducts.data.slice(
                                0,
                                6
                            )),function(product,index){return _c('div',{key:'product' + index,staticClass:"d-flex mb-2"},[_c('router-link',{staticClass:"d-flex product-item",attrs:{"to":{
                                    name: 'product',
                                    params: { id: product.prod_id }
                                }},nativeOn:{"click":function($event){return _vm.closeSearchPanel.apply(null, arguments)}}},[(product.images)?_c('section',[(product.images[0].folder)?_c('div',[_c('b-img',{staticClass:"product-image",attrs:{"src":`/images/pim/1200/${product.images[0].folder}/${product.images[0].img}.jpg`}})],1):_c('div',[_c('b-img',{staticClass:"product-image",attrs:{"src":`/images/pim/600/${product.images[0].img}.jpg`}})],1)]):_c('section',[_c('img',{attrs:{"src":"/images/products/img-coming-soon.png","alt":"image"}})]),_vm._v(" "),_c('div',{staticClass:"p-2"},[_c('p',{staticClass:"text-secondary font-weight-bold"},[_vm._v("\n                                        "+_vm._s(product.prod_key)+"\n                                    ")]),_vm._v(" "),_c('p',{staticClass:"desc",domProps:{"innerHTML":_vm._s( product.short_desc)}})])])],1)}),_vm._v(" "),(_vm.filteredProducts.data.length)?_c('div',{staticClass:"info d-flex flex-wrap bg-secondary text-light justify-content-around"},[_c('div',{staticClass:"p-3"},[_vm._v("\n                                SHOWING "+_vm._s(_vm.displayResultsCount)+" OF\n                                "+_vm._s(_vm.filteredProducts.total)+"\n                            ")]),_vm._v(" "),_c('div',{staticClass:"py-2"},[(_vm.filteredProducts.total > 6)?_c('b-button',{staticClass:"mt-1 btn btn-outline-light btn-sm",attrs:{"data-dismiss":"modal","to":{
                                        name: 'products',
                                        query: {
                                            q:'search',
                                            v: _vm.searchValue }
                                    }},on:{"click":_vm.closeSearchPanel}},[_vm._v("\n                                    ALL RESULTS\n                                ")]):_vm._e()],1)]):_vm._e()],2):_c('div',[_vm._m(1)])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-light close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("NO RECORDS FOUND")])])
}]

export { render, staticRenderFns }