<template>

    <router-link
        :to="{ name: 'product', params: { id:product.prod_id } }"

        class="product-gallery d-flex flex-column"
    >
        <div>
            <div class="img-container" >
                <section v-if="(product.images[0])">
                    <div v-if="product.images[0].folder">
                        <b-img
                            :src="`/images/pim/600/${product.images[0].folder}/${product.images[0].img}.jpg`"
                            class="product-image"
                        ></b-img>
                    </div>
                    <div v-else>
                        <img src="/images/products/img-coming-soon.png" alt="image" width="">
                    </div>
                </section>
                <section v-else>
                    <img src="/images/products/img-coming-soon.png" alt="image" width="">
                </section>
            </div>
            <div class="d-flex flex-column">
                <p class="prod-name inline-block" v-html="product.short_desc"></p>

                <p class="prod-id mb-1">
                    {{ product.prod_key }}
                </p>
                <div v-if="product.specs" class="mt-2 mb-3 specs">
                    <ul class="ml-3">
                        <li v-for="(specs, index) in product.specs.split('|').slice(0,4)" :key="index" v-html="specs">

                        </li>
                    </ul>
                </div>
                <div>

                </div>
            </div>
        </div>
        <div class="mb-3 product-gallery-icon pt-4">
            <product-icons :product="product"></product-icons>
        </div>

    </router-link>

</template>

<script>
export default {
    data() {
        return {
            loading: false
        };
    },
    props: {
        product:Object
    },
    methods: {
        // setProduct(prod_id) {
        //     this.$store.dispatch("setProduct", prod_id);
        // },
        formatCurrency(price) {
            price = price / 100;
            return price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
            });
        },



        toTitleCase(str) {
            return str
                .toLowerCase()
                .split(" ")
                .map(function(word) {
                    if (word.startsWith("&") && !word.endsWith(";")) {
                        return (
                            word.split(";")[0] +
                            " " +
                            word
                                .split(";")[1]
                                .charAt(0)
                                .toUpperCase() +
                            word.split(";")[1].slice(1)
                        );
                    }
                    return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(" ");
        }
    },
    computed: {
        // sortedImages() {
        //     return _.orderBy(this.images, ["id"], ["asc"]);
        // },
    }
};
</script>

<style lang="scss" scoped>
a {
    color: #111;
    // font-weight: bolder;
}
li {
    font-size: 0.8rem;
}

h4,
p {
    font-family: "Acumin Pro", Helvetica, Arial, sans-serif;
}

</style>
