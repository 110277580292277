<template>
    <div id="top">
        <div v-if="loading" >
            <div class="vh-100 d-flex justify-content-center align-items-center">

                <vue-loaders-ball-beat scale="2" color="black" />
            </div>

        </div>
        <div v-else>
            <div class="single-product mt-5 pt-5">
                <div class="breadcrumbs pl-4" v-if="productdetail" >
                    <!-- <a href="">PRODUCTS</a> -->
                    <router-link 
                        :to="{
                            name:'products'
                        }"
                    >PRODUCTS</router-link>
                    <span class="fa fa-angle-right"></span>
                        <router-link
                            :to="{
                                name: 'products',
                                query: { scID: productdetail.category.select_code_id }
                            }"
                            >{{ productscID }}
                        </router-link>
                    <span class="fa fa-angle-right"></span>
                        <router-link
                            :to="{
                                name: 'products',
                                query: {
                                    scID: productdetail.category.select_code_id,
                                    ccID: productdetail.category.commodity_code_id
                                }
                            }" >{{ productccID }}</router-link>
                </div>
                <div class="row  mt-5 mb-5" v-if="productdetail">
                    <div
                        id="prod-images"
                        class="col-md-12 col-lg-6 justify-content-center align-items-center"

                    >

                        <div class="w-100" id="product-slider" v-if="productdetail.images[0]">
                            <VueSlickCarousel v-bind="settings" ref="carousel1">
                                <div
                                    v-for="(image, index) in productdetail.images"
                                    :key="'image' + index"
                                    class="slide-content"
                                >
                                    <div
                                        :id="'slide' + index"
                                        class="position-relative d-flex justify-content-center "
                                    >
                                        <!-- <b-img
                                            :src="`/images/products/${image}`"
                                            class="product-image"
                                        ></b-img> -->
                                        <div v-if="image.folder" class="image-slider-container">
                                            <b-img
                                                :src="`/images/pim/1200/${image.folder}/${image.img}.jpg`"
                                                class="product-image"
                                            ></b-img>
                                        </div>
                                        <div v-else>
                                            <img src="/images/products/img-coming-soon.png" alt="image" width="">
                                        </div>

                                    </div>
                                </div>
                            </VueSlickCarousel>
                        </div>
                        <div class="w-100" id="product-slider" v-else>
                            <img src="/images/products/img-coming-soon.png" alt="IMAGE">
                        </div>
                    </div>
                    <div
                        class="col-xs-12 col-sm-12 col-lg-5"
                        id="product-details" >

                        <section>
                            <h2 class=" font-weight-bold" v-html="productdetail.short_desc"></h2>
                            <p class="text-primary font-weight-bold product-sku">{{ productdetail.prod_key }}</p>
                        </section>

                        <section class="mt-3 product-single-icons">
                            <product-icons :product="productdetail"></product-icons>
                        </section>


                        <section class="mt-4">

                            <div v-if="productdetail.description">
                                <p class="prod-features-title font-weight-bold text-uppercase">
                                    Product Description
                                </p>
                                <p  v-html="productdetail.description"></p>
                            </div>

                            <div v-if="productdetail.features" class="pt-2">
                                <p class="prod-features-title font-weight-bold text-uppercase">
                                    Features
                                </p>
                                <ul class="pl-4">
                                    <li v-for="(feature,index) in prodFeatures " :key="index"  v-html="feature"></li>
                                </ul>
                            </div>
                            <div class="my-4 pt-2">
                                <!-- <a @click="showSpecs()" class="btn btn-primary">VIEW PRODUCT SPECS </a > -->

                                <button class="btn btn-primary" type="submit" @click.prevent="downloadSpecs(productdetail.prod_id,productdetail.prod_key)">DOWNLOAD SPECSHEET</button>


                                <!-- <a @click="downloadSpecs()" class="btn btn-primary" v-if="productdetail.prod_id=='136924'">DOWNLOAD PRODUCT SPECS </a > -->
                                <!-- <router-link
                                    :to="{
                                        name: 'specsheet',
                                        query: { id: productdetail.prod_id }
                                    }"
                                    tag="button"
                                    class="btn btn-primary"
                                    >VIEW PRODUCT SPECS</router-link> -->
                            </div>

                        </section>


                        <!-- <p v-if="useCart" v-text="formatCurrency(productdetail.price)"></p>
                        <button
                            class="btn btn-success"
                            @click="$store.commit('addToCart', productdetail)"
                            v-if="useCart"  > Add To Cart </button> -->


                    </div>
                </div>

                <!-- <div class="d-flex justify-content-between flex-wrap prod-description py-5 "> -->
                <div class=" prod-description px-3 py-5 row d-flex justify-content-center">

                    <section id="prodSPecs" class="col-md-6 col-lg-4">
                        <h3 class="font-weight-bold">
                                    Product specs
                        </h3>
                        <ul class="list-unstyled mt-4">
                            <!-- loop through each feature -->

                            <li v-for="(filterOption,index) in filterOptions " :key="index" >
                                <div v-if="productdetail[filterOption.filter]">
                                    <strong class="text-uppercase">{{filterOption.head_EN}}:</strong>
                                    <span v-if="productdetail[filterOption.filter].indexOf('--')>-1">
                                        {{ productdetail[filterOption.filter].slice(2,-2).replaceAll('--',' , ') }}
                                    </span>
                                    <span v-else v-html="productdetail[filterOption.filter]">   </span>
                                </div>

                            </li>

                        </ul>

                    </section>
                    <section class="col-md-6 col-lg-5">
                        <h3 class="font-weight-bold">
                            Sizing Chart
                        </h3>
                        <img src="/images/Website-Boss_Glove-Size-Chart.jpg" class="mt-4" alt="glove chart" id="sizing-chart">
                    </section>
                </div>

                <!-- <div class="prod-single-gallery py-5 my-5">

                    <div class="row ">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-5">
                            <img src="/images/boss-glove-1.jpg" alt="boss-glove">
                        </div>

                        <div class="col-sm-5">
                            <img src="/images/boss-glove-2.jpg" alt="boss-glove">
                        </div>
                        <div class="col-sm-1"></div>
                    </div>
                </div> -->

                <div class="related-products w-100 py-5" v-if="relatedProducts">
                    <div class="row">
                        <div class="col-sm-1"></div>
                        <div class="col-sm-10">
                            <h3 class="font-weight-bold mb-4 px-2">related products</h3>

                            <div class="w-100" id="related-product-slider">

                                <VueSlickCarousel v-bind="relatedProdsettings" ref="carousel2" >
                                    <div  v-for="(prod, index) in relatedProducts"
                                        :key="'prod' + index" >
                                        <router-link
                                            :to="{ name: 'product', params: { id:prod.prod_id } }"

                                            class="d-flex flex-column justify-content-start"
                                        >
                                        <div
                                                :id="'prod' + index"
                                                class="w-100 d-flex  justify-content-center flex-column px-2 "
                                            >
                                            <div class="prod-image-container d-flex  align-items-center mb-4" v-if="prod.images[0]">
                                                <div v-if="prod.images[0].folder">
                                                    <b-img
                                                        :src="`/images/pim/1200/${prod.images[0].folder}/${prod.images[0].img}.jpg`"
                                                        class="product-image w-100"
                                                    ></b-img>
                                                </div>
                                                <div v-else>
                                                    <img src="/images/products/img-coming-soon.png" alt="IMAGE" class="w-100">
                                                </div>
                                            </div>
                                            <div class="prod-image-container d-flex  align-items-center" v-else>
                                                <img src="/images/products/img-coming-soon.png" alt="IMAGE" class="w-100">
                                            </div>

                                            <strong v-html="prod.short_desc" class="text-color-black" ></strong>
                                            <p class="text-primary font-weight-bold mt-1">{{prod.prod_key}}</p>
                                        </div>
                                        </router-link>
                                    </div>
                                </VueSlickCarousel>
                            </div>
                        </div>
                        <div class="col-sm-1"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapState } from "vuex";

export default {
    data() {
        return {
            error:false,
            loading: true,
            slide: 0,
            interval: 0,
            productdetail:{},
            filterOptions:{},
            relatedProducts:{},
            productscID:null,
            productccID:null,
            prodFeatures:[],
            settings: {
                dots: true,
                controls: true,
                dotsClass: "slick-dots custom-dot-class",
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                autoplay: false,
                slidesToShow: 1,
                slidesToScroll: 1
            },
            relatedProdsettings: {
                // dots: true,
                controls: true,
                // dotsClass: "slick-dots custom-dot-class",
                edgeFriction: 0.35,
                infinite: true,
                speed: 1000,
                autoplay: true,
                slidesToShow: 5,
                slidesToScroll: 5,
                responsive:[
                    {
                    breakpoint: 1024,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            infinite: true,

                        }
                    },
                    {
                    breakpoint: 800,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,

                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            dots: false,
                        }
                    },
                        {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,
                        }
                    }
                ]
            }
        };
    },
    beforeCreate: function() {
        document.body.className = 'single-product-body';
    },
    computed: {
        ...mapState([
            "categories",
        ])

    },
    methods: {
        // async downloadSpecs(){
        //     // this.loading=true;
        //     axios.get(`/api/specsheet/${this.productdetail.prod_id}/${this.productdetail.prod_key}/`);

        // },
        // onclick download spec sheet generate spec sheet and display
        async downloadSpecs(id,key){
            this.loading=true;
            document.getElementById("top").scrollIntoView({ block: 'start',  behavior: 'smooth' });
            try{
                await axios.get(`/api/specsheet/${id}/${key}/`).then(response=>{
                    this.loading=false;

                    const pdfUrl = window.location.origin+response.data;
                    const link = document.createElement('a');
                    link.href = pdfUrl;
                    link.target = '_blank';
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            }catch (error) {
                console.error(error);
            }
        },
        showSpecs(){
            var element = document.getElementById("prodSPecs");
            element.scrollIntoView({behavior: "smooth", block: "center"});

        },
        formatCurrency(price) {
            price = price / 100;
            return price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
            });
        },
        toTitleCase(str) {
            return str
                .toLowerCase()
                .split(" ")
                .map(function(word) {
                    if (word.startsWith("&") && !word.endsWith(";")) {
                        return (
                            word.split(";")[0] +
                            " " +
                            word
                                .split(";")[1]
                                .charAt(0)
                                .toUpperCase() +
                            word.split(";")[1].slice(1)
                        );
                    }
                    return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(" ");
        }
    },
    created() {

        // remove search modal backdrop
        $('.modal-backdrop').remove();


        // get the product info
        axios.get(`/api/product/${this.$route.params.id}`).then(response => {
           this.productdetail=response.data[0];
           this.categories.forEach((cat, catindex )=>{
                if(cat.select_code.id==this.productdetail.category.select_code_id){
                    this.productscID=cat.select_code.title;
                    cat.commodity_code.forEach((subcat, subcatindex )=>{
                        if(subcat.id==this.productdetail.category.commodity_code_id){
                            this.productccID=subcat.title;
                        }
                    });
                }
            });
            //filter features
            this.prodFeatures=this.productdetail.features.split('|').filter(function(feature){
                if(feature != ' '){
                    return feature;
                }
            });


            //    get selective filter option belonging to scID and ccID
            axios.get(`/api/filteroptions/${this.productdetail.category.select_code_id}/${this.productdetail.category.commodity_code_id}/`).then(response => {
                this.filterOptions=response.data;

            });

             // get related products
            axios.get(`/api/relatedproducts/${this.productdetail.category.select_code_id}/${this.productdetail.category.commodity_code_id}/`).then(response => {
                this.relatedProducts=response.data;
                this.loading=false;
            });

        });





    },
    components: { VueSlickCarousel }
};
</script>

<style lang="scss">


    .prod-description{
        border-top:6px solid #efefef;
        border-bottom:6px solid #efefef;

        // section{
        //     width:46%;
        // }
    }
    .prod-features-title{
        font-family: "Acumin Pro Condensed", Helvetica, Arial, sans-serif;
        font-size:1rem;
        margin-bottom:0.5rem;

    }
    .product-single-icons{
        .product-icon-tooltip{
            .prod-icon{
                &:hover +.icon-tooltip{
                    bottom: unset;
                    top:60px;
                    left:-77px;
                }
            }
            .icon-tooltip{
                bottom: unset;
                top:100px;

                &:before{
                    bottom: unset;
                    // left:110px;
                    top:-10px;
                    border-top:none;
                    border-bottom:10px solid #111;
                }

            }
        }
    }
.single-product-body {
    background:#fff;
    overflow-x: hidden;
    .fix-nav .search, .fix-nav .fa-search{
        color:#fff !important;
    }
    @media screen and (max-width: 1200px){
        #main-nav{
            background:#000 !important;
        }
        .fa-search{
            color:#fff !important;
        }
    }
}

#sizing-chart{
    max-width:100%;
}
.single-product{
    margin:3rem;
    @media screen and (max-width: 600px){
        margin:1rem;
    }
}
.prod-single-gallery{
    background:#000;
    img{
        max-width:100%;
    }
}
#product-details {
    margin-top: 2rem;
    padding:0 3rem;
    @media screen and (max-width: 768px){
        padding:0 2rem;
    }
    h2 {
        text-transform: unset;
        margin-bottom: 0;
        font-family: "Acumin Pro Condensed", Helvetica, Arial, sans-serif;
        font-size: 2.5rem;
        margin-bottom: 0.3rem;
    }
    h4 {
        font-size: 1.15rem;
        font-weight: normal;
        font-family: "Acumin Pro Condensed", Helvetica, Arial, sans-serif;
    }
    p {
        font-weight: normal;
        font-family: "Acumin Pro", Helvetica, Arial, sans-serif;
    }
    .product-sku{
        font-size:1.2rem;
    }
}

#related-product-slider{
    .slick-slider {
        strong{
            line-height:1.1;
        }
        button.slick-arrow.slick-prev,
        button.slick-arrow.slick-next {
            z-index: 100 !important;
            &:before {
                font-size: 3rem !important;
            }
        }
        button.slick-arrow.slick-prev {
            left: -3rem !important;
            &:before {
                font-family: "Font Awesome 5 Free";
                color: #c5c5c5;
                font-weight:600;
                content: "\f053" !important;
            }
        }
        button.slick-arrow.slick-next {
            right: -3rem !important;
            &:before {
                font-family: "Font Awesome 5 Free";
                color: #c5c5c5;
                font-weight:600;
                content: "\f054" !important;
            }
        }

    }
    @media screen and (max-width: 576px){
        .slick-slider {
            padding:0 1rem;
            button.slick-arrow.slick-prev,
            button.slick-arrow.slick-next {
                &:before {
                    font-size: 2rem !important;
                }
            }
            button.slick-arrow.slick-prev {
                left: 0.5rem !important;

            }
            button.slick-arrow.slick-next {
                right: 0.5rem !important;

            }

        }
    }

}
#product-slider {
    .slick-slider {
        button.slick-arrow.slick-prev,
        button.slick-arrow.slick-next {
            z-index: 100 !important;
            &:before {
                font-size: 2rem !important;
            }
        }
        button.slick-arrow.slick-prev {
            left: 1rem !important;
            &:before {
                color: #cd1b00;
            }
        }
        button.slick-arrow.slick-next {
            right: 1rem !important;
            &:before {
                color: #cd1b00;
            }
        }
    }
    .slide-content {
        .image-slider-container {
            display:flex;
            justify-content: center;
            flex-wrap:wrap;
            img.product-image {
                max-width: 90%;
                object-fit: contain;
                @media screen and (max-width: 974px) {
                    height: unset;
                }
            }
        }
    }
}
#prod-breadcrumbs {
    font-size: 0.8rem;
    font-weight: normal;
    a {
        color: #333;
        &:hover {
            color: #cd1b00;
        }
    }
}
</style>
