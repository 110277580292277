<template>

    <div v-if="product.ansi_type_class"  class="product-icon-tooltip">
        <div  class="prod-icon icon-square">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div v-switch="product.ansi_type_class">
                <div v-case="'R3'">
                    <div>
                        <p><span>Type/ Class</span> <strong>R3</strong></p>
                        <p>
                            Class R3 apparel is intended for workers requiring the highest level of visibility, have high task loads and need to be visible throughout a full range of body motions.
                        </p>
                    </div>
                </div>
                <div v-case="'R2'">
                    <div>
                        <p><span>Type/ Class</span><strong>R2</strong></p>
                        <p>Type/Class R2 apparel is necessary for workers exposed to traffic traveling over 25 mph and who work against complex backgrounds.</p>
                    </div>
                </div>
                <div v-case="'P2'">
                    <div>
                        <p><span>Type/ Class</span><strong>P2</strong></p>
                        <p>
                            The Type/Class P2 standard was developed to provide enhanced visibility for emergency responders and law enforcement personnel in both roadway and off-road environments. All vests meet revised ANSI/ISEA 207-2015 standard.
                        </p>
                    </div>
                </div>
                <div v-case="'O1'">
                    <div>
                        <p><span>Type/ Class</span><strong>O1</strong></p>
                        <p>
                            Class O1 apparel is intended off-road use, to provide added visibility to workers who are not exposed to traffic.
                        </p>
                    </div>
                </div>
                <div v-case="'N'">
                    <div>
                        <p><span>Non-ANSI</span></p>
                        <p>
                            Non-ANSI garments are color enhanced and offer an additional level of safety for low risk activities, but do not meet ANSI standards.
                        </p>
                    </div>
                </div>
                <div v-case="'E'">
                    <div>
                        <p><span>ANSI Classe E</span></p>
                        <p>
                            Applies to retro-reflective equipped pants or shorts, designed to be worn in combination with either Class 2 or Class 3 tops. When worn in this manner, the ensemble is rated as Performance Class 3.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='ansi-107-'+self.product.ansi_type_class+'-color'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
