<template>

    <div v-if="product.en374 && product.en374.length > 0"  class="product-icon-tooltip">
        <div  class="prod-icon icon-tall d-flex flex-column">
            <i v-html="getIcon[0].svg"></i>
            <small class="icon-text-small" v-html="product.en374[0].en374"></small>
        </div>
        <section class="icon-tooltip">
            <div>
                <p><span>EN 374 Results:</span> <strong v-html="product.en374[0].en374"></strong></p>
                <p>
                    <ul>
                        <li v-if="product.en374[0].en374.indexOf('A') != -1">A: Methanol</li>
                        <li v-if="product.en374[0].en374.indexOf('B') != -1">B: Acetone</li>
                        <li v-if="product.en374[0].en374.indexOf('C') != -1">C: Acetonitrile</li>
                        <li v-if="product.en374[0].en374.indexOf('D') != -1">D: Dichloromethane</li>
                        <li v-if="product.en374[0].en374.indexOf('E') != -1">E: Carbon Disulfide</li>
                        <li v-if="product.en374[0].en374.indexOf('F') != -1">F: Toluene</li>
                        <li v-if="product.en374[0].en374.indexOf('G') != -1">G: Diethylamine</li>
                        <li v-if="product.en374[0].en374.indexOf('H') != -1">H: Tetrahydrofuran</li>
                        <li v-if="product.en374[0].en374.indexOf('I') != -1">I: Ethyl Acetate</li>
                        <li v-if="product.en374[0].en374.indexOf('J') != -1">J: Heptane</li>
                        <li v-if="product.en374[0].en374.indexOf('K') != -1">K: Sodium Hydroxide 40%</li>
                        <li v-if="product.en374[0].en374.indexOf('L') != -1">L: Sulfuric Acid 96%</li>
                        <li v-if="product.en374[0].en374.indexOf('M') != -1">M: Nitric Acid 65%</li>
                        <li v-if="product.en374[0].en374.indexOf('N') != -1">N: Acetic Acid 99%</li>
                        <li v-if="product.en374[0].en374.indexOf('O') != -1">O: Ammonium Hydroxide 25%</li>
                        <li v-if="product.en374[0].en374.indexOf('P') != -1">P: Hydrogen Peroxide 30%</li>
                        <li v-if="product.en374[0].en374.indexOf('S') != -1">S: Hydrogen Fluoride 40%</li>
                        <li v-if="product.en374[0].en374.indexOf('T') != -1">T: Formaldehyde 37%</li>
                    </ul>
                </p>
                <p>
                    To attain the EN 374 pictogram shield gloves must pass at least a Level 2 in three chemicals. The chemicals listed above are the chemicals that passed for this glove.
                </p>
            </div>
        </section>
    </div>


</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='en-374'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
