<template>
    <div>
        <section>
            <div v-if="fileExists && prodImageDetail[0].folder">
                <b-img
                    :src="`/images/pim/600/${prodImageDetail[0].folder}/${prodImageDetail[0].img}.jpg`"

                ></b-img>
            </div>
            <div v-else>
                <img src="/images/products/img-coming-soon.png" alt="image" >
            </div>
        </section>

    </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        prodImageDetail:{
            type: Array,
            default: ()=> []
        },

    },
    data() {
        return {
            fileExists:false,
            currentURL:window.location.origin
        }

    },
    created(){
        // check if the image file exists
        this.checkFile('/images/pim/600/'+this.prodImageDetail[0].folder+'/'+this.prodImageDetail[0].img+'.jpg');
    },
    methods:{
        // check if the file exists
        checkFile(path){
            fetch(path)
                .then(response => {
                    if (response.ok) {
                        this.fileExists = true;

                    } else {
                        this.fileExists = false;
                    }

                })
                .catch(error => {
                    console.error('Error:', error);
                    this.fileExists = false;
                });
        }
    }


}
</script>
