<template>
    <div>
        <div id="ssFooterContainer" style="position:absolute; bottom:0px; width:816px; height:100px; padding:0 50px;">

            <!--Product Circularity Key-->

            <div id="ssKey" style="width:700px;">
            <div style="margin-left:22px"><img height="17px" src="/images/product-circularity-us-en.svg"></div>
            </div>

            <div style="border-top:solid 2px #78797b; font-size: 10px; line-height:12px; padding:14px 25px 0 25px;">
                <strong style="font-size:12px; line-height:20px;">PROTECTIVE INDUSTRIAL PRODUCTS, INC.</strong>
                <span style="color:#cd1b00; font-size:12px; line-height:20px;">|
                BRINGING THE BEST OF THE WORLD TO YOU®
                </span>
                <br />
                AMERICAS: +1 (800) 262-5755 | EUROPE: +34-96182-41-48 | AMEA: (ASIA, MIDDLE EAST, AFRICA) 852-2475-9228 | www.pipglobalsafety.com
                <p style="margin-top:5px; padding:0; font-size:9px; line-height:10px; color:#999;">
                    This document and the information contained herein is the property of Protective Industrial Products, Inc. (PIP) and may not be used or reproduced without permission. Product users should conduct all appropriate testing or other evaluations to determine the suitability of PIP products for a particular purpose or use within a particular environment. PIP DISCLAIMS ALL WARRANTIES OTHER THAN AS EXPRESSLY PROVIDED. {{ currentDate }}
                </p>

            </div>

        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";

export default {

    data() {
        return {
        }

    },
    computed: {
        currentDate(){
            // Create a new Date object
            var currentDate = new Date();

            // Get the current year, month, and day
            var year = currentDate.getFullYear();
            var month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because January is 0
            var day = ('0' + currentDate.getDate()).slice(-2);

            // Combine the year, month, and day using the desired format
            var formattedDate = year + '-' + month + '-' + day;

            // Output the formatted date
            return formattedDate;
        }
    },
    created(){

    },

}
</script>
