<template>
    <div class="w-100 position-relative">
        <div class="content-container position-absolute">
            <div class="text-container p-5">
                <h1>{{ caption }}</h1>
                <p>{{ text }}</p>
            </div>
            <slot></slot>
            <!-- <img :src="{ imgSrc }" /> -->
        </div>
    </div>
    <!-- <b-carousel-slide :caption="caption" :text="text" :img-src="imgSrc">
        <slot></slot
    ></b-carousel-slide> -->
</template>

<script>
export default {
    props: {
        caption: String,
        text: String,
        imgSrc: String
    }
};
</script>

<style scoped></style>
