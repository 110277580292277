<template>

    <div v-if="product.ansi_cut_level_abdomen || product.ansi_cut_level_back || product.ansi_cut_level_belly_patch  || product.ansi_cut_level_bicep  || product.ansi_cut_level_chest  || product.ansi_cut_level_forearm || product.ansi_cut_level_lap_groin || product.ansi_cut_level_neck_collar || product.ansi_cut_level_waist_leg || product.ansi_cut_level_upper_back  || product.ansi_cut_level_torso  "  class="product-icon-tooltip">
        <div  class="prod-icon icon-extra-wide">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p>CUT RESISTANT GARMENT</p>
                <p><span>Test Method:</span><strong>ASTM F2992-15</strong></p>
                <p>This test method assesses the cut resistance of a material when exposed to a cutting edge under specified loads. Data obtained from this test method can be used to compare the cut resistance of different materials with scores ranging from A1-A9. <br>This item offers cut protection in the following locations:</p>
                <p v-if="product.ansi_cut_level_abdomen ">
                    <span>Abdomen:</span>
                    <strong v-html="product.ansi_cut_level_abdomen"></strong>
                </p>
                <p v-if="product.ansi_cut_level_back ">
                    <span>Back:</span>
                    <strong v-html="product.ansi_cut_level_back"></strong>
                </p>
                <p v-if="product.ansi_cut_level_belly_patch ">
                    <span>Belly Patch:</span>
                    <strong v-html="product.ansi_cut_level_belly_patch"></strong>
                </p>
                <p v-if="product.ansi_cut_level_bicep ">
                    <span>Bicep:</span>
                    <strong v-html="product.ansi_cut_level_bicep"></strong>
                </p>
                <p v-if="product.ansi_cut_level_chest ">
                    <span>Chest:</span>
                    <strong v-html="product.ansi_cut_level_chest"></strong>
                </p>
                <p v-if="product.ansi_cut_level_forearm ">
                    <span>Forearm:</span>
                    <strong v-html="product.ansi_cut_level_forearm"></strong>
                </p>
                <p v-if="product.ansi_cut_level_lap_groin ">
                    <span>Lap/Groin:</span>
                    <strong v-html="product.ansi_cut_level_lap_groin"></strong>
                </p>

                <p v-if="product.ansi_cut_level_neck_collar ">
                    <span>Neck/Collar:</span>
                    <strong v-html="product.ansi_cut_level_neck_collar"></strong>
                </p>
                <p v-if="product.ansi_cut_level_torso ">
                    <span>Torso:</span>
                    <strong v-html="product.ansi_cut_level_torso"></strong>
                </p>
                <p v-if="product.ansi_cut_level_upper_back ">
                    <span>Upper Back:</span>
                    <strong v-html="product.ansi_cut_level_upper_back"></strong>
                </p>
                <p v-if="product.ansi_cut_level_waist_leg ">
                    <span>Waist/Leg:</span>
                    <strong v-html="product.ansi_cut_level_waist_leg"></strong>
                </p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='cut-resistant-clothing'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
