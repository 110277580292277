<template>

    <div v-if="product.fr"  class="product-icon-tooltip">
        <div  class="prod-icon icon-square">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p><strong>FLAME RETARDANT (FR)</strong></p>
                <p>
                    Flame retardant garments pass specific ASTM tests that deem the fabric flame resistant. Flame resistant fabrics and garments provide protection against clothing ignition, sustained flame spread and do not melt or drip. The material resists ignition, self-extinguishes and the FR properties last for the wearable life of the garment.
                </p>
            </div>
        </section>
    </div>


</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='flame-retardant'){
                    return icon;
                }
            });
        }
    },
    created(){
    },

}
</script>
