<template>
    <div :name="name">
        <div class="row">
            <div class="col-md-12 px-3">
                <h5 class="text-uppercase  filter_head "  data-toggle="collapse" :data-target="'#'+setid" aria-expanded="true" aria-controls="collapseOne" :class="{'collapsed': !show}">
                    {{ name }}
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                </h5>
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: String,
        setid: String,
        show: Number,
    },

    method:{

    }


};
</script>

<style lang="scss" scoped>
// h5 {
//     font-size: 1.2rem;
//     font-weight: 700;
//     border-bottom: 1px solid #00549e;
//     font-family: "acumin-pro-condensed", Helvetica, Arial, sans-serif;
// }
.collapsed{
    i{
        transform:rotate(0) !important;
    }
}
.filter_head{
    border-bottom: 1px solid #efefef;
    color: #666;
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 1rem;
    padding: 0 1rem 0.5rem 0;
    font-family: "acumin-pro", Helvetica, Arial, sans-serif;
    display:flex;
    justify-content: space-between;
    font-size:0.8rem;
    i{
        transform:rotate(180deg);
        transition: transform 0.6s;
    }
    @media screen and (max-width: 1024px){
        padding: 0 1rem 0.5rem 1rem;
    }
}
.hide-filter{
    transform: translateY(0);
}

</style>
