<template>
   <div v-if="product.astm_f1060"  class="product-icon-tooltip">
            <div  class="prod-icon icon-square">
                <i v-html="getIcon[0].svg"></i>
            </div>
            <section class="icon-tooltip">

                <div v-switch="product.astm_f1060">
                    <div v-case="'1'">
                        <p>
                            <span>ANSI Contact Heat Level:</span><strong v-html="product.astm_f1060"></strong>
                        </p>
                        <p>
                            <span>Test Method:</span><strong>ASTM F1060-18</strong>
                        </p>
                        <p>
                            <span>Temperature:</span><strong>176<sup>&deg;</sup>F</strong>
                        </p>
                        <p>The ANSI/ISEA 105-2016 standard outlines test methods for contact heat and scored from 0-5. The ASTM F1060-18 test is used to test conductive heat resistance against gloves and PPE. Measuring the highest contact temperature for which the time to second-degree burn is at least 15 seconds and the time to pain or alarm time is at least four seconds.</p>
                    </div>
                    <div v-case="'2'">
                        <p>
                            <span>ANSI Contact Heat Level:</span><strong v-html="product.astm_f1060"></strong>
                        </p>
                        <p>
                            <span>Test Method:</span><strong>ASTM F1060-18</strong>
                        </p>
                        <p>
                            <span>Temperature:</span><strong>284<sup>&deg;</sup>F</strong>
                        </p>
                        <p>The ANSI/ISEA 105-2016 standard outlines test methods for contact heat and scored from 0-5. The ASTM F1060-18 test is used to test conductive heat resistance against gloves and PPE. Measuring the highest contact temperature for which the time to second-degree burn is at least 15 seconds and the time to pain or alarm time is at least four seconds.</p>
                    </div>
                    <div v-case="'3'">
                        <p>
                            <span>ANSI Contact Heat Level:</span><strong v-html="product.astm_f1060"></strong>
                        </p>
                        <p>
                            <span>Test Method:</span><strong>ASTM F1060-18</strong>
                        </p>
                        <p>
                            <span>Temperature:</span><strong>392<sup>&deg;</sup>F</strong>
                        </p>
                        <p>The ANSI/ISEA 105-2016 standard outlines test methods for contact heat and scored from 0-5. The ASTM F1060-18 test is used to test conductive heat resistance against gloves and PPE. Measuring the highest contact temperature for which the time to second-degree burn is at least 15 seconds and the time to pain or alarm time is at least four seconds.</p>
                    </div>
                    <div v-case="'4'">
                        <p>
                            <span>ANSI Contact Heat Level:</span><strong v-html="product.astm_f1060"></strong>
                        </p>
                        <p>
                            <span>Test Method:</span><strong>ASTM F1060-18</strong>
                        </p>
                        <p>
                            <span>Temperature:</span><strong>500<sup>&deg;</sup>F</strong>
                        </p>
                        <p>The ANSI/ISEA 105-2016 standard outlines test methods for contact heat and scored from 0-5. The ASTM F1060-18 test is used to test conductive heat resistance against gloves and PPE. Measuring the highest contact temperature for which the time to second-degree burn is at least 15 seconds and the time to pain or alarm time is at least four seconds.</p>
                    </div>
                    <div v-case="'5'">
                        <p>
                            <span>ANSI Contact Heat Level:</span><strong v-html="product.astm_f1060"></strong>
                        </p>
                        <p>
                            <span>Test Method:</span><strong>ASTM F1060-18</strong>
                        </p>
                        <p>
                            <span>Temperature:</span><strong>608<sup>&deg;</sup>F</strong>
                        </p>
                        <p>The ANSI/ISEA 105-2016 standard outlines test methods for contact heat and scored from 0-5. The ASTM F1060-18 test is used to test conductive heat resistance against gloves and PPE. Measuring the highest contact temperature for which the time to second-degree burn is at least 15 seconds and the time to pain or alarm time is at least four seconds.</p>
                    </div>


                </div>
            </section>
    </div>

</template>

<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object
    },
    data() {
        return {
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='ansi-contact-heat-'+self.product.astm_f1060){
                    return icon;
                }
            });
        }
    },

}
</script>
