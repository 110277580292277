<template>
    <div id="category-header" :style="`background-image:url('/images/headers/Boss-Gloves-Product-Header.jpg');`" >
            <div class="hero-text">
                <h1 class="text-light" id="heading" v-if="currentParam.scID">
                    {{ category }}
                </h1>
                <h1 class="text-light" id="heading" v-else>
                    PRODUCTS
                </h1>

            </div>
        </div>
</template>

<script>
    import { mapState } from "vuex";
    export default {
        data(){
            return{
                currentParam:this.$route.query,
            }
        },
        computed: {
            ...mapState(["categories"]),

            category(){
                for(var i=0;i<this.categories.length;i++){
                    if(this.categories[i].select_code.id==this.currentParam.scID){
                        return this.categories[i].select_code.title;
                    }
                }

            }
        },

        created(){

        }
    }
</script>
<style lang="scss" scoped>
#category-header {
    height: 50vh;
    position: relative;

    // background-position: bottom center;
    background-position: center center;
    background-size: cover;
    background-color: #111;
    border-bottom:6px solid #efefef;
    @media screen and (max-width: 750px) {
        background-position: left;
        height: unset;
    }
}
.hero-text {
    width: 50%;
    position: absolute;
    bottom: 3rem;
    left: 2rem;

    #header-text {
        font-size: 0.9rem;
        width: 80%;
    }
    #heading {
        font-size: 4rem;
    }
    @media screen and (max-width: 1200px){
        margin-top: 75px;
    }
    @media screen and (max-width: 750px) {
        width: 100%;
        position: unset;
        padding: 2rem;
        #heading {
            font-size: 2rem;
        }
    }
}
</style>
