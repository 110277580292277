<template>
    <div>
        <div class="container-fluid py-5">
            <main role="main" class="dashboard-main">
                    <router-view></router-view>
            </main>
        </div>
    </div>
</template>
<style lang="scss" scoped>

</style>
<script>
import {mapState} from  "vuex";
import { logOut } from '../utils/auth';
    export default{
        computed:{
            ...mapState({user:state=>state.user}),
         },
        methods:{
            menuClick(){
                this.displayWelcome=false;
            },
        }
    };
</script>
