<template>

    <div v-if="product.chnl_promo"  class="product-icon-tooltip " :class="{'showIcon': show }" id="custom-logo-icon">
        <div  class="prod-icon icon-square">
            <i v-html="getIcon[0].svg"></i>
        </div>
        <section class="icon-tooltip">
            <div>
                <p>
                    <strong>CUSTOM LOGO</strong>
                </p>
                <p>We have a dedicated team that manages all of your logo requests and will help ensure the process runs smoothly from start to finish. If you have any questions along the way, they will be more than happy to assist you.</p>
            </div>
        </section>
    </div>

</template>
<script>
import { mapState } from "vuex";

export default {
    props: {
        product:Object,

    },
    data() {
        return {
            show:false,
        }

    },
    computed: {
        ...mapState(["icons"]),
        getIcon(){
            self=this;
            return this.icons.filter(function(icon){
                if(icon.name=='custom-logo'){
                    return icon;
                }
            });
        }
    },
    created(){
        if(this.$route.params.id){
            this.show=true;
        }
    },

}
</script>
<style>
.showIcon{
    display:inline-block !important;
}
</style>
